import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import { AgGridReact } from "ag-grid-react";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import { keys } from "d3";
import D3RepPerformanceBar from "../RepPerformanceAnalysis/Charts/D3RepPerformanceBarYTD";
import D3RepPerformanceBarWins from "../RepPerformanceAnalysis/Charts/D3RepPerformanceBarFYWinsAsOfDate";
import Legend from "./Legend";
import AsOfDateTable from "./AsOfDateTable";
import addHeaderdescription from "../../components/HeaderDescription";
const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px 10px",
    color: "inherit",
  },
  table1: {
    background: "#FAFAFA",
    border: "none",
    width: 5,
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    color: "white",
    background: "#4472C4",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 5px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});

const nearestCompletedFiscalQuarter = () => {
  let year = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  if (currentMonth < 3) {
    return `${year - 1}-Q4`;
  } else if (currentMonth > 2 && currentMonth < 6) {
    return `${year}-Q1`;
  } else if (currentMonth > 5 && currentMonth < 9) {
    return `${year}-Q2`;
  } else {
    return `${year}-Q3`;
  }
};

const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};

const formatter = (value) => {
  if (_isNaN(value)) return 0;
  return d3.format(",")(value.toFixed(1));
};

const currencyFormatter = (value) => {
  if (_isNaN(value)) return 0;
  return "$" + d3.format(",")(Math.round(value));
};

class AsOfDate extends React.Component {
  state = {
    year: ["all"],
    month: ["all"],
    quarter: ["all"],
    // cfq: this.props.AsOfDateFilters === '' ? [nearestCompletedFiscalQuarter()] : [this.props.AsOfDateFilters.closedFiscalQuarters[1]],
    disabled: ["year", "month", "quarter"],
    timeFrame: "quarter",
    rep:
      this.props.RepPerformanceYTDFilters !== ""
        ? this.props.recordsets[1]
        : ["All"],
  };

  filters = ["rep"];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  writtenTeams = [];

  formatDate = (date) => {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      timeZone: "UTC",
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day}-${month}-${year}`;
  };

  componentDidMount = () => {
    this.props.getAsOfDateFilters();
    window.addEventListener("resize", this.onResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
    D3RepPerformanceBar.destroy(this._rootNode1);
    D3RepPerformanceBarWins.destroy(this._rootNode2);
  };

  sum = (input) => {
    if (toString.call(input) !== "[object Array]") return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.RepPerformanceYTDFilters !== "" &&
      prevProps.RepPerformanceYTDFilters === ""
    ) {
      // console.log(this.props.recordsets[1].map(x => x.Full_Name))
      const reps = this.props.recordsets[1].map((x) => x.Full_Name);
      this.setState({
        ...prevState,
        rep: this.props.recordsets !== undefined ? reps : ["All"],
      });
    }
    if (prevProps.AsOfDateData !== this.props.AsOfDateData) {
      D3RepPerformanceBar.destroy(this._rootNode1);
      this._chart1 = D3RepPerformanceBar.create(this._rootNode1, {
        data: this.props.AsOfDateData.final,
        currentFY: this.props.AsOfDateData.currentFY,
      });
      D3RepPerformanceBarWins.destroy(this._rootNode2);
      this._chart1 = D3RepPerformanceBarWins.create(this._rootNode2, {
        data: this.props.AsOfDateData.final,
        currentFY: this.props.AsOfDateData.currentFY,
        type: "AsOfDate",
      });
    }
  };

  handleGo = () => {
    let filters = this.filters
      .map((f) => {
        return {
          name: "Rep",
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? ["All"]
                : this.getSelectedRepIDs()
              : this.state[f],
          string: true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    this.props.getAsOfDateData(filters);
  };

  handleChangeTimeFrame = (e) => {
    this.setState({
      ...this.state,
      timeFrame: e.target.value,
      disabled:
        e.target.value === "custom"
          ? [
              ...this.state.disabled.filter(
                (f) => f !== "year" && f !== "month" && f !== "quarter"
              ),
              "cfq",
            ]
          : [
              ...this.state.disabled.filter((f) => f !== "cfq"),
              "year",
              "month",
              "quarter",
            ],
    });
  };

  // handleChange = e => {
  //   this.setState({
  //     ...this.state,
  //     [e.target.name]: e.target.name === 'rep' ? [e.target.value] : e.target.value.length === 0 ? ['All'] : this.state[e.target.name].length === 1 && this.state[e.target.name].includes('All') ? e.target.value.filter(v => v !== 'All').length > 0 ? e.target.value.filter(v => v !== 'All') : ['All'] : e.target.value.includes('All') ? ['All'] : e.target.value
  // })
  // }

  handleChange = (e) => {
    // if (e.target.name === 'year' && e.target.value.filter(v => v === 'All').length > 0) {
    //   // console.log(e.target.name)
    //     this.setState({
    //         ...this.state,
    //         [e.target.name]: e.target.name === 'rep' ? [e.target.value] : e.target.value.length === 0 ? ['All'] : this.state[e.target.name].length === 1 && this.state[e.target.name].includes('All') ? e.target.value.filter(v => v !== 'All').length > 0 ? e.target.value.filter(v => v !== 'All') : ['All'] : e.target.value.includes('All') ? ['All'] : e.target.value,
    //         month: ['All'],
    //         quarter: ['All']
    //     })
    // }
    // else if(typeof(e.target.value) === "string"){
    //   // console.log(e.target.value)
    //     this.setState({
    //         ...this.state,
    //         [e.target.name]: [e.target.value],
    //     })
    // }
    //  else {
    this.setState({
      ...this.state,
      // [e.target.name]: e.target.value
      [e.target.name]:
        e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
    // }
  };

  //Getting the Userid for reps
  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.recordset.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  //sorting the reps based on last name
  orderRepsByLastName = () => {
    let temp = this.props.recordset.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[1]} ${res[0]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }
  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }

  onResize = async () => {
    D3RepPerformanceBar.destroy(this._rootNode1);
    this._chart1 = D3RepPerformanceBar.create(this._rootNode1, {
      data: this.props.AsOfDateData.final,
      currentFY: this.props.AsOfDateData.currentFY,
    });
    D3RepPerformanceBarWins.destroy(this._rootNode2);
    this._chart1 = D3RepPerformanceBarWins.create(this._rootNode2, {
      data: this.props.AsOfDateData.final,
      currentFY: this.props.AsOfDateData.currentFY,
      type: "AsOfDate",
    });
  };
  render() {
    // console.log(this.props.recordsets)
    const { classes } = this.props;
    return (
      <Grid container>
        {/* <Grid item style={{ marginBottom: "1rem" }} xs={12}>
          <Typography variant="h2" align="center">
            Rep Performance YTD
          </Typography>
          <Typography variant="body1">
            Explore the YTD performance for each rep along with the adequacy of
            the open pipeline.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Rep Performance YTD",
          "Explore the YTD performance for each rep along with the adequacy of the open pipeline."
        )}
        {/* {this.props.RepPerformanceYTDFilters !== undefined ?
          <Grid container style={{ marginBottom: "2rem", marginTop: "1rem" }}>
            <Grid className={classes.grid} item container justify="flex-start" xs={12}>
              <Grid item spacing={2} xs={2} container component={Paper}>

                <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel className={classes.filterLabel} style={{ color: !this.state.rep.includes('All') ? '#4472c4' : '#000' }} id="select-rep-label">Rep:</InputLabel>
                    <Select
                      data-test='rep'
                      value={this.state.rep}
                      onChange={this.handleChange}
                      name='rep'
                      multiple
                    >
                      <MenuItem value={'All'} >All</MenuItem>
                      {this.props.RepPerformanceYTDFilters !== '' && this.props.recordset
                        .filter(y => y.type === "rep" && y.Full_Name !== '')
                        .sort((a, b) => a.Full_Name < b.Full_Name ? -1 : a.Full_Name > b.Full_Name ? 1 : 0)
                        .map(y => <MenuItem key={y.Full_Name} value={y.Full_Name}>{y.Full_Name}</MenuItem>)}

                    </Select>
                  </FormControl>

                </Grid>
              </Grid>
              <Grid />

              <Grid item sm={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button variant='contained' color='primary' onClick={this.handleGo} data-test="go">GO</Button>
              </Grid>
            </Grid>
          </Grid> :
          <Grid item container component={Card} style={{ margin: '1rem' }} justify="center">
            <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
          </Grid>
        } */}
        <Grid container item xs={12}>
          {this.props.AsOfDateData !== "" ? (
            <Grid item component={Card}>
              <AsOfDateTable data={this.props.AsOfDateData} />
            </Grid>
          ) : (
            <Grid item container justify="center" component={Card}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} style={{ margin: "2rem" }}>
          {this.props.AsOfDateData !== "" ? (
            <Card style={{ margin: "auto", width: "90%" }} raised={true}>
              <Typography
                variant="h2"
                style={{
                  margin: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {`FY ${this.props.AsOfDateData.currentFY}`} Wins YTD
              </Typography>

              <div
                style={{
                  justifyContent: "center",
                  marginTop: 20,
                  margin: "auto",
                  width: "95%",
                }}
                className="line-container"
                ref={this._setRef2.bind(this)}
              />
            </Card>
          ) : (
            <Card style={{ margin: "auto", width: "90%" }} raised={true}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Card>
          )}
        </Grid>
        <Grid item xs={12} style={{ margin: "2rem" }}>
          {this.props.AsOfDateData !== "" ? (
            <Card style={{ margin: "auto", width: "90%" }} raised={true}>
              <Typography
                variant="h2"
                style={{
                  margin: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Projected {`FY ${this.props.AsOfDateData.currentFY}`} Wins
              </Typography>

              <Grid container>
                <Grid item container xs={12}>
                  <div
                    style={{
                      justifyContent: "center",
                      marginTop: 20,
                      margin: "auto",
                      width: "95%",
                    }}
                    className="line-container"
                    ref={this._setRef1.bind(this)}
                  />
                </Grid>
                <Grid
                  item
                  container
                  justify="center"
                  style={{ margin: "2rem" }}
                  xs={12}
                >
                  <Legend
                    square={10}
                    legends={[
                      {
                        text: `FY ${this.props.AsOfDateData.currentFY} Wins YTD`,
                        color: "#70AD47",
                      },
                      {
                        text: `FY ${this.props.AsOfDateData.currentFY} Rest of the year Projected Win`,
                        color: "#A9D18E",
                      },
                      {
                        text: `FY ${this.props.AsOfDateData.currentFY} Quota`,
                        color: "#BF9000",
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Card>
          ) : (
            <Card style={{ margin: "auto", width: "90%" }} raised={true}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Card>
          )}
        </Grid>
      </Grid>
    );
  }
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "white",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
    },
  },
}))(TableRow);

function mapStateToProps(state) {
  const { RepPerformanceYTDFilters, AsOfDateData, persistentAlert } = state.app;
  const { recordset, recordsets } = RepPerformanceYTDFilters;
  const { final } = AsOfDateData;

  var names = [];

  if (final !== undefined) {
    names = final.map((x) => x.name);
  }

  return {
    RepPerformanceYTDFilters,
    AsOfDateData,
    persistentAlert,
    recordset,
    final,
    names,
    recordsets,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getAsOfDateData: (filters) => {
    dispatch({ type: "get_AsOfDate_data_request" });
    // console.log(filters)
    appService.getRepPerformanceYTD(filters).then(
      (json) => {
        dispatch({ type: "get_AsOfDate_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_AsOfDate_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_AsOfDate_data_failure", error });
      }
    );
  },
  getAsOfDateFilters: () => {
    dispatch({ type: "get_AsOfDate_filters_request" });
    appService.getRepPerformanceYTDFilters().then(
      (json) => {
        // console.log(json)
        dispatch({ type: "get_AsOfDate_filters_success", json });
        dispatch({ type: "get_AsOfDate_data_request" });
        const filters = [];
        appService.getRepPerformanceYTD(filters).then(
          (json) => {
            dispatch({ type: "get_AsOfDate_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_AsOfDate_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_AsOfDate_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_AsOfDate_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_AsOfDate_filters_failure", error });
      }
    );
  },
});

const connectedAsOfDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AsOfDate));
export { connectedAsOfDate as AsOfDate };
