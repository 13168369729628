import React from 'react'
import {
  Card, CardHeader, MenuItem, TextField, InputAdornment, Divider, Button, Typography, Grid, Select, InputLabel, FormControl, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { appService } from '../../App/app.service';
import { AgGridReact } from 'ag-grid-react';
import Skeleton from '@material-ui/lab/Skeleton'
import * as d3 from 'd3'
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';
import { keys } from 'd3';
import { stageFormattter } from '../../util/customFunctions';

const styles = theme => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  table: {
    border: '0.5px solid lightgrey', padding: '5px 10px', color: 'inherit', width: '3.6666%'
  },
  rankCol: {
    maxWidth: '1rem'
  },
  table1: {
    background: '#FAFAFA', border: 'none'
  },
  rep: {
    border: '0.5px solid lightgrey', padding: '5px 10px', width: '20%', color: 'inherit'
  },
  tableHeader: {
    fontWeight: 600, border: '0.5px solid lightgrey', padding: '5px 10px', color: 'white', background: '#4472C4', width: '3.6666%'
  },
  repHeader: {
    fontWeight: 600, border: '0.5px solid lightgrey', padding: '5px 10px', width: '20%', color: 'white', background: '#4472C4'
  },
  mainTable: {
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    }
  },
  mainTable2: {
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    }
  }
})

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="$"
      allowNegative={false}
    />
  );
}

const nearestCompletedFiscalQuarter = () => {
  let year = new Date().getFullYear()
  let currentMonth = new Date().getMonth()
  if (currentMonth < 3) {
    return `${year - 1}-Q4`
  } else if (currentMonth > 2 && currentMonth < 6) {
    return `${year}-Q1`
  } else if (currentMonth > 5 && currentMonth < 9) {
    return `${year}-Q2`
  } else {
    return `${year}-Q3`
  }
}

const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};

const formatter = (value) => {
  if (_isNaN(value)) return 0
  return d3.format(",")(value.toFixed(1))
}

const currencyFormatter = (value) => {
  if (_isNaN(value)) return 0
  return '$' + d3.format(",")(Math.round(value))
}


class FunnelCompare extends React.Component {

  state = {
    year: ['all'],
    month: ['all'],
    quarter: ['all'],
    closed_fiscal_quarter: this.props.funnelCompareFilters === '' ? [nearestCompletedFiscalQuarter()] : [this.props.funnelCompareFilters.filter(y => y.type === "closed_fiscal_quarter" && y["closed_fiscal_quarter"] !== '')[1].closed_fiscal_quarter],
    disabled: ['year', 'month', 'quarter'],
    timeFrame: 'quarter',
    topReps: [],
    topCalculated: false,
    topRepsTable: [],
    benchmarks: [],
    benchmarks_acv: [],
    acv: null,
    firstStage: null
  }


  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  formatDate = (date) => {
    let { day, month, year } = new Intl.DateTimeFormat('en', {
      timeZone: 'UTC',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).formatToParts(date).reduce((acc, part) => {
      if (part.type != 'literal') {
        acc[part.type] = part.value;
      }
      return acc;
    }, Object.create(null));
    return `${day}-${month}-${year}`;
  }

  componentDidMount = () => {
    this.props.getfunnelCompareFilters(this.props.company)
    //window.addEventListener('resize', this.onZoom)
  }

  componentWillUnmount = () => {
    //window.removeEventListener('resize', this.onZoom)
  }

  sum = (input) => {
    if (toString.call(input) !== "[object Array]")
      return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  }


  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.funnelCompareFilters !== '' && prevProps.funnelCompareFilters === '') {
      this.setState({ ...prevState, closed_fiscal_quarter: [this.props.funnelCompareFilters.filter(y => y.type === "closed_fiscal_quarter" && y["closed_fiscal_quarter"] !== '')[1].closed_fiscal_quarter] })
    }
    if (prevProps.funnelCompareData !== this.props.funnelCompareData) {
     let firstStage = this.props.repStages[0]
      // let lastStage = this.props.company === 'C0003' ? 'Win' : this.props.company === 'C0002' ? 'Won' : 'Closed Won'
      let lastStage = this.props.repStages.slice(-1)[0]
      console.log(lastStage)
      let topRepsTable = []
      let topReps = []
      let topCalculated = false

      this.props.sortedReps.map((j, i) => {
        let pursued_acv = Math.round(this.props.funnelCompareData[0].filter(x => x.rep === j && x.stage === firstStage)[0].acv)
        let closed_won_acv = Math.round(this.props.funnelCompareData[0].filter(x => x.rep === j && x.stage === lastStage)[0].acv)
        let win_rate = closed_won_acv === 0 || pursued_acv === 0 ? 0 : Math.round((closed_won_acv / pursued_acv) * 100)
        let total_won = this.sum(this.props.funnelCompareData[0].filter(x => x.stage === lastStage).map(x => x.acv))
        let win_rate_by_total = closed_won_acv === 0 || total_won === 0 ? 0 : ((closed_won_acv / total_won) * 100)
        let cumulative = i === 0 ? (win_rate_by_total) : (win_rate_by_total) + (((this.sum(this.props.funnelCompareData[0].filter(x => this.props.sortedReps.slice(0, i).includes(x.rep) && x.stage === lastStage).map(k => k.acv))) / total_won) * 100)
        let closed_won = Math.round(this.props.funnelCompareData[0].filter(x => x.rep === j && x.stage === lastStage)[0].count)
        var eToD_conv = Math.round(this.props.funnelCompareData[0].filter(item => item.rep == j && item.stage === firstStage)[0].conv_acv * 100)
        var dToW_conv = Math.round(this.props.funnelCompareData[0].filter(item => item.rep == j && item.stage !== firstStage).map(item => item.conv_acv).filter(i => i !== null).length !== 5 ? 0 : this.props.funnelCompareData[0].filter(item => item.rep == j && item.stage !== firstStage).map(item => item.conv_acv).filter(i => i !== null).reduce((a, b) => a * b, 1) * 100)


        topRepsTable.push({
          rep: j,
          pursued_acv,
          closed_won_acv,
          win_rate,
          rank: i + 1,
          win_rate_by_total,
          cumulative,
          closed_won,
          eToD_conv,
          dToW_conv
        })
        if (cumulative <= 50 && !topCalculated) {
          topReps.push(j)
        } else if (cumulative > 50 && !topCalculated) {
          topReps.push(j)
          topCalculated = true
        }
      })
      let lostInSuspect = this.props.funnelCompareData[0].filter(item => item.stage === firstStage).map(i => i.acv).reduce((a, b) => a + b, 0)



      let benchmarks = []
      this.props.funnelCompareData[1].map(s => {
        let topconv = this.props.funnelCompareData[0].filter(o => topReps.includes(o.rep) && o.stage === s).map(o => o.conv)
        let avgconvOfStage = this.sum(topconv) / topconv.length
        let obj = {
          stage: s,
          value: avgconvOfStage
        }
        benchmarks.push(obj)
      })
      let benchmarks_acv = []
      this.props.funnelCompareData[1].map(s => {
        let topconv = this.props.funnelCompareData[0].filter(o => topReps.includes(o.rep) && o.stage === s).map(o => o.conv_acv)
        let avgconvOfStage = this.sum(topconv) / topconv.length
        let obj = {
          stage: s,
          value: avgconvOfStage
        }
        benchmarks_acv.push(obj)
      })

      this.setState({
        topRepsTable: topRepsTable,
        topReps: topReps,
        benchmarks: benchmarks,
        benchmarks_acv: benchmarks_acv,
        firstStage
      })

    }
  }

  filters = ['closed_fiscal_quarter']

  getSelectedRepIDs = () => {
    let idArray = []
    this.state.rep.map(i => {
      this.props.funnelCompareFilters.map(j => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID]
        }
      })
    })
    return idArray
  }
  handleGo = () => {
    let filters = this.filters.map(f => {
      return {
        name: f,
        value: f === 'rep' ? this.state[f].includes('All') ? 'All' : this.getSelectedRepIDs() : this.state[f],
        string: this.props.funnelCompareFilters.filter(i => i.type === f && i[f] === this.state[f][0])[0] ? this.props.funnelCompareFilters.filter(i => i.type === f && i[f] === this.state[f][0])[0].string === "Y" : ''
      }
    }).filter(f => (!Array.isArray(f.value) && f.value !== 'All') || !f.value.includes('All')).filter(f => !this.state.disabled.includes(f.name))
    if (this.state.acv !== null) {
      filters = [
        ...filters,
        {
          name: 'acv',
          value: parseInt(this.state.acv),
          string: false
        }
      ]
    }
    this.props.getfunnelCompareData(this.props.company, filters)


  }

  handleChangeTimeFrame = (e) => {
    this.setState({ ...this.state, timeFrame: e.target.value, disabled: e.target.value === 'custom' ? [...this.state.disabled.filter(f => f !== 'year' && f !== 'month' && f !== 'quarter'), 'cfq'] : [...this.state.disabled.filter(f => f !== 'cfq'), 'year', 'month', 'quarter'] })
  }

  handleChange = e => {
    if (e.target.name === 'year' && e.target.value.filter(v => v === 'All').length > 0) {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.name === 'rep' ? [e.target.value] : e.target.value.length === 0 ? ['All'] : this.state[e.target.name].length === 1 && this.state[e.target.name].includes('All') ? e.target.value.filter(v => v !== 'All').length > 0 ? e.target.value.filter(v => v !== 'All') : ['All'] : e.target.value.includes('All') ? ['All'] : e.target.value,
        month: ['All'],
        quarter: ['All']
      })
    } else {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.name === 'rep' ? [e.target.value] : e.target.value.length === 0 ? ['All'] : this.state[e.target.name].length === 1 && this.state[e.target.name].includes('All') ? e.target.value.filter(v => v !== 'All').length > 0 ? e.target.value.filter(v => v !== 'All') : ['All'] : e.target.value.includes('All') ? ['All'] : e.target.value
      })
    }
  }

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value
    })
  }
  render() {
    const { classes } = this.props

    return (
      <Grid container>

        <Grid item xs={12}>
          <Typography variant='h2' align='center' style={{ marginBottom: 5 }}>Rep Benchmarking Analysis</Typography>
          <Typography variant='body1'>
            Benchmark conversion rates by sales stage for each rep against the cohort of top reps to identify blind spots for proactive, targeted intervention.
          </Typography>
        </Grid>


        <Grid container item style={{ marginBottom: 10 }} xs={12} md={8}>
          <Grid item xs={3} container component={Paper} style={{ marginRight: 10, padding: 10 }}>
            <Grid item xs={6} sm={12}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel style={{ color: !this.state.closed_fiscal_quarter.includes('All') ? '#4472c4' : '#000' }} id="select-vfiscalQuarter-label">Closed Fiscal Quarter:</InputLabel>
                <Select
                  labelId="select-vfiscalQuarter-label"
                  id="select-cfiscalQuarter"
                  value={this.state.closed_fiscal_quarter}
                  onChange={this.handleChange}
                  label="Closed Fiscal Quarter"
                  name='closed_fiscal_quarter'
                  multiple
                >
                  <MenuItem value={'All'} >All</MenuItem>
                  {this.props.funnelCompareFilters === '' && <MenuItem value={nearestCompletedFiscalQuarter()} >{nearestCompletedFiscalQuarter()}</MenuItem>}
                  {this.props.funnelCompareFilters !== '' && this.props.funnelCompareFilters.filter(y => y.type === "closed_fiscal_quarter" && y["closed_fiscal_quarter"] !== '')
                    .map(y => <MenuItem key={y['closed_fiscal_quarter']} value={y['closed_fiscal_quarter']}>{y['closed_fiscal_quarter']}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={3} container component={Paper} style={{ marginRight: 10, padding: 10 }}>
            <Grid item xs={6} sm={12}>
              <Grid item xs={12} sm={12} style={{ paddingTop: 0, height: '0px' }}>

                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                  //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                  onChange={this.onAcvChange}
                  value={this.state.acv}
                  autoSelect={true}
                  renderInput={(params) => (
                    <TextField {...params} label="ACV >= :" margin="normal" variant="standard" style={{ marginTop: 4, }}
                      InputLabelProps={{ shrink: true, style: { color: this.state.acv !== null ? '#4472c4' : '#000' } }}
                      InputProps={{
                        ...params.InputProps,
                        inputComponent: NumberFormatCustom,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />

              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button variant='contained' color='primary' onClick={this.handleGo}>GO</Button>
          </Grid>
        </Grid>








        <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant='h2' align='center'>Benchmark vs Top Reps</Typography>
        </Grid>
        <Grid item xs={12} id='grid-container' style={{ marginBottom: 20, overflow: 'auto' }}>
          <Paper style={{ marginBottom: 20 }}>
            <Grid style={{ margin: 'auto', width: '95%', marginBottom: 20 }} item xs={12} justify="center" alignItems="center">
              {this.props.funnelCompareData !== '' && this.props.persistentAlert.message !== 'Updating data...' ?
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {/* <TableContainer className={classes.mainTable} style={{ width: '100%', marginBottom: 20 }}>
                    <Table size="small" aria-label="html table" style={{ tableLayout: 'auto', marginTop: 20 }}>
                      <TableRow>
                        {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.table}>{this.state.benchmarks.length > 0 ? Math.round(this.state.benchmarks.filter(b => b.stage === s)[0].value * 100) : 0}%</TableCell>)}
                        
                        {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.table}>{this.state.benchmarks_acv.length > 0 ? Math.round(this.state.benchmarks_acv.filter(b => b.stage === s)[0].value * 100) : 0}%</TableCell>)}
                      </TableRow>
                    </Table>
                  </TableContainer> */}
                  <TableContainer className={classes.mainTable} style={{ width: '100%' }}>
                    <Table size="small" aria-label="html table" style={{ tableLayout: 'auto', marginBottom: 20, marginTop: 20 }}>
                      <TableHead>
                        <TableRow>
                          {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.table}>{this.state.benchmarks.length > 0 ? Math.round(this.state.benchmarks.filter(b => b.stage === s)[0].value * 100) : 0}%</TableCell>)}
                          <TableCell align='center' className={classes.rep}>Top Reps</TableCell>
                          {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.table}>{this.state.benchmarks_acv.length > 0 ? Math.round(this.state.benchmarks_acv.filter(b => b.stage === s)[0].value * 100) : 0}%</TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ padding: "10px" }}></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align='center' className={classes.tableHeader} style={{ background: '#BF8F00' }} colSpan={5}>By Opportunity Count</TableCell>
                          <TableCell align='center' className={classes.rep}></TableCell>
                          <TableCell align='center' className={classes.tableHeader} style={{ background: '#548235' }} colSpan={5}>By ACV</TableCell>
                        </TableRow>
                        <TableRow>
                          {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.tableHeader}>{`${stageFormattter(s)} to ${stageFormattter(this.props.funnelCompareData[1][i + 1])}`}</TableCell>)}
                          <TableCell align='center' className={classes.repHeader}>Rep</TableCell>
                          {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.tableHeader}>{`${stageFormattter(s)} to ${stageFormattter(this.props.funnelCompareData[1][i + 1])}`}</TableCell>)}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          [...new Set(this.props.funnelCompareData[0].map(i => i.team))].sort().map(t => {
                            return <><TableRow>
                              {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell style={{ background: '#8EA9DB', color: 'white', fontWeight: 600 }} align='right' className={classes.table}></TableCell>)}
                              <TableCell style={{ background: '#8EA9DB', color: 'white', fontWeight: 600 }} align='left' className={classes.rep}>{t}</TableCell>
                              {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell style={{ background: '#8EA9DB', color: 'white', fontWeight: 600 }} align='right' className={classes.table}></TableCell>)}
                            </TableRow>
                              {[...new Set(this.props.funnelCompareData[0].filter(i => i.team === t).map(j => j.rep))].sort().map(j => <TableRow>
                                {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='right' style={

                                  (this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && (_isNaN(Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].acv * 100)) ? 0 : (Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].acv * 100))))[i]) !== 0
                                    ? ((this.state.benchmarks.length > 0 ? Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].conv * 100) - (Math.round(this.state.benchmarks.filter(b => b.stage === s)[0].value * 100)) : 0) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', fontWeight: 600 } : (this.state.benchmarks.length > 0 ? Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].conv * 100) - (Math.round(this.state.benchmarks.filter(b => b.stage === s)[0].value * 100)) : 0) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', fontWeight: 600 } : {}) : ({ opacity: 0 })

                                } className={classes.table}>


                                  {this.state.benchmarks.length > 0 ? Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].conv * 100) - (Math.round(this.state.benchmarks.filter(b => b.stage === s)[0].value * 100)) : 0}%</TableCell>)
                                }
                                <TableCell align='left' className={classes.rep}>{j}</TableCell>
                                {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='right' style={
                                  (this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && (_isNaN(Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].acv * 100)) ? 0 : (Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].acv * 100))))[i]) !== 0
                                    ? ((this.state.benchmarks_acv.length > 0 ? Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].conv_acv * 100) - (Math.round(this.state.benchmarks_acv.filter(b => b.stage === s)[0].value * 100)) : 0) > 20 ? { background: 'rgb(112, 173, 71)', color: 'white', fontWeight: 600 } : (this.state.benchmarks_acv.length > 0 ? Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].conv_acv * 100) - (Math.round(this.state.benchmarks_acv.filter(b => b.stage === s)[0].value * 100)) : 0) < -20 ? { background: 'rgb(237, 125, 49)', color: 'white', fontWeight: 600 } : {}) : ({ opacity: 0 })

                                } className={classes.table}>{this.state.benchmarks_acv.length > 0 ? Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].conv_acv * 100) - (Math.round(this.state.benchmarks_acv.filter(b => b.stage === s)[0].value * 100)) : 0}%</TableCell>)}
                              </TableRow>)}
                            </>
                          })
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                :
                <Paper
                  style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}
                  raised={true}
                >
                  <Skeleton animation="wave" variant="text" width='80%' style={{ margin: '20px 0px' }} />
                  <Divider style={{ width: '100%' }} />
                  <Skeleton animation="wave" variant="rect" width='80%' height={200} style={{ margin: '20px 0px' }} />
                </Paper>}
            </Grid>
          </Paper>
        </Grid>

        <Divider style={{ width: '100%', marginBottom: 20, marginTop: 20, height: 3 }} />

        <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant='h2' align='center'>Conversion rates from stage to stage</Typography>
        </Grid>
        <Grid item xs={12} id='grid-container' style={{ marginBottom: 20, overflow: 'auto' }}>
          <Paper>
            <Grid style={{ margin: 'auto', width: '95%', marginBottom: 20 }} item xs={12} justify="center" alignItems="center">
              {this.props.funnelCompareData !== '' && this.props.persistentAlert.message !== 'Updating data...' ?
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {/* <TableContainer className={classes.mainTable} style={{ width: '100%', marginBottom: 20 }}>
                    <Table size="small" aria-label="html table" style={{ tableLayout: 'auto', marginTop: 20 }}>
                      <TableRow>
                        {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.table}>{this.state.benchmarks.length > 0 ? Math.round(this.state.benchmarks.filter(b => b.stage === s)[0].value * 100) : 0}%</TableCell>)}
                        <TableCell align='center' className={classes.rep}>Top Reps</TableCell>
                        {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.table}>{this.state.benchmarks_acv.length > 0 ? Math.round(this.state.benchmarks_acv.filter(b => b.stage === s)[0].value * 100) : 0}%</TableCell>)}
                      </TableRow>
                    </Table>
                  </TableContainer> */}
                  <TableContainer className={classes.mainTable} style={{ width: '100%' }}>
                    <Table size="small" aria-label="html table" style={{ tableLayout: 'auto', marginBottom: 20, marginTop: 20 }}>
                      <TableHead>
                        <TableRow>
                          {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.table}>{this.state.benchmarks.length > 0 ? Math.round(this.state.benchmarks.filter(b => b.stage === s)[0].value * 100) : 0}%</TableCell>)}
                          <TableCell align='center' className={classes.rep}>Top Reps</TableCell>
                          {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.table}>{this.state.benchmarks_acv.length > 0 ? Math.round(this.state.benchmarks_acv.filter(b => b.stage === s)[0].value * 100) : 0}%</TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ padding: "10px" }}></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align='center' className={classes.tableHeader} style={{ background: '#BF8F00' }} colSpan={5}>By Opportunity Count</TableCell>
                          <TableCell align='center' className={classes.rep}></TableCell>
                          <TableCell align='center' className={classes.tableHeader} style={{ background: '#548235' }} colSpan={5}>By ACV</TableCell>
                        </TableRow>
                        <TableRow>
                          {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.tableHeader}>{`${stageFormattter(s)} to ${stageFormattter(this.props.funnelCompareData[1][i + 1])}`}</TableCell>)}
                          <TableCell align='center' className={classes.repHeader}>Rep</TableCell>
                          {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='center' className={classes.tableHeader}>{`${stageFormattter(s)} to ${stageFormattter(this.props.funnelCompareData[1][i + 1])}`}</TableCell>)}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          [...new Set(this.props.funnelCompareData[0].map(i => i.team))].sort().map(t => {
                            return <><TableRow>
                              {this.props.funnelCompareData[1].map((s, index) => index < this.props.funnelCompareData[1].length - 1 && <TableCell style={{ background: '#8EA9DB', color: 'white', fontWeight: 600 }} align='right' className={classes.table}>{_isNaN(Math.round((this.sum([...this.props.funnelCompareData[0].filter(x => x.team === t && x.stage === this.props.funnelCompareData[1][index + 1]).map(y => y.count)]) / this.sum([...this.props.funnelCompareData[0].filter(z => z.team === t && z.stage === s).map(i => i.count)])) * 100)) ? 0 : Math.round((this.sum([...this.props.funnelCompareData[0].filter(x => x.team === t && x.stage === this.props.funnelCompareData[1][index + 1]).map(y => y.count)]) / this.sum([...this.props.funnelCompareData[0].filter(z => z.team === t && z.stage === s).map(i => i.count)])) * 100)}%</TableCell>)}
                              <TableCell style={{ background: '#8EA9DB', color: 'white', fontWeight: 600 }} align='left' className={classes.rep}>{t}</TableCell>
                              {this.props.funnelCompareData[1].map((s, index) => index < this.props.funnelCompareData[1].length - 1 && <TableCell style={{ background: '#8EA9DB', color: 'white', fontWeight: 600 }} align='right' className={classes.table}>{_isNaN(Math.round((this.sum([...this.props.funnelCompareData[0].filter(i => i.team === t && i.stage === this.props.funnelCompareData[1][index + 1]).map(i => i.acv)]) / this.sum([...this.props.funnelCompareData[0].filter(i => i.team === t && i.stage === s).map(i => i.acv)])) * 100)) ? 0 : Math.round((this.sum([...this.props.funnelCompareData[0].filter(i => i.team === t && i.stage === this.props.funnelCompareData[1][index + 1]).map(i => i.acv)]) / this.sum([...this.props.funnelCompareData[0].filter(i => i.team === t && i.stage === s).map(i => i.acv)])) * 100)}%</TableCell>)}
                            </TableRow>
                              {[...new Set(this.props.funnelCompareData[0].filter(i => i.team === t).map(j => j.rep))].sort().map(j => <TableRow>
                                {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='right' className={classes.table}>{_isNaN(Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].conv * 100)) ? 0 : (Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].conv * 100))}%</TableCell>)}
                                <TableCell align='left' className={classes.rep}>{j}</TableCell>
                                {this.props.funnelCompareData[1].map((s, i) => i < this.props.funnelCompareData[1].length - 1 && <TableCell align='right' className={classes.table}>{_isNaN(Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].conv_acv * 100)) ? 0 : (Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s)[0].conv_acv * 100))}%</TableCell>)}
                              </TableRow>)}
                            </>
                          })
                        }
                        <TableRow>
                          {this.props.funnelCompareData[3].map((stage, i) => i < this.props.funnelCompareData[3].length - 1 && <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}>{_isNaN(Math.round((this.props.funnelCompareData[3][i + 1].count / this.props.funnelCompareData[3][i].count) * 100)) ? 0 : (Math.round((this.props.funnelCompareData[3][i + 1].count / this.props.funnelCompareData[3][i].count) * 100))}%</TableCell>)}
                          <TableCell style={{ fontWeight: 600 }} align='left' className={classes.rep}>Total</TableCell>
                          {this.props.funnelCompareData[3].map((stage, i) => i < this.props.funnelCompareData[3].length - 1 && <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}>{_isNaN(Math.round((this.props.funnelCompareData[3][i + 1].acv / this.props.funnelCompareData[3][i].acv) * 100)) ? 0 : (Math.round((this.props.funnelCompareData[3][i + 1].acv / this.props.funnelCompareData[3][i].acv) * 100))}%</TableCell>)}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                :
                <Paper
                  style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}
                  raised={true}
                >
                  <Skeleton animation="wave" variant="text" width='80%' style={{ margin: '20px 0px' }} />
                  <Divider style={{ width: '100%' }} />
                  <Skeleton animation="wave" variant="rect" width='80%' height={200} style={{ margin: '20px 0px' }} />
                </Paper>}
            </Grid>
          </Paper>
        </Grid>

        <Divider style={{ width: '100%', marginBottom: 20, marginTop: 20, height: 3 }} />

        <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant='h2' align='center'>Opportunities flowing from stage to stage</Typography>
        </Grid>
        <Grid item xs={12} id='grid-container' style={{ marginBottom: 20, overflow: 'auto' }}>
          <Paper>
            <Grid style={{ margin: 'auto', width: '95%', marginBottom: 20 }} item xs={12} justify="center" alignItems="center">
              {this.props.funnelCompareData !== '' && this.props.persistentAlert.message !== 'Updating data...' ?
                <div style={{ display: 'flex' }}>
                  <TableContainer className={classes.mainTable} style={{ width: '100%' }}>
                    <Table size="small" aria-label="html table" style={{ tableLayout: 'auto', margin: '20px 0 20px 0' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' className={classes.tableHeader} style={{ background: '#BF8F00' }} colSpan={6}>By Opportunity Count</TableCell>
                          <TableCell align='center' className={classes.rep}></TableCell>
                          <TableCell align='center' className={classes.tableHeader} style={{ background: '#548235' }} colSpan={6}>By ACV</TableCell>
                        </TableRow>
                        <TableRow>
                          {this.props.funnelCompareData[1].map(s => <TableCell align='center' className={classes.tableHeader}>{stageFormattter(s)}</TableCell>)}
                          <TableCell align='center' className={classes.repHeader}>Rep</TableCell>
                          {this.props.funnelCompareData[1].map(s => <TableCell align='center' className={classes.tableHeader}>{stageFormattter(s)}</TableCell>)}
                        </TableRow>
                      </TableHead>
                      <TableBody>{ }
                        {
                          [...new Set(this.props.funnelCompareData[0].map(i => i.team))].sort().map(t => {
                            return <><TableRow>
                              {this.props.funnelCompareData[1].map(s => <TableCell style={{ background: '#8EA9DB', color: 'white', fontWeight: 600 }} align='right' className={classes.table}>{this.sum([...this.props.funnelCompareData[0].sort((a, b) => a.rep - b.rep).filter(i => i.team === t && i.stage === s).map(i => i.count)])}</TableCell>)}
                              <TableCell style={{ background: '#8EA9DB', color: 'white', fontWeight: 600 }} align='left' className={classes.rep}>{t}</TableCell>
                              {this.props.funnelCompareData[1].map(s => <TableCell style={{ background: '#8EA9DB', color: 'white', fontWeight: 600 }} align='right' className={classes.table}>${d3.format(",")(Math.round(this.sum([...this.props.funnelCompareData[0].filter(i => i.team === t && i.stage === s).map(i => i.acv)])))}</TableCell>)}
                            </TableRow>
                              {[...new Set(this.props.funnelCompareData[0].filter(i => i.team === t).map(j => j.rep))].sort().map(j => <TableRow>
                                {this.props.funnelCompareData[1].map(s => <TableCell align='right' className={classes.table}>{this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s).map(i => i.count)}</TableCell>)}
                                <TableCell align='left' className={classes.rep}>{j}</TableCell>
                                {this.props.funnelCompareData[1].map(s => <TableCell align='right' className={classes.table}>${d3.format(",")(Math.round(this.props.funnelCompareData[0].filter(i => i.rep === j && i.stage === s).map(i => i.acv)))}</TableCell>)}
                              </TableRow>)}
                            </>
                          })
                        }
                        <TableRow>
                          {this.props.funnelCompareData[1].map(stage => (<TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}>{this.props.funnelCompareData[0].filter(item => item.stage === stage).map(item => item.count).reduce((a, b) => Number(a) + Number(b), 0)}</TableCell>))}
                          <TableCell style={{ fontWeight: 600 }} align='left' className={classes.rep}>Total</TableCell>
                          {this.props.funnelCompareData[1].map(stage => (<TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}>${d3.format(",")(Math.round(this.props.funnelCompareData[0].filter(item => item.stage === stage).map(item => item.acv).reduce((a, b) => Number(a) + Number(b), 0)))}</TableCell>))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                :
                <Paper
                  style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}
                  raised={true}
                >
                  <Skeleton animation="wave" variant="text" width='80%' style={{ margin: '20px 0px' }} />
                  <Divider style={{ width: '100%' }} />
                  <Skeleton animation="wave" variant="rect" width='80%' height={200} style={{ margin: '20px 0px' }} />
                </Paper>}
            </Grid>
          </Paper>
        </Grid>

        <Divider style={{ width: '100%', marginBottom: 20, marginTop: 20, height: 3 }} />

        <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant='h2' align='center'>Top Reps</Typography>
        </Grid>
        <Grid item xs={12} id='grid-container' style={{ marginBottom: 20, overflow: 'auto' }}>
          <Paper>
            <Grid style={{ margin: 'auto', width: '95%', marginBottom: 20 }} item xs={12} justify="center" alignItems="center">
              {this.props.funnelCompareData !== '' && this.props.persistentAlert.message !== 'Updating data...' ?
                <div style={{ display: 'flex' }}>
                  <TableContainer className={classes.mainTable} style={{ width: '100%' }}>
                    <Table size="medium" aria-label="html table" style={{ tableLayout: 'auto', margin: '20px 0 20px 0' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' className={classes.tableHeader}>Rank</TableCell>
                          <TableCell align='center' className={classes.tableHeader} style={{ minWidth: '250px' }}>Rep</TableCell>
                          <TableCell align='center' className={classes.tableHeader}>{(this.props.funnelCompareData[2].filter(item => item.type == "win")[0].label)} ACV</TableCell>
                          <TableCell align='center' className={classes.tableHeader}>{(this.props.funnelCompareData[2].filter(item => item.type == "win")[0].label)} ACV %</TableCell>
                          <TableCell align='center' className={classes.tableHeader}>Cumulative % of Total</TableCell>
                          <TableCell align='center' className={classes.tableHeader}>Pursued ACV</TableCell>
                          <TableCell align='center' className={classes.tableHeader}>Overall Win Rate From {stageFormattter(this.props.funnelCompareData[2].filter(item => item.type == "first")[0].label)} </TableCell>
                          <TableCell align='center' className={classes.tableHeader}>{stageFormattter(this.props.funnelCompareData[2].filter(item => item.type == "first")[0].label)} to {stageFormattter(this.props.funnelCompareData[2].filter(item => item.type == "second")[0].label)} Conversion Rate</TableCell>
                          <TableCell align='center' className={classes.tableHeader}>{stageFormattter(this.props.funnelCompareData[2].filter(item => item.type == "second")[0].label)} to {stageFormattter(this.props.funnelCompareData[2].filter(item => item.type == "win")[0].label)} Conversion Rate</TableCell>


                          <TableCell align='center' className={classes.tableHeader}>{(this.props.funnelCompareData[2].filter(item => item.type == "win")[0].label)} No of Opps</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {

                          this.state.topRepsTable.map(j =>
                            <StyledTableRow style={this.state.topReps.includes(j.rep) && j.cumulative !== 0 ? { background: '#E2EFDA' } : {}}>
                              <TableCell align='right' className={classes.table}>{j.rank}</TableCell>
                              <TableCell align='left' className={classes.table}>{j.rep}</TableCell>
                              <TableCell align='right' className={classes.table}>${d3.format(",")(j.closed_won_acv)}</TableCell>
                              <TableCell align='right' className={classes.table}>{Math.round(j.win_rate_by_total)}%</TableCell>
                              <TableCell align='right' className={classes.table}>{_isNaN(j.cumulative) ? 0 : Math.round(j.cumulative)}%</TableCell>
                              <TableCell align='right' className={classes.table}>${d3.format(",")(j.pursued_acv)}</TableCell>
                              <TableCell align='right' className={classes.table}>{j.win_rate}%</TableCell>
                              <TableCell align='right' className={classes.table}>{j.eToD_conv}%</TableCell>
                              <TableCell align='right' className={classes.table}>{j.dToW_conv}%</TableCell>



                              <TableCell align='right' className={classes.table}>{d3.format(",")(j.closed_won)}</TableCell>
                            </StyledTableRow>
                          )
                        }
                        <TableRow>
                          <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}></TableCell>
                          <TableCell style={{ fontWeight: 600 }} align='left' className={classes.table}>Total</TableCell>
                          <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}>${d3.format(",")(this.state.topRepsTable.map(item => item.closed_won_acv).reduce((a, b) => a + b, 0))}</TableCell>
                          <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}>100%</TableCell>
                          <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}></TableCell>
                          <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}>${d3.format(",")(this.state.topRepsTable.map(item => item.pursued_acv).reduce((a, b) => a + b, 0))}</TableCell>
                          <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}>{_isNaN(Math.round((this.state.topRepsTable.map(item => item.closed_won_acv).reduce((a, b) => a + b, 0) / this.state.topRepsTable.map(item => item.pursued_acv).reduce((a, b) => a + b, 0)) * 100)) ? 0 : (Math.round((this.state.topRepsTable.map(item => item.closed_won_acv).reduce((a, b) => a + b, 0) / this.state.topRepsTable.map(item => item.pursued_acv).reduce((a, b) => a + b, 0)) * 100))}%</TableCell>
                          <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}>{_isNaN(Math.round(this.props.funnelCompareData[3].filter(item => item.stage === this.props.funnelCompareData[2].filter(i => i.type === "first")[0].label)[0].conv_acv * 100)) ? 0 : (Math.round(this.props.funnelCompareData[3].filter(item => item.stage === this.props.funnelCompareData[2].filter(i => i.type === "first")[0].label)[0].conv_acv * 100))}%</TableCell>
                          <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}>{_isNaN(Math.round(this.props.funnelCompareData[3].filter(item => item.stage !== this.state.firstStage).map(item => item.conv_acv).reduce((a, b) => a * b, 1) * 100)) ? 0 : (Math.round(this.props.funnelCompareData[3].filter(item => item.stage !== this.state.firstStage).map(item => item.conv_acv).reduce((a, b) => a * b, 1) * 100))}%</TableCell>
                          <TableCell style={{ fontWeight: 600 }} align='right' className={classes.table}>{d3.format(",")(this.state.topRepsTable.map(item => item.closed_won).reduce((a, b) => a + b, 0))}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                :
                <Paper
                  style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}
                  raised={true}
                >
                  <Skeleton animation="wave" variant="text" width='80%' style={{ margin: '20px 0px' }} />
                  <Divider style={{ width: '100%' }} />
                  <Skeleton animation="wave" variant="rect" width='80%' height={200} style={{ margin: '20px 0px' }} />
                </Paper>}
            </Grid>
          </Paper>
        </Grid>

        <Divider style={{ width: '100%', marginBottom: 20, marginTop: 20, height: 3 }} />


      </Grid >
    )
  }
}




function mapStateToProps(state) {
  const {
    funnelCompareFilters,
    funnelCompareData,
    persistentAlert,
    repPerformanceData,
    user
  } = state.app


  let lastStage = user.company === 'C0003' ? 'Win' : user.company === 'C0002' ? 'Won' : 'Closed Won'

  return {
    repPerformanceData, funnelCompareFilters, funnelCompareData, persistentAlert, sortedReps: funnelCompareData[0] ? [...new Set(funnelCompareData[0].filter(y => y.stage === lastStage).sort((a, b) => b.acv - a.acv).map(j => j.rep))] : '', company: user.company,
    repStages: funnelCompareData[1]
  }
}

const mapDispatchToProps = (dispatch) => ({

  getfunnelCompareData: (company, filters) => {
    dispatch({ type: 'get_funnelCompare_data_request' })
    appService.getChartData(company === 'C0003' ? 'C0003.CHID0000007'  : `${company}.CHID0000009`, filters)
      .then(json => {
        dispatch({ type: 'get_funnelCompare_data_success', json })
      }, error => {
        if (typeof error === 'object') dispatch({ type: 'get_funnelCompare_data_failure', error: 'Something went wrong' })
        else
          dispatch({ type: 'get_funnelCompare_data_failure', error })
      })
  },
  getfunnelCompareFilters: (company) => {
    dispatch({ type: 'get_funnelCompare_filters_request' })
    appService.getChartFilters(company === 'C0003' ? 'C0003.CHID0000007'  : `${company}.CHID0000009`)
      .then(json => {
        dispatch({ type: 'get_funnelCompare_filters_success', json })
        dispatch({ type: 'get_funnelCompare_data_request' })
        appService.getChartData(company === 'C0003' ? 'C0003.CHID0000007'  : `${company}.CHID0000009`, [{ name: 'closed_fiscal_quarter', value: [json.message.filter(y => y.type === "closed_fiscal_quarter" && y["closed_fiscal_quarter"] !== '')[1].closed_fiscal_quarter], string: true }])
          .then(json => {
            dispatch({ type: 'get_funnelCompare_data_success', json })
          }, error => {
            if (typeof error === 'object') dispatch({ type: 'get_funnelCompare_data_failure', error: 'Something went wrong' })
            else
              dispatch({ type: 'get_funnelCompare_data_failure', error })
          })
      }, error => {
        if (typeof error === 'object') dispatch({ type: 'get_funnelCompare_filters_failure', error: 'Something went wrong' })
        else
          dispatch({ type: 'get_funnelCompare_filters_failure', error })
      })
  }

})
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f5f5f5',
    },
  },
}))(TableRow);

const connectedfunnelCompare = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FunnelCompare))
export { connectedfunnelCompare as FunnelCompare }