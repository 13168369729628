import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../../App/app.service";
import {
  getCurrentFiscalQuarter,
  nearestCompletedFiscalQuarter,
} from "../../../util/fiscalQuarterFunctions";
import SummaryTrendsTeamTable from "./components/SummaryTrendsTeamTable";
import { colorStages } from "../AccountHealth/utils";
import { renewalsRow } from "../RenewalsCompleted/components/utils";
import SingleLevelDrilldown from "../../../components/Drilldown/SingleLevelDrilldown";
import AccountHealthDrilldown from "../AccountHealth/components/AccountHealthDrilldown";
import addHeaderdescription from "../../../components/HeaderDescription";
const styles = (theme) => ({
  gridItem: {
    margin: "1rem",
  },
});
class AccountHealthByTeam extends Component {
  filters = ["Status", "Account_Segment"];
  constructor(props) {
    super(props);

    this.state = {
      Status: ["Active"],
      Account_Segment: ["All"],
      disabled: ["year", "month", "quarter"],
      accountSegment: [...renewalsRow],
      filterSegment: [],
      filterStatus: ["Active"],
    };
  }
  componentDidMount() {
    this.props.getAccountHealthByTeamFilters();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const currentQuarter = getCurrentFiscalQuarter();
      this.setState({ ...prevState, Status: ["Active"] });
      const missingSegemnt = this.props.funnelCompareFilters
        .filter(
          (y) => y.type === "Account_Segment" && y["Account_Segment"] !== ""
        )
        .map((item) => item.Account_Segment)
        .filter(function (obj) {
          return renewalsRow.indexOf(obj) == -1;
        });
      this.setState({
        ...this.state,
        accountSegment: [...renewalsRow, ...missingSegemnt],
      });
    }
  }

  passFiltersForDrilldown = () => {
    let filters = [];
    if (!this.state.Status.includes("All")) {
      filters = [...filters, this.state.Status];
    }

    if (this.state.Status.includes("All")) {
      filters = [...filters, "All"];
    }

    return filters[0];
  };

  passFiltersForDrilldown2 = () => {
    let formattedFilters = [];
    //  if (!this.state.Status.includes('All')) {
    //     formattedFilters = [...formattedFilters, { name: 'Status', value: this.state.Status, string: true }]
    //     // formattedFiltersForDrillDown = [...formattedFiltersForDrillDown, { name: 'Status', value: this.state.Status, string: true }]
    // }
    if (!this.state.Account_Segment.includes("All")) {
      formattedFilters = [
        ...formattedFilters,
        {
          name: "Account_Segment",
          value: this.state.Account_Segment,
          string: true,
        },
      ];
      // formattedFiltersForDrillDown = [...formattedFiltersForDrillDown, { name: 'Account_Segment', value: this.state.Account_Segment, string: true }]
    }
    return formattedFilters;
  };
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
    });
    this.props.setTable("");
  };
  handleChangeLocation = (location, filters, type, rowType) => {
    let filtersRow = filters.row;
    let formattedFilters = [
      {
        name: "Overall_Health",
        value:
          rowType === "totalAll"
            ? filters.col
            : rowType === "totalCol"
            ? filters.col
            : [filters.col],
        string: true,
      },
      {
        name: type,
        value:
          rowType === "totalAll"
            ? filters.row
            : rowType === "totalRow"
            ? filters.row
            : [filters.row],
        string: true,
      },
    ];
    let formattedFiltersForDrillDown = [
      {
        name: "Overall_Health",
        value:
          rowType === "totalAll"
            ? filters.col
            : rowType === "totalCol"
            ? "All"
            : [filters.col],
        string: true,
      },
      {
        name: type,
        value:
          rowType === "totalAll"
            ? "All"
            : rowType === "totalRow"
            ? "All"
            : [filters.row],
        string: true,
      },
    ];

    let drilldownLabel = [...formattedFiltersForDrillDown];

    if (!this.state.filterStatus.includes("All")) {
      formattedFilters = [
        ...formattedFilters,
        { name: "Status", value: this.state.filterStatus, string: true },
      ];
      formattedFiltersForDrillDown = [
        ...formattedFiltersForDrillDown,
        { name: "Status", value: this.state.filterStatus, string: true },
      ];
    }
    // if (!this.state.Account_Segment.includes('All')) {
    //     formattedFilters = [...formattedFilters, { name: 'Account_Segment', value: this.state.filterSegment, string: true }]
    //     formattedFiltersForDrillDown = [...formattedFiltersForDrillDown, { name: 'Account_Segment', value: this.state.Account_Segment, string: true }]

    // }

    formattedFilters = [...formattedFilters, ...this.state.filterSegment];
    formattedFiltersForDrillDown = [
      ...formattedFiltersForDrillDown,
      ...this.state.filterSegment,
    ];

    this.props.setTable(type);
    this.props.getChartDrilldown("renewals", formattedFilters);
    this.setState({
      ...this.state,
      location: location,

      filtersForDrilldown: [
        ...formattedFiltersForDrillDown
          .filter((item) => item.name !== "Status")
          .map((item) => ({
            ...item,
            name: item.name === "GS_Head" ? "GS / CU_Head" : item.name,
          }))
          .map((item) => ({ ...item, title: item.name.split("_").join(" ") })),
      ],
    });
  };
  handleStatusType = (e, value) => {
    this.setState({
      ...this.state,
      Status: [e.target.value],
    });
  };
  handleGo = () => {
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    this.setState({
      ...this.state,
      filterStatus: this.passFiltersForDrilldown(),
      filterSegment: this.passFiltersForDrilldown2(),
    });
    this.props.getAccountHealthByTeamData(this.props.company, filters);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer} justify="center">
        {/* <Grid
          className={classes.gridItem}
          item
          xs={12}
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Account Health - By Team
          </Typography>
          <Typography variant="body1">
            Delve into the health status of accounts by team and segment.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Account Health - By Team",
          "Delve into the health status of accounts by team and segment."
        )}
        <Grid
          className={classes.gridItem}
          xs={12}
          item
          container
          justify="flex-start"
        >
          <Grid
            justify="flex-start"
            item
            xs={2}
            container
            component={Paper}
            style={{ padding: 10, marginRight: 10 }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    color: !this.state.Status.includes("All")
                      ? "#4472c4"
                      : "#000",
                  }}
                  id="select-vfiscalQuarter-label"
                >
                  Status:
                </InputLabel>
                <Select
                  labelId="select-vfiscalQuarter-label"
                  id="select-cfiscalQuarter"
                  value={this.state.Status}
                  onChange={this.handleChange}
                  label="Status"
                  name="Status"
                  multiple
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {this.props.funnelCompareFilters === "" && (
                    <MenuItem value={nearestCompletedFiscalQuarter()}>
                      {nearestCompletedFiscalQuarter()}
                    </MenuItem>
                  )}
                  {this.props.funnelCompareFilters !== "" &&
                    this.props.funnelCompareFilters
                      .filter((y) => y.type === "Status" && y["Status"] !== "")
                      .map((y) => (
                        <MenuItem key={y["Status"]} value={y["Status"]}>
                          {y["Status"]}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            justify="flex-start"
            item
            xs={2}
            container
            component={Paper}
            style={{ padding: 10, marginRight: 10 }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  style={{
                    color: !this.state.Account_Segment.includes("All")
                      ? "#4472c4"
                      : "#000",
                  }}
                  id="select-vfiscalQuarter-label"
                >
                  Account Segment:
                </InputLabel>
                <Select
                  labelId="select-vfiscalQuarter-label"
                  id="select-cAccount_Segment"
                  value={this.state.Account_Segment}
                  onChange={this.handleChange}
                  label="Account_Segment"
                  name="Account_Segment"
                  multiple
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {this.props.funnelCompareFilters === "" && (
                    <MenuItem value={nearestCompletedFiscalQuarter()}>
                      {nearestCompletedFiscalQuarter()}
                    </MenuItem>
                  )}
                  {this.state.accountSegment.map((y) => (
                    <MenuItem key={y} value={y}>
                      {y}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            item
            sm={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" color="primary" onClick={this.handleGo}>
              GO
            </Button>
          </Grid>
        </Grid>
        {this.props.accountHealthByTeamData !== "" ? (
          <Grid
            className={classes.gridItem}
            item
            container
            xs={12}
            justify="flex-start"
            component={Card}
          >
            <Grid item style={{ padding: "1rem" }} xs={12}>
              <Typography
                align="center"
                variant="h2"
                style={{ margin: "1rem" }}
              >
                Account Health By CS Team Head
              </Typography>
            </Grid>
            <Grid item container xs={12} md={12} style={{ padding: "1rem" }}>
              <SummaryTrendsTeamTable
                handleChangeLocation={this.handleChangeLocation}
                data={this.props.accountHealthByTeamData[0]}
                type="SFDC"
                row="CS_Head"
                col="Overall_Health"
                columns={colorStages}
                rowHeading="CS Team Head"
              />
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.gridItem} item xs={12}>
            <Card>
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Card>
          </Grid>
        )}
        {this.props.accountHealthByTeamData !== "" ? (
          <Grid
            className={classes.gridItem}
            item
            container
            xs={12}
            justify="flex-start"
            component={Card}
          >
            <Grid item style={{ padding: "1rem" }} xs={12}>
              <Typography
                align="center"
                variant="h2"
                style={{ margin: "1rem" }}
              >
                Account Health By GS / CU Head
              </Typography>
            </Grid>
            <Grid item container xs={12} md={12} style={{ padding: "1rem" }}>
              {/* <QuarterTrendsTable data={this.props.accountHealthData[4]} row="GS_Head" column="Overall_Health" countKey="SFDC" /> */}
              <SummaryTrendsTeamTable
                handleChangeLocation={this.handleChangeLocation}
                data={this.props.accountHealthByTeamData[1]}
                type="SFDC"
                row="GS_Head"
                col="Overall_Health"
                columns={colorStages}
                rowHeading="GS / CU Head"
              />
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.gridItem} item xs={12}>
            <Card>
              <Skeleton
                animation="wave"
                variant="rect"
                width="95%"
                height={230}
                style={{ margin: "2rem" }}
              />
            </Card>
          </Grid>
        )}
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Account Health By ${this.props.tableType
                .split("_")
                .join(" ")}`}
              back={this.handleChangeLocationBack}
              filters={[
                { title: "Status", value: this.state.filterStatus },
                ...this.state.filtersForDrilldown,
              ]}
            >
              <AccountHealthDrilldown
                header={`Account Health By ${this.props.tableType
                  .split("_")
                  .join(" ")}`}
                body={this.props.drilldownOnGraphData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    accountHealthByTeamFilters,
    accountHealthByTeamData,
    drilldownOnGraphData,
    tableType,
    user,
  } = state.app;

  return {
    funnelCompareFilters: accountHealthByTeamFilters,
    accountHealthByTeamData,
    drilldownOnGraphData,
    tableType,
    company: user.company,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },
  getChartDrilldown: (key, filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });

    appService.getChartDrilldown("C0003.CHID00000017", key, filters).then(
      (json) => {
        let tempOpportunities = json.message.recordsets[1];
        tempOpportunities = tempOpportunities.sort((a, b) =>
          a.CARR > b.CARR ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let x = {
            ...item,
            OppNo: index + 1,
          };
          return x;
        });

        // tempOpportunities.sort((a, b) => a.ACV > b.ACV ? -1 : 1)

        // tempOpportunities = tempOpportunities.map((item, index) => {
        //   let createdDateString = (item.Created_Date.split('T')[0])
        //   var [createdYear, createdMonth, createdDate] = createdDateString.split("-")
        //   let temp = {
        //     ...item,
        //     OppNo: index + 1,
        //     age: Math.round(
        //       (new Date(Date.now()).setHours(0) - (new Date(new Date(parseInt(createdYear), parseInt(createdMonth) - 1, parseInt(createdDate.substr(0, 2))).setHours(0)).setMinutes(0))) /
        //       (1000 * 60 * 60 * 24)
        //     ),
        //   }
        //   return temp
        // })

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.recordsets[0],
            opportunities: tempOpportunities,
            // columns: json.columns
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getAccountHealthByTeamFilters: (company) => {
    dispatch({ type: "get_AccountHealthByTeam_filters_request" });
    appService.getChartFilters("C0003.CHID00000025").then(
      (json) => {
        dispatch({ type: "get_AccountHealthByTeam_filters_success", json });
        dispatch({ type: "get_AccountHealthByTeam_data_request" });
        appService
          .getChartData("C0003.CHID00000025", [
            { name: "Status", value: ["Active"], string: true },
          ])
          .then(
            (json) => {
              dispatch({ type: "get_AccountHealthByTeam_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_AccountHealthByTeam_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_AccountHealthByTeam_data_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_AccountHealthByTeam_filters_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_AccountHealthByTeam_filters_failure", error });
      }
    );
  },
  getAccountHealthByTeamData: (company, filters = []) => {
    dispatch({ type: "get_AccountHealthByTeam_data_request" });
    appService.getChartData("C0003.CHID00000025", filters).then(
      (json) => {
        dispatch({ type: "get_AccountHealthByTeam_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_AccountHealthByTeam_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_AccountHealthByTeam_data_failure", error });
      }
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AccountHealthByTeam));
