import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import { AgGridReact } from "ag-grid-react";
import Skeleton from "@material-ui/lab/Skeleton";
import * as d3 from "d3";
import CopyTablesObject from "../../components/xlsx-export/CopyTablesObject";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  border2: {
    border: "0.5px solid lightgrey",
    paddingRight: "8% !important",
    width: "20%",
    paddingBottom: 0,
    paddingTop: 0,
  },
  border3: {
    border: "0.5px solid lightgrey",
    paddingRight: "13% !important",
    width: "33.3333%",
    paddingBottom: 0,
    paddingTop: 0,
  },
  border4: {
    border: "0.5px solid lightgrey",
    paddingRight: "17% !important",
    width: "50%",
    paddingBottom: 0,
    paddingTop: 0,
  },
  border1: {
    border: "0.5px solid lightgrey",
    height: 70,
    background: "#4472C4",
    color: "white",
    fontWeight: 600,
  },
  border: {
    border: "0.5px solid lightgrey",
    background: "black",
    color: "white",
    fontWeight: 600,
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "0px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "0px 5px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});

const nearestCompletedFiscalQuarter = () => {
  let year = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  if (currentMonth < 3) {
    return `${year - 1}-Q4`;
  } else if (currentMonth > 2 && currentMonth < 6) {
    return `${year}-Q1`;
  } else if (currentMonth > 5 && currentMonth < 9) {
    return `${year}-Q2`;
  } else {
    return `${year}-Q3`;
  }
};

const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};

const formatter = (value) => {
  if (_isNaN(value)) return 0;
  return d3.format(",")(value.toFixed(1));
};

const currencyFormatter = (value) => {
  if (_isNaN(value)) return 0;
  return "$" + d3.format(",")(Math.round(value));
};

class CurrentState extends React.Component {
  tableRef = React.createRef();
  tableRef2 = React.createRef();
  tableRef3 = React.createRef();
  tableRef4 = React.createRef();
  tableRef5 = React.createRef();
  tableRef6 = React.createRef();
  tableRef7 = React.createRef();

  state = {
    rowData: "",
    cfq: ["All"],
    vfq:
      this.props.CurrentStateFilters === ""
        ? [nearestCompletedFiscalQuarter()]
        : [this.props.CurrentStateFilters.openFiscalQuarters[1]],
    disabled: [],
    sankeyData: [],
  };

  filters = ["cfq", "vfq"];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  formatDate = (date) => {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      timeZone: "UTC",
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day}-${month}-${year}`;
  };

  componentDidMount = () => {
    this.props.getCurrentStateFilters();
    window.addEventListener("resize", this.onResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
  };

  sum = (input) => {
    if (toString.call(input) !== "[object Array]") return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  };
  onResize = async () => {
    // D3Sankey.destroy(this._rootNode1)
    // this.chart=  D3Sankey.create(this._rootNode1,{data:this.props.CurrentStateData.sankey})
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.CurrentStateFilters !== this.props.CurrentStateFilters) {
      this.setState({
        ...this.state,
        vfq: [this.props.CurrentStateFilters.validatedFiscalQuarters[1]],
        cfq: ["All"],
      });
    }
    if (prevProps.CurrentStateData !== this.props.CurrentStateData) {
      this.setState({
        ...this.state,
        sankeyData: this.props.CurrentStateData.sankey,
      });
    }
    if (
      this.props.CurrentStateData !== prevProps.CurrentStateData &&
      prevProps.CurrentStateData !== ""
    ) {
      this.setState({
        ...this.state,
        sankeyData: this.props.CurrentStateData.sankey,
      });
    }
    if (this.props.CurrentStateData.computed2 !== "") {
      // D3Sankey.destroy(this._rootNode1)
      // this.chart = D3Sankey.create(this._rootNode1,{data:this.props.CurrentStateData.sankey})
    }
  };

  handleGo = () => {
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value: this.state[f],
        };
      })
      .filter((f) => !Array.isArray(f.value) || !f.value.includes("All"))
      .filter((f) => !this.state.disabled.includes(f.name));
    this.props.getCurrentStateData(filters);
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: this.state[e.target.name].includes("All")
        ? e.target.value.includes("All")
          ? [...e.target.value.filter((f) => f !== "All")]
          : [...e.target.value]
        : e.target.value.includes("All")
        ? ["All"]
        : [...e.target.value],
    });
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center">
            Current state of opportunities that came to Qualifying
          </Typography>
          <Typography variant="body1">
            Current state of opportunities that came to Qualifying.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Current state of opportunities that came to Qualifying",
          "Current state of opportunities that came to Qualifying."
        )}
        <Grid container item style={{ marginBottom: 10 }} xs={12} md={8}>
          <Grid
            item
            xs={3}
            container
            component={Paper}
            style={{ marginRight: 10, padding: 10 }}
          >
            <Grid item xs={6} sm={12}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel
                  style={{
                    color: !this.state.vfq.includes("All") ? "#4472c4" : "#000",
                  }}
                  id="select-vfiscalQuarter-label"
                >
                  Qualifying Fiscal Quarter:
                </InputLabel>
                <Select
                  labelId="select-vfiscalQuarter-label"
                  id="select-vfiscalQuarter"
                  value={this.state.vfq}
                  onChange={this.handleChange}
                  label="Validated Fiscal Quarter"
                  name="vfq"
                  multiple
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {this.props.CurrentStateFilters === "" && (
                    <MenuItem value={nearestCompletedFiscalQuarter()}>
                      {nearestCompletedFiscalQuarter()}
                    </MenuItem>
                  )}
                  {this.props.CurrentStateFilters !== "" &&
                    this.props.CurrentStateFilters.validatedFiscalQuarters.map(
                      (y) => (
                        <MenuItem key={y} value={y}>
                          {y}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            container
            component={Paper}
            style={{ marginRight: 10, padding: 10 }}
          >
            <Grid item xs={6} sm={12}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel
                  style={{
                    color: !this.state.cfq.includes("All") ? "#4472c4" : "#000",
                  }}
                  id="select-fiscalQuarter-label"
                >
                  Open Fiscal Quarter:
                </InputLabel>
                <Select
                  labelId="select-fiscalQuarter-label"
                  id="select-fiscalQuarter"
                  value={this.state.cfq}
                  onChange={this.handleChange}
                  label="Open Fiscal Quarter"
                  name="cfq"
                  multiple
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {this.props.CurrentStateFilters !== "" &&
                    this.props.CurrentStateFilters.openFiscalQuarters.map(
                      (y) => (
                        <MenuItem key={y} value={y}>
                          {y}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            sm={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" color="primary" onClick={this.handleGo}>
              GO
            </Button>
          </Grid>
        </Grid>
        {/* <Grid item container justify='space-around'>
          <Grid item xs={6}>
            <Card
              className={clsx(classes.root)}
              raised={true}
            >
              <CardContent className={classes.content}>
                <Chart
                  chartType="Sankey"
                  width="90%"
                  height="400px"
                  data={this.state.sankeyData}
                  options={{
                    tooltip: {
                      html: true
                    },
                    sankey: {
                      iterations: 20, node: {
                        label: {
                          fontName: 'Roboto',
                          fontSize: '0.8rem',
                          //color: '#000',
                          bold: true,
                          italic: false
                        },
                        interactivity: true, // Allows you to select nodes.
                        labelPadding: 2,     // Horizontal distance between the label and the node.
                        nodePadding: 30,     // Vertical distance between nodes.
                        width: 10,
                      }
                    }
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}
        <Grid
          item
          xs={12}
          id="grid-container"
          style={{ marginBottom: 60, overflow: "auto" }}
        >
          {this.props.CurrentStateData !== "" &&
          this.props.persistentAlert.message !== "Updating data..." ? (
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "2rem",
                margin: "1rem",
              }}
            >
              <TableContainer
                /* className={classes.mainTable} */ component={Paper}
                style={{ width: "45.4545%", marginRight: "4.5454%" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                    // marginRight: "10px",
                  }}
                >
                  <CopyTablesObject tableRef={this.tableRef} />
                </div>
                <Table
                  ref={this.tableRef}
                  size="small"
                  aria-label="html table"
                  style={{ tableLayout: "auto" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={2}></TableCell>
                      <TableCell
                        align="center"
                        colSpan={3}
                        className={classes.border}
                      >
                        Next Stage Move
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className={classes.border1}>
                        Stage
                      </TableCell>
                      <TableCell align="center" className={classes.border1}>
                        ##
                      </TableCell>
                      <TableCell align="center" className={classes.border1}>
                        Advances
                      </TableCell>
                      <TableCell align="center" className={classes.border1}>
                        Moves Back or same stage
                      </TableCell>
                      <TableCell align="center" className={classes.border1}>
                        Lost/Omit
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.CurrentStateData.computed.map((i) => (
                      <StyledTableRow key={i.seq}>
                        <TableCell align="center" className={classes.border2}>
                          {i.stage}
                        </TableCell>
                        <TableCell align="right" className={classes.border2}>
                          {i.num === null ? "-" : d3.format(",")(i.num)}
                        </TableCell>
                        <TableCell align="right" className={classes.border2}>
                          {i.advances === null
                            ? "-"
                            : d3.format(",")(i.advances)}
                        </TableCell>
                        <TableCell align="right" className={classes.border2}>
                          {i.same_stage === null
                            ? "-"
                            : d3.format(",")(i.same_stage)}
                        </TableCell>
                        <TableCell align="right" className={classes.border2}>
                          {i.lost_omit === null
                            ? "-"
                            : d3.format(",")(i.lost_omit)}
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer
                /* className={classes.mainTable} */ component={Paper}
                style={{ width: "27.2727%", marginRight: "4.5454%" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                    // marginRight: "10px",
                  }}
                >
                  <CopyTablesObject tableRef={this.tableRef2} />
                </div>
                <Table
                  ref={this.tableRef2}
                  size="small"
                  aria-label="html table"
                  style={{ tableLayout: "auto" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={3}
                        className={classes.border}
                      >
                        Latest Stage Reached (as of Today)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className={classes.border1}>
                        Won
                      </TableCell>
                      <TableCell align="center" className={classes.border1}>
                        Lost/Omit
                      </TableCell>
                      <TableCell align="center" className={classes.border1}>
                        In Pipeline (Active)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.CurrentStateData.computed.map((i) => (
                      <StyledTableRow key={i.seq}>
                        <TableCell align="right" className={classes.border3}>
                          {i.won === null ? "-" : d3.format(",")(i.won)}
                        </TableCell>
                        <TableCell align="right" className={classes.border3}>
                          {i.lost_omit_latest === null
                            ? "-"
                            : d3.format(",")(i.lost_omit_latest)}
                        </TableCell>
                        <TableCell align="right" className={classes.border3}>
                          {i.in_pipeline === null
                            ? "-"
                            : d3.format(",")(i.in_pipeline)}
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer
                /* className={classes.mainTable} */ component={Paper}
                style={{ width: "18.1818%" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                    // marginRight: "10px",
                  }}
                >
                  <CopyTablesObject tableRef={this.tableRef3} />
                </div>
                <Table
                  ref={this.tableRef3}
                  size="small"
                  aria-label="html table"
                  style={{ tableLayout: "auto" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={2}
                        className={classes.border}
                      >
                        Conversion
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className={classes.border1}>
                        To Win
                      </TableCell>
                      <TableCell align="center" className={classes.border1}>
                        To Next Stage
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.CurrentStateData.computed.map((i) => (
                      <StyledTableRow key={i.seq}>
                        <TableCell align="right" className={classes.border4}>
                          {i.to_win === null ? "-" : `${Math.round(i.to_win)}%`}
                        </TableCell>
                        <TableCell align="right" className={classes.border4}>
                          {i.to_next_stage === null
                            ? "-"
                            : `${Math.round(i.to_next_stage)}%`}
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          ) : (
            <Card
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexDirection: "column",
              }}
              raised={true}
            >
              <Skeleton
                animation="wave"
                variant="text"
                width="80%"
                style={{ margin: "20px 0px" }}
              />
              <Divider style={{ width: "100%" }} />
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Card>
          )}
        </Grid>

        <Divider style={{ width: "100%", height: 1, marginBottom: 20 }} />

        <Typography
          variant="body1"
          style={{ marginBottom: 20, marginLeft: "1.4rem" }}
        >
          Alternate Version
        </Typography>

        <Grid
          item
          xs={12}
          id="grid-container"
          style={{ marginBottom: 20, overflow: "auto" }}
        >
          {this.props.CurrentStateData !== "" &&
            this.props.persistentAlert.message !== "Updating data..." && (
              <Card
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1rem",
                  margin: "1rem",
                }}
              >
                <Grid
                  container
                  style={{ margin: "2rem" }}
                  justify="space-between"
                >
                  <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                    <Typography variant="h2" style={{ textAlign: "center" }}>
                      By Opportunity Count
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container justify="flex-end">
                      <Grid item xs={12}>
                        <Grid
                          container
                          justify="flex-end"
                          alignItems="flex-end"
                          style={{ marginLeft: "-4rem" }}
                        >
                          <Grid item xs={6}>
                            <TableContainer
                              /* className={classes.mainTable} */ component={
                                Paper
                              }
                              style={{
                                marginRight: "1rem",
                                marginBottom: "1rem",
                              }}
                            >
                              <Table
                                size="small"
                                aria-label="html table"
                                style={{ tableLayout: "auto" }}
                              >
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="right">
                                      Total Opps that came to Qualifying:
                                    </TableCell>
                                    <TableCell align="right">
                                      {d3.format(",")(
                                        this.props.CurrentStateData.computed3
                                          .total
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="right">
                                      Back to non-open stages - Alive:
                                    </TableCell>
                                    <TableCell align="right">
                                      {d3.format(",")(
                                        this.props.CurrentStateData.computed3
                                          .backToSXStillAlive
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="right">
                                      Back to non-open stages- Later Lost or
                                      Qualified Out:
                                    </TableCell>
                                    <TableCell align="right">
                                      {d3.format(",")(
                                        this.props.CurrentStateData.computed3
                                          .backToSXLaterLost
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="right">
                                      Remaining opportunities:
                                    </TableCell>
                                    <TableCell align="right">
                                      {d3.format(",")(
                                        this.props.CurrentStateData.computed3
                                          .remainingCameToEAndMovedForward
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                          <Grid item xs={2}>
                            <Grid
                              container
                              justify="flex-start"
                              alignItems="flex-start"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  textAlign: "right",
                                  marginBottom: "1.2rem",
                                }}
                              >
                                (
                                {Math.round(
                                  (this.props.CurrentStateData.computed3
                                    .remainingCameToEAndMovedForward /
                                    this.props.CurrentStateData.computed3
                                      .total) *
                                    100
                                )}
                                %)
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container justify="space-evenly">
                      <Grid item xs={6}>
                        <TableContainer
                          /* className={classes.mainTable} */ component={Paper}
                          style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                        >
                          <Table
                            size="small"
                            aria-label="html table"
                            style={{ tableLayout: "auto" }}
                          >
                            <TableBody>
                              <TableRow>
                                <TableCell align="right">
                                  Remaining opportunities:
                                </TableCell>
                                <TableCell align="right">
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3
                                      .remainingCameToEAndMovedForward
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="right">
                                  Lost / Qualified Out from Qualifying onward:
                                </TableCell>
                                <TableCell align="right">
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3
                                      .lostFromEOnward
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="right">Won:</TableCell>
                                <TableCell align="right">
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3.won
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="right">
                                  Currently Active:
                                </TableCell>
                                <TableCell align="right">
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3
                                      .currentlyActive
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TableContainer
                      /* className={classes.mainTable} */ component={Paper}
                      style={{ margin: "1rem 1rem 1rem 0rem" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "10px",
                          // marginRight: "10px",
                        }}
                      >
                        <CopyTablesObject tableRef={this.tableRef4} />
                      </div>
                      <Table
                        ref={this.tableRef4}
                        size="small"
                        aria-label="html table"
                        style={{ tableLayout: "auto" }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell
                              align="center"
                              colSpan={4}
                              className={classes.border}
                            >
                              Summary of moves
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.border1}
                            >
                              Stage
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.border1}
                            >
                              Came to stage
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.border1}
                            >
                              Lost from stage
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.border1}
                            >
                              Active at stage
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.border1}
                            >
                              Advanced to next stage
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.props.CurrentStateData.computed2.map((i) => (
                            <StyledTableRow key={i.seq}>
                              <TableCell
                                align="center"
                                className={classes.border2}
                              >
                                {i.stage}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.border2}
                              >
                                {i.cameToStageAndMovedForward === null
                                  ? "-"
                                  : d3.format(",")(
                                      i.cameToStageAndMovedForward
                                    )}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.border2}
                              >
                                {i.lostFromStage === null
                                  ? "-"
                                  : d3.format(",")(i.lostFromStage)}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.border2}
                              >
                                {i.activeInStage === null
                                  ? "-"
                                  : d3.format(",")(i.activeInStage)}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.border2}
                              >
                                {i.movedToNextStage === null
                                  ? "-"
                                  : d3.format(",")(i.movedToNextStage)}
                              </TableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container justify="space-evenly">
                      <Grid item xs={6}>
                        <TableContainer
                          /* className={classes.mainTable} */ component={Paper}
                          style={{ margin: "1rem 0rem 1rem 1rem" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginBottom: "10px",
                              // marginRight: "10px",
                            }}
                          >
                            <CopyTablesObject tableRef={this.tableRef5} />
                          </div>
                          <Table
                            ref={this.tableRef5}
                            size="small"
                            aria-label="html table"
                            style={{ tableLayout: "auto" }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  className={classes.border}
                                >
                                  Conversion
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className={classes.border1}
                                >
                                  To Win
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.border1}
                                >
                                  To Next Stage
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.props.CurrentStateData.computed2.map(
                                (i) => (
                                  <StyledTableRow key={i.seq}>
                                    <TableCell
                                      align="right"
                                      className={classes.border4}
                                    >
                                      {i.toWin === null
                                        ? "-"
                                        : `${Math.round(i.toWin)}%`}
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={classes.border4}
                                    >
                                      {i.toNextStage === null
                                        ? "-"
                                        : `${Math.round(i.toNextStage)}%`}
                                    </TableCell>
                                  </StyledTableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            )}
        </Grid>

        <Grid
          item
          xs={12}
          id="grid-container"
          style={{ marginBottom: 20, overflow: "auto" }}
        >
          {this.props.CurrentStateData !== "" &&
            this.props.persistentAlert.message !== "Updating data..." && (
              <Card
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1rem",
                  margin: "1rem",
                }}
              >
                <Grid
                  container
                  style={{ margin: "2rem" }}
                  justify="space-between"
                >
                  <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                    <Typography variant="h2" style={{ textAlign: "center" }}>
                      By ACV
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      justify="flex-end"
                      alignItems="flex-end"
                      style={{ marginLeft: "-4rem" }}
                    >
                      <Grid item xs={6}>
                        <TableContainer
                          /* className={classes.mainTable} */ component={Paper}
                          style={{ marginRight: "1rem", marginBottom: "1rem" }}
                        >
                          <Table
                            size="small"
                            aria-label="html table"
                            style={{ tableLayout: "auto" }}
                          >
                            <TableBody>
                              <TableRow>
                                <TableCell align="right">
                                  Total ACV that came to Qualifying:
                                </TableCell>
                                <TableCell align="right">
                                  $
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3
                                      .total_acv
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="right">
                                  Back to non-open stages - Alive:
                                </TableCell>
                                <TableCell align="right">
                                  $
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3
                                      .backToSXStillAlive_acv
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="right">
                                  Back to non-open stages- Later Lost or
                                  Qualified Out:
                                </TableCell>
                                <TableCell align="right">
                                  $
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3
                                      .backToSXLaterLost_acv
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="right">
                                  Remaining ACV:
                                </TableCell>
                                <TableCell align="right">
                                  $
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3
                                      .remainingCameToEAndMovedForward_acv
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>

                      <Grid item xs={2}>
                        <Grid
                          container
                          justify="flex-start"
                          alignItems="flex-start"
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textAlign: "right",
                              marginBottom: "1.2rem",
                            }}
                          >
                            (
                            {Math.round(
                              (this.props.CurrentStateData.computed3
                                .remainingCameToEAndMovedForward_acv /
                                this.props.CurrentStateData.computed3
                                  .total_acv) *
                                100
                            )}
                            )%
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container justify="space-evenly">
                      <Grid item xs={6}>
                        <TableContainer
                          /* className={classes.mainTable} */ component={Paper}
                          style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                        >
                          <Table
                            size="small"
                            aria-label="html table"
                            style={{ tableLayout: "auto" }}
                          >
                            <TableBody>
                              <TableRow>
                                <TableCell align="right">
                                  Remaining ACV:
                                </TableCell>
                                <TableCell align="right">
                                  $
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3
                                      .remainingCameToEAndMovedForward_acv
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="right">
                                  Lost / Qualified Out from Qualifying onward:
                                </TableCell>
                                <TableCell align="right">
                                  $
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3
                                      .lostFromEOnward_acv
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="right">Won:</TableCell>
                                <TableCell align="right">
                                  $
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3
                                      .won_acv
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="right">
                                  Currently Active:
                                </TableCell>
                                <TableCell align="right">
                                  $
                                  {d3.format(",")(
                                    this.props.CurrentStateData.computed3
                                      .currentlyActive_acv
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TableContainer
                      /* className={classes.mainTable} */ component={Paper}
                      style={{ margin: "1rem 1rem 1rem 0" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "10px",
                          // marginRight: "10px",
                        }}
                      >
                        <CopyTablesObject tableRef={this.tableRef6} />
                      </div>
                      <Table
                        ref={this.tableRef6}
                        size="small"
                        aria-label="html table"
                        style={{ tableLayout: "auto" }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell
                              align="center"
                              colSpan={4}
                              className={classes.border}
                            >
                              Summary of moves
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.border1}
                            >
                              Stage
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.border1}
                            >
                              Came to stage
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.border1}
                            >
                              Lost from stage
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.border1}
                            >
                              Active at stage
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.border1}
                            >
                              Advanced to next stage
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.props.CurrentStateData.computed2.map((i) => (
                            <StyledTableRow key={i.seq}>
                              <TableCell
                                align="center"
                                className={classes.border2}
                              >
                                {i.stage}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.border2}
                              >
                                {i.cameToStageAndMovedForward_acv === null
                                  ? "-"
                                  : `$${d3.format(",")(
                                      i.cameToStageAndMovedForward_acv
                                    )}`}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.border2}
                              >
                                {i.lostFromStage_acv === null
                                  ? "-"
                                  : `$${d3.format(",")(i.lostFromStage_acv)}`}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.border2}
                              >
                                {i.activeInStage_acv === null
                                  ? "-"
                                  : `$${d3.format(",")(i.activeInStage_acv)}`}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.border2}
                              >
                                {i.movedToNextStage_acv === null
                                  ? "-"
                                  : `$${d3.format(",")(
                                      i.movedToNextStage_acv
                                    )}`}
                              </TableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container justify="space-evenly">
                      <Grid item xs={6}>
                        <TableContainer
                          /* className={classes.mainTable} */ component={Paper}
                          style={{ margin: "1rem 0rem 1rem 1rem" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginBottom: "10px",
                              // marginRight: "10px",
                            }}
                          >
                            <CopyTablesObject tableRef={this.tableRef7} />
                          </div>
                          <Table
                            ref={this.tableRef7}
                            size="small"
                            aria-label="html table"
                            style={{ tableLayout: "auto" }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  className={classes.border}
                                >
                                  Conversion
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className={classes.border1}
                                >
                                  To Win
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.border1}
                                >
                                  To Next Stage
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.props.CurrentStateData.computed2.map(
                                (i) => (
                                  <StyledTableRow key={i.seq}>
                                    <TableCell
                                      align="right"
                                      className={classes.border4}
                                    >
                                      {i.toWin_acv === null
                                        ? "-"
                                        : `${Math.round(i.toWin_acv)}%`}
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={classes.border4}
                                    >
                                      {i.toNextStage_acv === null
                                        ? "-"
                                        : `${Math.round(i.toNextStage_acv)}%`}
                                    </TableCell>
                                  </StyledTableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            )}
        </Grid>
      </Grid>
    );
  }
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgb(218, 227, 243)",
    },
  },
}))(TableRow);

function mapStateToProps(state) {
  const { CurrentStateFilters, CurrentStateData, persistentAlert } = state.app;

  return {
    CurrentStateFilters,
    CurrentStateData,
    persistentAlert,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getCurrentStateData: (filters) => {
    dispatch({ type: "get_CurrentState_data_request" });
    appService.getCurrentStateData(filters).then(
      (json) => {
        dispatch({ type: "get_CurrentState_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_CurrentState_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_CurrentState_data_failure", error });
      }
    );
  },
  getCurrentStateFilters: () => {
    dispatch({ type: "get_CurrentState_filters_request" });
    appService.getCurrentStateFilters().then(
      (json) => {
        dispatch({ type: "get_CurrentState_filters_success", json });
        dispatch({ type: "get_CurrentState_data_request" });
        appService
          .getCurrentStateData([
            { name: "cfq", value: [json.message.openFiscalQuarters[1]] },
          ])
          .then(
            (json) => {
              dispatch({ type: "get_CurrentState_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_CurrentState_data_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_CurrentState_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_CurrentState_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_CurrentState_filters_failure", error });
      }
    );
  },
});

const connectedCurrentState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CurrentState));
export { connectedCurrentState as CurrentState };
