import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
  getDefaultSalesType,
  getDefaultACV,
} from "../../util/customFunctions";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import WinAcvDrillDownDrilldownBody from "../WinACVAnalysis/WinAcvDrillDown";
import Drilldown from "../../components/Drilldown/Drilldown";
import FiscalQuarterFilter from "../../Filters/FiscalQuarterFilter";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import RegionFilter from "../../Filters/Region_Filter";
import OpportunityType from "../../Filters/OpportunityType";
import AccountSegmentFilter from "../../Filters/AccountSegment";
import {
  colorsForRenewal,
  renewalsRow,
} from "../../CustomerSuccess/Pages/RenewalsCompleted/components/utils";
import Acct_SegmentFilter from "../../Filters/Acct_SegmentFilter";
import FilterContainer from "../../Filters/FilterContainer";
import { getMixKey } from "../../util/generateHeadings";
import DynamicTableSegment from "../../components/DyanmicTableSegment";
import { deSnake } from "../../util/dataFormatter";
import { updatedScheme10 } from "../../util/chartColorScheme";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import DynamictableV2 from "../../components/DynamicTableV2";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as d3 from "d3";
import NumberFormat from "react-number-format";
import addHeaderdescription from "../../components/HeaderDescription";
import { replaceAll } from "../../components/xlsx-export/LabelCange";

const styles = (theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
});

const monthYearObj = {
  1: "Jan.",
  2: "Feb.",
  3: "Mar.",
  4: "Apr.",
  5: "May",
  6: "Jun.",
  7: "Jul.",
  8: "Aug.",
  9: "Sep.",
  10: "Oct.",
  11: "Nov.",
  12: "Dec.",
};

const monthFormmatter = (month) => {
  const [year, mon] = month.split("-");
  const monValue = monthYearObj[parseInt(mon)];
  return `${monValue} ${year}`;
};
class PipelineCreatedMixAnalysis extends Component {
  filters = [
    "Created_Year",
    "Created_Fiscal_Quarter",
    "Created_Month",
    "projected_close_fiscal_quarter",
    "Open_Date_Initial_Fiscal_Quarter",
    "Open_Date_Initial_Calendar_Year",
    "Open_Date_Initial_Calendar_Month",
    "Qualified_Date_Initial_Fiscal_Quarter",
    "Qualified_Date_Initial_Calendar_Month",
    "Qualified_Date_Initial_Calendar_Year",
    "Region_Rollup",
    "Booking_Type_NL_CS_US",
    "Acct_Segment",
  ];
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  state = {
    projected_close_fiscal_quarter: ["All"],
    Qualified_Date_Initial_Fiscal_Quarter: ["All"],
    Created_Year: ["All"],
    Created_Fiscal_Quarter: ["All"],
    Created_Month: ["All"],
    Open_Date_Initial_Fiscal_Quarter: ["All"],
    Open_Date_Initial_Calendar_Year: ["All"],
    Open_Date_Initial_Calendar_Month: ["All"],
    Qualified_Date_Initial_Calendar_Month: ["All"],
    Qualified_Date_Initial_Calendar_Year: ["All"],
    disabled: ["year", "month", "quarter"],
    earliestOpenStage: "",
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Segment: ["All"],
    filtersForDrilldownPcfq: ["All"],
    rep: ["All"],
    repTeam: "rep",
    team: ["All"],
    Acct_Vertical: ["All"],
    Solution_Cat: ["All"],
    Acct_Segment: ["All"],
    Cust_Type: ["All"],
    Acct_Industry: ["All"],
    Acct_Size_Segment: ["All"],
    ACV_Range: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Type: ["All"],
    Lead_Source: ["All"],
    Product_Line: ["All"],
    milestone_stage: "",
    selectCol: "Open_Date_Initial_Fiscal_Quarter",
    group_by: "group_by_quarter",
    isMonth: false,
    tab: "Summary",
    acv: "",
    acvlt: "",
    toggleValue: "AcvSort",
    Partner_Opp: ["All"],
    Opportunity_Record_Type: ["All"],
  };

  oppTypeName = "Type";
  componentDidMount() {
    this.props.getfunnelCompareFilters(this.props.company);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const stageSequence = getDefaultSelectedStage(
        this.props.funnelCompareFilters.filter(
          (f) => f.type === "milestone_stage"
        )
      );
      let stage_names = [];
      const stageLength = this.props.funnelCompareFilters.filter(
        (f) => f.type === "milestone_stage"
      ).length;
      let col = "Open_Date_Initial_Fiscal_Quarter";
      if (stageLength === 1) {
        col = "Qualified_Date_Initial_Fiscal_Quarter";
      }
      this.props.funnelCompareFilters.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence == stageSequence) stage_names = i.stage_name;
        }
      });
      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      let oppTypes = ["All"];
      let oppTypeColumn;
      if (this.props.funnelCompareFilters.filter((s) => s.oppType).length > 0) {
        oppTypeColumn = this.props.funnelCompareFilters.filter(
          (s) => s.oppType
        )[0].oppType;
        this.oppTypeName = oppTypeColumn;
        this.variableCols = {
          ...this.variableCols,
          [oppTypeColumn]: oppTypeColumn,
        };
        oppTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter((s) => s.oppType),
          oppTypeColumn
        );
        this.setState({
          ...prevState,
          [oppTypeColumn]: oppTypes,
        });
      }
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter(
            (s) => s.type === "cross_sell1__c"
          ),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter((s) => s.type === "Type"),
          "Type"
        );
      }

      console.log(
        getDefaultACV(
          this.props.funnelCompareFilters.filter((s) => s.type === "acv")
        ),
        "ACV"
      );

      this.setState({
        ...prevState,
        selectCol: col,
        acv:
          getDefaultACV(
            this.props.funnelCompareFilters.filter((s) => s.type === "acv")
          ) !== ""
            ? d3.format(",")(
                String(
                  getDefaultACV(
                    this.props.funnelCompareFilters.filter(
                      (s) => s.type === "acv"
                    )
                  )
                )
              )
            : getDefaultACV(
                this.props.funnelCompareFilters.filter((s) => s.type === "acv")
              ),
        Created_Fiscal_Quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "Created_Fiscal_Quarter"
        ),
        Qualified_Date_Initial_Fiscal_Quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "Qualified_Date_Initial_Fiscal_Quarter"
        ),
        Open_Date_Initial_Fiscal_Quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "Open_Date_Initial_Fiscal_Quarter"
        ),
        milestone_stage: stageSequence,
        filtersForDrilldownPcfq: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "Open_Date_Initial_Fiscal_Quarter"
        ),
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
      });
    }
  }

  onAcvChange = (e, value) => {
    console.log(value);
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onAcvLtChange = (e, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  handleChangeTab = (e) => {
    this.handleGo();
    console.log(e.target.value);
    this.setState({
      ...this.state,
      tab: e.target.value,
    });
  };

  handleChangeLocation = (location, data, type, quarter, team) => {
    console.log(data, quarter);
    const quarterFilter = this.props.currentFilters.filter(
      (item) => item.name === quarter
    );
    if (location !== "overview") {
      this.props.setTable("PipelineMix");
      let filters;

      if (type === "Acct_Segment_Rollup") {
        filters = [
          {
            name: quarter === "month" ? "group_by_month" : quarter,
            value:
              data.cell !== "Total"
                ? typeof data.cell === "string"
                  ? [data.cell]
                  : data.cell
                : quarterFilter.length > 0
                ? quarterFilter[0].value
                : this.props.funnelCompareFilters !== "" &&
                  this.props.funnelCompareFilters
                    .filter((y) => y.type === quarter && y[quarter] !== "")
                    .map((y) => y[quarter])
                    .filter((item) => item !== null),
            string: true,
          },
          {
            name: type,
            value: typeof data.row === "string" ? [data.row] : data.row,
            string: true,
          },
        ];
      } else {
        const uniqueStages = this.props.pipelineMixAnalysisData
          .filter((item) => item.type === type)[0]
          .data.map((item) => item[type])
          .filter(getUniqueValues);
        filters = [
          {
            name: quarter === "month" ? "group_by_month" : quarter,
            value:
              data.cell !== "Total"
                ? typeof data.cell === "string"
                  ? [data.cell]
                  : data.cell
                : quarterFilter.length > 0
                ? quarterFilter[0].value
                : this.props.funnelCompareFilters !== "" &&
                  this.props.funnelCompareFilters
                    .filter((y) => y.type === quarter && y[quarter] !== "")
                    .map((y) => y[quarter])
                    .filter((item) => item !== null),
            string: true,
          },
          {
            name: type,
            value:
              data.row !== "Total"
                ? data.row === "Others" && type === "Acct_Industry"
                  ? this.props.pipelineMixAnalysisData.filter(
                      (item) => item.type === "Acct_Industry"
                    )[0].otherIndustries
                  : typeof data.row === "string"
                  ? [data.row]
                  : data.row
                : uniqueStages.includes("Others") && type === "Acct_Industry"
                ? [
                    ...uniqueStages.filter((c) => c !== "Others"),
                    ...this.props.pipelineMixAnalysisData.filter(
                      (item) => item.type === "Acct_Industry"
                    )[0].otherIndustries,
                  ]
                : uniqueStages,
            string: true,
          },
        ];
      }

      if (team !== undefined) {
        filters = [
          ...filters,
          {
            name: "Team",
            value: typeof team === "string" ? [team] : team,
            string: true,
          },
        ];
      }

      let localFilters = this.props.currentFilters;

      filters = [...filters, ...localFilters];

      if (type === "Apps_Cluster") {
        this.props.getPbiSummaryDrilldown(
          filters.filter((i) => i.name !== "Apps_Cluster"),
          {
            appsCluster:
              data.row !== "Total"
                ? typeof data.row === "string"
                  ? [data.row]
                  : data.row
                : "All",
            oppStatus: "open",
            type: "appsCluster",
          }
        );
      } else if (type === "Product_Line") {
        this.props.getPbiSummaryDrilldown(
          [
            ...filters.filter((i) => i.name !== "Product_Line"),
            ...localFilters.filter((i) => i.name === "Product_Line"),
          ],
          {
            appsCluster:
              data.row !== "Total"
                ? typeof data.row === "string"
                  ? [data.row]
                  : data.row
                : "All",
            oppStatus: "open",
            type: "productLine",
          }
        );
      } else {
        this.props.getPipelineMixDrilldown(filters);
      }

      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        filtersForDrilldown: [
          {
            title:
              type === "Acct_Segment_Rollup"
                ? "Segment"
                : this.props.pipelineMixAnalysisData.filter(
                    (i) => i.type === type
                  )[0].label,
            value:
              data.row !== "Total"
                ? type === "Owner_Name" &&
                  typeof data.row !== "string" &&
                  data.row.length > 10
                  ? "All"
                  : data.row
                : "All",
          },
          {
            title: deSnake(quarter),
            value:
              data.cell !== "Total"
                ? data.cell
                : this.state.filtersForDrilldownPcfq,
            // value: data.cell !== "Total" ? data.cell : this.state[quarter].includes("All") ?
            //     "All" : this.state[quarter]
          },
        ],
      });
    } else {
      this.props.clearDrilldownData();
    }
  };
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };

  // handleChangeTimeFrame = e => {

  // }

  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };
  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
      secondLevelLocation: "",
    });
    this.props.setTable("");
  };

  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Region_Rollup",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Booking_Type_NL_CS_US",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Acct_Vertical",
          projectedQuarter: "projected_close_fiscal_quarter",
          ACV_Range: "ACV_Range",
          Product_Line: "Product_Line",
          Open_Date_Initial_Fiscal_Quarter: "Open_Date_Initial_Fiscal_Quarter",
          Qualified_Date_Initial_Fiscal_Quarter:
            "Qualified_Date_Initial_Fiscal_Quarter",
          Open_Date_Initial_Calendar_Year: "Open_Date_Initial_Calendar_Year",
          Open_Date_Initial_Calendar_Month: "Open_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Month:
            "Qualified_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Year:
            "Qualified_Date_Initial_Calendar_Year",
          Created_Fiscal_Quarter: "Created_Fiscal_Quarter",
          Created_Month: "Created_Month",
          Created_Year: "Created_Year",
        }
      : this.props.company === "C0008"
      ? {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Product_Line",
          projectedQuarter: "projected_close_fiscal_quarter",
          ACV_Range: "ACV_Range",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Lead_Source: "Lead_Source",
          Open_Date_Initial_Fiscal_Quarter: "Open_Date_Initial_Fiscal_Quarter",
          Qualified_Date_Initial_Fiscal_Quarter:
            "Qualified_Date_Initial_Fiscal_Quarter",
          Open_Date_Initial_Calendar_Year: "Open_Date_Initial_Calendar_Year",
          Open_Date_Initial_Calendar_Month: "Open_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Month:
            "Qualified_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Year:
            "Qualified_Date_Initial_Calendar_Year",
          Created_Fiscal_Quarter: "Created_Fiscal_Quarter",
          Created_Month: "Created_Month",
          Created_Year: "Created_Year",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Solution_Cat",
          projectedQuarter: "projected_close_fiscal_quarter",
          ACV_Range: "ACV_Range",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Lead_Source: "Lead_Source",
          Open_Date_Initial_Fiscal_Quarter: "Open_Date_Initial_Fiscal_Quarter",
          Qualified_Date_Initial_Fiscal_Quarter:
            "Qualified_Date_Initial_Fiscal_Quarter",
          Open_Date_Initial_Calendar_Year: "Open_Date_Initial_Calendar_Year",
          Open_Date_Initial_Calendar_Month: "Open_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Month:
            "Qualified_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Year:
            "Qualified_Date_Initial_Calendar_Year",
          Created_Fiscal_Quarter: "Created_Fiscal_Quarter",
          Created_Month: "Created_Month",
          Created_Year: "Created_Year",
          Partner_Opp: "Partner_Opp",
          Opportunity_Record_Type: "Opportunity_Record_Type",
        };

  staticCustSegment =
    this.props.company === "C0002"
      ? [
          { value: "<=$100M", displayValue: "<=$100M" },
          { value: "$100M-$1B", displayValue: "$100M-$1B" },
          { value: ">$1B", displayValue: ">$1B" },
        ]
      : [
          { value: "<=$25M", displayValue: "<=$25M" },
          { value: "$25-50M", displayValue: "$25M-50M" },
          { value: "$50-250M", displayValue: "$50M-250M" },
          { value: "$250-500M", displayValue: "$250M-500M" },
          { value: "$500M-2B", displayValue: "$500M-2B" },
          { value: ">$2B", displayValue: ">$2B" },
        ];

  orderRepsByLastName = () => {
    let temp = this.props.funnelCompareFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleChangeToggle = (e) => {
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  handleGo = () => {
    var filters1 = this.props.lkpFilters.filter((item) => item !== "sequence");
    filters1.push("Open_Date_Initial_Calendar_Month");
    filters1.push("Qualified_Date_Initial_Calendar_Month");
    filters1.push("Created_Month");
    filters1.push("group_by");
    let col = "Open_Date_Initial_Fiscal_Quarter";
    console.log(this.state.milestone_stage, "STAGE_SEQ");
    // console.log(filters1)
    if (
      this.props.funnelCompareFilters.filter(
        (i) => i.type === "milestone_stage"
      ).length > 2
    ) {
      if (
        this.state.milestone_stage ===
        this.props.funnelCompareFilters
          .filter((i) => i.type === "milestone_stage")
          .map((i) => i.sequence)[2]
      ) {
        filters1 = filters1.filter(
          (item) =>
            ![
              "Open_Date_Initial_Fiscal_Quarter",
              "Open_Date_Initial_Calendar_Year",
              "Open_Date_Initial_Calendar_Month",
              "Created_Fiscal_Quarter",
              "Created_Month",
              "Created_Year",
            ].includes(item)
        );
        col = "Qualified_Date_Initial_Fiscal_Quarter";
      }

      if (
        this.state.milestone_stage ===
        this.props.funnelCompareFilters
          .filter((i) => i.type === "milestone_stage")
          .map((i) => i.sequence)[1]
      ) {
        filters1 = filters1.filter(
          (item) =>
            ![
              "Qualified_Date_Initial_Fiscal_Quarter",
              "Qualified_Date_Initial_Calendar_Month",
              "Qualified_Date_Initial_Calendar_Year",
              "Created_Fiscal_Quarter",
              "Created_Month",
              "Created_Year",
            ].includes(item)
        );
        col = "Open_Date_Initial_Fiscal_Quarter";
      }

      if (
        this.state.milestone_stage ===
        this.props.funnelCompareFilters
          .filter((i) => i.type === "milestone_stage")
          .map((i) => i.sequence)[0]
      ) {
        filters1 = filters1.filter(
          (item) =>
            ![
              "Qualified_Date_Initial_Fiscal_Quarter",
              "Qualified_Date_Initial_Calendar_Month",
              "Qualified_Date_Initial_Calendar_Year",
              "Open_Date_Initial_Fiscal_Quarter",
              "Open_Date_Initial_Calendar_Year",
              "Open_Date_Initial_Calendar_Month",
            ].includes(item)
        );
        col = "Created_Fiscal_Quarter";
      }
    } else if (
      this.props.funnelCompareFilters.filter(
        (i) => i.type === "milestone_stage"
      ).length > 1
    ) {
      if (
        this.state.milestone_stage ===
        this.props.funnelCompareFilters
          .filter((i) => i.type === "milestone_stage")
          .map((i) => i.sequence)[1]
      ) {
        filters1 = filters1.filter(
          (item) =>
            ![
              "Open_Date_Initial_Fiscal_Quarter",
              "Open_Date_Initial_Calendar_Year",
              "Open_Date_Initial_Calendar_Month",
              "Created_Fiscal_Quarter",
              "Created_Month",
              "Created_Year",
            ].includes(item)
        );
        col = "Qualified_Date_Initial_Fiscal_Quarter";
      }

      if (
        this.state.milestone_stage ===
        this.props.funnelCompareFilters
          .filter((i) => i.type === "milestone_stage")
          .map((i) => i.sequence)[0]
      ) {
        filters1 = filters1.filter(
          (item) =>
            ![
              "Qualified_Date_Initial_Fiscal_Quarter",
              "Qualified_Date_Initial_Calendar_Month",
              "Qualified_Date_Initial_Calendar_Year",
              "Created_Fiscal_Quarter",
              "Created_Month",
              "Created_Year",
            ].includes(item)
        );
        col = "Open_Date_Initial_Fiscal_Quarter";
      }
    } else {
      filters1 = filters1.filter(
        (item) =>
          ![
            "Open_Date_Initial_Fiscal_Quarter",
            "Open_Date_Initial_Calendar_Year",
            "Open_Date_Initial_Calendar_Month",
            "Created_Fiscal_Quarter",
            "Created_Month",
            "Created_Year",
          ].includes(item)
      );
      col = "Qualified_Date_Initial_Fiscal_Quarter";
    }
    // console.log(this.state.milestone_stage, filters1)
    // console.log(this.props.funnelCompareFilters.filter(i => i.type === 'Open_Date_Initial_Fiscal_Quarter' && i['Open_Date_Initial_Fiscal_Quarter'] === this.state['Open_Date_Initial_Fiscal_Quarter'][0])[0], this.state)
    let filters = filters1
      .map((f) => {
        // console.log(f)
        // console.log(this.state[f], f)
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_names = [];
    this.props.funnelCompareFilters.map((i) => {
      if (i.milestone_stage !== undefined) {
        if (i.sequence === this.state.milestone_stage)
          stage_names = [i.milestone_stage];
      }
    });
    console.log(filters);
    filters = [
      ...filters,
      {
        name: "milestone_stage",
        value: stage_names,
        string: true,
      },
    ];
    if (this.state.group_by === "group_by_quarter") {
      filters = filters.filter((f) => f.name !== "group_by");
    }
    let ismonth = false;
    if (this.state.group_by === "group_by_month") {
      col = "month";
      ismonth = true;
    }

    if (
      this.state.acv !== null &&
      this.state.acv !== 0 &&
      this.state.acv !== ""
    ) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    } else if (this.state.acv === 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }

    if (
      this.state.acvlt !== null &&
      this.state.acvlt !== 0 &&
      this.state.acvlt !== ""
    ) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    } else if (this.state.acvlt === 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }

    this.setState({
      ...this.state,
      filtersForDrilldownPcfq: this.passFiltersForDrilldown(),
      selectCol: col,
      isMonth: ismonth,
    });
    this.props.getPipelineMixAnalysisData(this.props.company, filters);
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.projected_close_fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        this.state.projected_close_fiscal_quarter,
      ];
    }

    if (this.state.projected_close_fiscal_quarter.includes("All")) {
      filtersArray = [...filtersArray, "All"];
    }

    return filtersArray[0];
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    console.log("Value_Label_pipeline_mix", this.props.Value_Label);
    console.log(this.state.acv);
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid className={classes.grid} item xs={12} style={{ marginBottom: 10 }}>
                    <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Pipeline Created Mix Analysis</Typography>
                    <Typography variant='body1'>
                    Analyze the pipeline created across multiple dimensions to spot trends and patterns.
                    </Typography>
                </Grid> */}
        {addHeaderdescription(
          "Pipeline Created Mix Analysis",
          "Analyze the pipeline created across multiple dimensions to spot trends and patterns."
        )}
        {this.props.funnelCompareFilters !== "" ? (
          <Grid item container xs={12}>
            <Grid container item style={{ padding: 10 }} xs={2} md={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-stage-label"
                      style={{ color: "#4472c4", width: 144 }}
                    >
                      Milestone Stage:
                    </InputLabel>
                    <Select
                      labelId="select-stage-label"
                      id="select-stage"
                      value={this.state.milestone_stage}
                      onChange={this.handleChangeEarliestOpenStage}
                      label="Milestone Stage:"
                      name="milestone_stage"
                      data-test="milestine_stage"
                    >
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.map(
                          (y) =>
                            y.milestone_stage && (
                              <MenuItem key={y.sequence} value={y.sequence}>
                                {y.milestone_stage}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {this.state.milestone_stage ===
            this.props.funnelCompareFilters
              .filter(
                (i) => i.type === "milestone_stage" && i.col === "created"
              )
              .map((i) => i.sequence)[0] ? (
              <Grid container item style={{ padding: 10 }} xs={6} md={6}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={
                        this.state.Created_Year[0] !== "All" ||
                        this.state.Created_Month[0] !== "All"
                      }
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Created_Fiscal_Quarter
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-fiscalQuarter-label"
                      >
                        Created Fiscal Quarter:
                      </InputLabel>
                      <Select
                        value={
                          this.state[this.variableCols.Created_Fiscal_Quarter]
                        }
                        onChange={this.handleChange}
                        name={this.variableCols.Created_Fiscal_Quarter}
                        data-test="Created_Fiscal_Quarter"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>

                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters.map((y) =>
                            y.type ===
                            this.variableCols.Created_Fiscal_Quarter ? (
                              <MenuItem
                                key={
                                  y[this.variableCols.Created_Fiscal_Quarter]
                                }
                                value={
                                  y[this.variableCols.Created_Fiscal_Quarter]
                                }
                              >
                                {y[this.variableCols.Created_Fiscal_Quarter]}
                              </MenuItem>
                            ) : null
                          )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={this.state.Created_Fiscal_Quarter[0] !== "All"}
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Created_Month
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-year-label"
                      >
                        Created Calendar Year:
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.Created_Year]}
                        onChange={this.handleChange}
                        name={this.variableCols.Created_Year}
                        data-test="Created_Year"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>

                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters.map((y) =>
                            y.type === this.variableCols.Created_Year ? (
                              <MenuItem
                                key={y[this.variableCols.Created_Year]}
                                value={y[this.variableCols.Created_Year]}
                              >
                                {y[this.variableCols.Created_Year]}
                              </MenuItem>
                            ) : null
                          )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl
                      disabled={this.state.Created_Fiscal_Quarter[0] !== "All"}
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Created_Month
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-month-label"
                      >
                        Created Calendar Month:
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.Created_Month]}
                        onChange={this.handleChange}
                        name={this.variableCols.Created_Month}
                        data-test="Created_Month"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                          <MenuItem key={y} value={y}>
                            {this.months[y - 1]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            ) : this.state.milestone_stage ===
              this.props.funnelCompareFilters
                .filter((i) => i.type === "milestone_stage")
                .filter((i) => i.col === "open")
                .map((i) => i.sequence)[0] ? (
              <Grid container item style={{ padding: 10 }} xs={6} md={6}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={
                        this.state.Open_Date_Initial_Calendar_Year[0] !==
                          "All" ||
                        this.state.Open_Date_Initial_Calendar_Month[0] !== "All"
                      }
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Open_Date_Initial_Fiscal_Quarter
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-fiscalQuarter-label"
                      >
                        Open Initial Fiscal Quarter:
                      </InputLabel>
                      <Select
                        value={
                          this.state[
                            this.variableCols.Open_Date_Initial_Fiscal_Quarter
                          ]
                        }
                        onChange={this.handleChange}
                        name={
                          this.variableCols.Open_Date_Initial_Fiscal_Quarter
                        }
                        data-test="Open_Date_Initial_Fiscal_Quarter"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>

                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters.map((y) =>
                            y.type ===
                            this.variableCols
                              .Open_Date_Initial_Fiscal_Quarter ? (
                              <MenuItem
                                key={
                                  y[
                                    this.variableCols
                                      .Open_Date_Initial_Fiscal_Quarter
                                  ]
                                }
                                value={
                                  y[
                                    this.variableCols
                                      .Open_Date_Initial_Fiscal_Quarter
                                  ]
                                }
                              >
                                {
                                  y[
                                    this.variableCols
                                      .Open_Date_Initial_Fiscal_Quarter
                                  ]
                                }
                              </MenuItem>
                            ) : null
                          )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={
                        this.state.Open_Date_Initial_Fiscal_Quarter[0] !== "All"
                      }
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Open_Date_Initial_Calendar_Year
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-year-label"
                      >
                        Open Initial Calendar Year:
                      </InputLabel>
                      <Select
                        value={
                          this.state[
                            this.variableCols.Open_Date_Initial_Calendar_Year
                          ]
                        }
                        onChange={this.handleChange}
                        name={this.variableCols.Open_Date_Initial_Calendar_Year}
                        data-test="Open_Date_Initial_Calendar_Year"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>

                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters.map((y) =>
                            y.type ===
                            this.variableCols
                              .Open_Date_Initial_Calendar_Year ? (
                              <MenuItem
                                key={
                                  y[
                                    this.variableCols
                                      .Open_Date_Initial_Calendar_Year
                                  ]
                                }
                                value={
                                  y[
                                    this.variableCols
                                      .Open_Date_Initial_Calendar_Year
                                  ]
                                }
                              >
                                {
                                  y[
                                    this.variableCols
                                      .Open_Date_Initial_Calendar_Year
                                  ]
                                }
                              </MenuItem>
                            ) : null
                          )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl
                      disabled={
                        this.state.Open_Date_Initial_Fiscal_Quarter[0] !== "All"
                      }
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Open_Date_Initial_Calendar_Month
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-month-label"
                      >
                        Open Initial Calendar Month:
                      </InputLabel>
                      <Select
                        value={
                          this.state[
                            this.variableCols.Open_Date_Initial_Calendar_Month
                          ]
                        }
                        onChange={this.handleChange}
                        name={
                          this.variableCols.Open_Date_Initial_Calendar_Month
                        }
                        data-test="Open_Date_Initial_Calendar_Month"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                          <MenuItem key={y} value={y}>
                            {this.months[y - 1]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container item style={{ padding: 10 }} xs={6} md={6}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={
                        this.state.Qualified_Date_Initial_Calendar_Month[0] !==
                          "All" ||
                        this.state.Qualified_Date_Initial_Calendar_Year[0] !==
                          "All"
                      }
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols
                              .Qualified_Date_Initial_Fiscal_Quarter
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-fiscalQuarter-label"
                      >
                        Qualified Initial Fiscal Quarter:
                      </InputLabel>
                      <Select
                        value={
                          this.state[
                            this.variableCols
                              .Qualified_Date_Initial_Fiscal_Quarter
                          ]
                        }
                        onChange={this.handleChange}
                        name={
                          this.variableCols
                            .Qualified_Date_Initial_Fiscal_Quarter
                        }
                        data-test="Qualified_Date_Initial_Fiscal_Quarter"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>

                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters.map((y) =>
                            y.type ===
                            this.variableCols
                              .Qualified_Date_Initial_Fiscal_Quarter ? (
                              <MenuItem
                                key={
                                  y[
                                    this.variableCols
                                      .Qualified_Date_Initial_Fiscal_Quarter
                                  ]
                                }
                                value={
                                  y[
                                    this.variableCols
                                      .Qualified_Date_Initial_Fiscal_Quarter
                                  ]
                                }
                              >
                                {
                                  y[
                                    this.variableCols
                                      .Qualified_Date_Initial_Fiscal_Quarter
                                  ]
                                }
                              </MenuItem>
                            ) : null
                          )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={
                        this.state.Qualified_Date_Initial_Fiscal_Quarter[0] !==
                        "All"
                      }
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols
                              .Qualified_Date_Initial_Calendar_Year
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-year-label"
                      >
                        Qualified Initial Calendar Year:
                      </InputLabel>
                      <Select
                        value={
                          this.state[
                            this.variableCols
                              .Qualified_Date_Initial_Calendar_Year
                          ]
                        }
                        onChange={this.handleChange}
                        name={
                          this.variableCols.Qualified_Date_Initial_Calendar_Year
                        }
                        data-test="Qualified_Date_Initial_Calendar_Year"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>

                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters.map((y) =>
                            y.type ===
                            this.variableCols
                              .Qualified_Date_Initial_Calendar_Year ? (
                              <MenuItem
                                key={
                                  y[
                                    this.variableCols
                                      .Qualified_Date_Initial_Calendar_Year
                                  ]
                                }
                                value={
                                  y[
                                    this.variableCols
                                      .Qualified_Date_Initial_Calendar_Year
                                  ]
                                }
                              >
                                {
                                  y[
                                    this.variableCols
                                      .Qualified_Date_Initial_Calendar_Year
                                  ]
                                }
                              </MenuItem>
                            ) : null
                          )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl
                      disabled={
                        this.state.Qualified_Date_Initial_Fiscal_Quarter[0] !==
                        "All"
                      }
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols
                              .Qualified_Date_Initial_Calendar_Month
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-month-label"
                      >
                        Qualified Initial Calendar Month:
                      </InputLabel>
                      <Select
                        value={
                          this.state[
                            this.variableCols
                              .Qualified_Date_Initial_Calendar_Month
                          ]
                        }
                        onChange={this.handleChange}
                        name={
                          this.variableCols
                            .Qualified_Date_Initial_Calendar_Month
                        }
                        data-test="Open_Date_Initial_Calendar_Month"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                          <MenuItem key={y} value={y}>
                            {this.months[y - 1]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={2}
              md={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.projectedQuarter
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Proj. Close Fiscal Quarter:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.projectedQuarter]}
                      onChange={this.handleChange}
                      name={this.variableCols.projectedQuarter}
                      data-test="projected-close-fiscal-quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>

                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.map((y) =>
                          y.type === this.variableCols.projectedQuarter ? (
                            <MenuItem
                              key={y[this.variableCols.projectedQuarter]}
                              value={y[this.variableCols.projectedQuarter]}
                            >
                              {y[this.variableCols.projectedQuarter]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>

            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    data-test="rep"
                    id="combo-box-demo"
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                        label="Rep:"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      data-test="team"
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) => y.type === "team" && y.Display_Name !== ""
                          )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} sm={12} style={{}}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.Acct_Vertical
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : this.props.company === "C0008"
                        ? "Product Line"
                        : "Solution Category:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Acct_Vertical]}
                      onChange={this.handleChange}
                      name={this.variableCols.Acct_Vertical}
                      data-test={this.variableCols.Acct_Vertical}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Acct_Vertical &&
                              y[this.variableCols.Acct_Vertical] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Acct_Vertical] <
                            b[this.variableCols.Acct_Vertical]
                              ? -1
                              : a[this.variableCols.Acct_Vertical] >
                                b[this.variableCols.Acct_Vertical]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Acct_Vertical]}
                              value={y[this.variableCols.Acct_Vertical]}
                            >
                              {
                                y[
                                  this.props.company === "C0008"
                                    ? "label"
                                    : this.variableCols.Acct_Vertical
                                ]
                              }
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={6}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} sm={4} style={{}}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Opportunity Type:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Type"
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerType &&
                              y[this.variableCols.CustomerType] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} style={{}}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[this.variableCols.Industry].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003" ? "Region:" : "Industry:"}
                    </InputLabel>
                    <Select
                      data-test="Industry"
                      value={this.state[this.variableCols.Industry]}
                      onChange={this.handleChange}
                      name={this.variableCols.Industry}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Industry &&
                              y[this.variableCols.Industry] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Industry] <
                            b[this.variableCols.Industry]
                              ? -1
                              : a[this.variableCols.Industry] >
                                b[this.variableCols.Industry]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Industry]}
                              value={y[this.variableCols.Industry]}
                            >
                              {y[this.variableCols.Industry]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{}}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      {this.props.company === "C0003"
                        ? "Segment:"
                        : "Customer Segment:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Segment"
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerSegment &&
                              y[this.variableCols.CustomerSegment] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerSegment]}
                              value={y[this.variableCols.CustomerSegment]}
                            >
                              {y[this.variableCols.CustomerSegment]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={4}>
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((f) => f.type === "acv")
                        .map((d) => d3.format(",")(String(d.acv)))
                    }
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    autoSelect={true}
                    // defaultValue={0}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "5px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== "" && this.state.acv !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustomv2,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((f) => f.type === "acv")
                        .map((d) => d3.format(",")(String(d.acv)))
                    }
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "5px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== "" &&
                              this.state.acvlt !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Adding ACV Range Filter */}
            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.ACV_Range
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.Value_Label} Range:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.ACV_Range]}
                      onChange={this.handleChange}
                      name={this.variableCols.ACV_Range}
                      data-test={this.variableCols.ACV_Range}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {console.log(
                        this.props.funnelCompareFilters
                          .filter((y) => y.type === this.variableCols.ACV_Range)
                          .map((y) => y[this.variableCols.ACV_Range])
                      )}
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.ACV_Range &&
                              y[this.variableCols.ACV_Range] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.ACV_Range]}
                              value={y[this.variableCols.ACV_Range]}
                            >
                              {y[this.variableCols.ACV_Range]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {this.props.company !== "C0003" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{}}
                >
                  <Grid item xs={12} sm={12} style={{}}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Lead_Source
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Lead Source:
                      </InputLabel>
                      <Select
                        data-test="Lead_Source"
                        value={this.state[this.variableCols.Lead_Source]}
                        onChange={this.handleChange}
                        name={this.variableCols.Lead_Source}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Lead_Source &&
                                y[this.variableCols.Lead_Source] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Lead_Source]}
                                value={y[this.variableCols.Lead_Source]}
                              >
                                {y[this.variableCols.Lead_Source]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*Deal Category filter */}
            {this.props.company === "C0001" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{}}
                >
                  <Grid item xs={12} sm={12} style={{}}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.deal_category__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.deal_category__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.deal_category__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.deal_category__c &&
                                y[this.variableCols.deal_category__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.deal_category__c]}
                                value={y[this.variableCols.deal_category__c]}
                              >
                                {y[this.variableCols.deal_category__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company === "C0008" && (
              <>
                {" "}
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={12} style={{}}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.cross_sell1__c
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Sales Type:
                        </InputLabel>
                        <Select
                          data-test="cross_sell1__c"
                          value={this.state[this.variableCols.cross_sell1__c]}
                          onChange={this.handleChange}
                          name={this.variableCols.cross_sell1__c}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.cross_sell1__c &&
                                  y[this.variableCols.cross_sell1__c] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.cross_sell1__c]}
                                  value={y[this.variableCols.cross_sell1__c]}
                                >
                                  {y[this.variableCols.cross_sell1__c]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={12} style={{}}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[this.variableCols.Type].includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Deal Type:
                        </InputLabel>
                        <Select
                          data-test="Type"
                          value={this.state[this.variableCols.Type]}
                          onChange={this.handleChange}
                          name={this.variableCols.Type}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.Type &&
                                  y[this.variableCols.Type] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.Type]}
                                  value={y[this.variableCols.Type]}
                                >
                                  {y[this.variableCols.Type]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/*Opp. Type filter */}
            {this.props.company !== "C0008" &&
              this.props.company !== "C0003" &&
              this.props.funnelCompareFilters.filter((it) => it.oppType) && (
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={12} style={{}}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols[this.oppTypeName]
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Opp. Type:
                        </InputLabel>
                        <Select
                          data-test="Product_Category"
                          value={
                            this.state[this.variableCols[this.oppTypeName]]
                          }
                          onChange={this.handleChange}
                          name={this.variableCols[this.oppTypeName]}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type ===
                                    this.variableCols[this.oppTypeName] &&
                                  y[this.variableCols[this.oppTypeName]] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols[this.oppTypeName]]}
                                  value={y[this.variableCols[this.oppTypeName]]}
                                >
                                  {y[this.variableCols[this.oppTypeName]]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}

            {this.props.company === "C0003" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{}}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 7 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Product_Line
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Product Cat.:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.Product_Line]}
                        onChange={this.handleChange}
                        name={this.variableCols.Product_Line}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Product_Line &&
                                y[this.variableCols.Product_Line] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Product_Line]}
                                value={y[this.variableCols.Product_Line]}
                              >
                                {y["label"]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0013" && (
              <>
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.Partner_Opp
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Partner Opp:
                        </InputLabel>
                        <Select
                          data-test="Product_Category"
                          value={this.state[this.variableCols.Partner_Opp]}
                          onChange={this.handleChange}
                          name={this.variableCols.Partner_Opp}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.Partner_Opp &&
                                  y[this.variableCols.Partner_Opp] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.Partner_Opp]}
                                  value={y[this.variableCols.Partner_Opp]}
                                >
                                  {y[this.variableCols.Partner_Opp]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.Opportunity_Record_Type
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Opportunity Record Type:
                        </InputLabel>
                        <Select
                          data-test="Product_Category"
                          value={
                            this.state[
                              this.variableCols.Opportunity_Record_Type
                            ]
                          }
                          onChange={this.handleChange}
                          name={this.variableCols.Opportunity_Record_Type}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type ===
                                    this.variableCols.Opportunity_Record_Type &&
                                  y[
                                    this.variableCols.Opportunity_Record_Type
                                  ] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={
                                    y[this.variableCols.Opportunity_Record_Type]
                                  }
                                  value={
                                    y[this.variableCols.Opportunity_Record_Type]
                                  }
                                >
                                  {y[this.variableCols.Opportunity_Record_Type]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} sm={12} style={{}}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color:
                          this.state.include_outliers !== "quarter"
                            ? "#4472c4"
                            : "#000",
                      }}
                      id="select-region-label"
                    >{`Group By:`}</InputLabel>
                    <Select
                      data-test="group_by"
                      value={this.state.group_by}
                      onChange={this.handleChange}
                      name="group_by"
                      // disabled={(this.state.year.includes('All') && this.state.month.includes('All') && this.state.cfq.includes('All')) || (this.state.year.includes('All') && !this.state.month.includes('All')) || (!this.state.year.includes('All') && this.state.month.includes('All')) }
                    >
                      <MenuItem value={"group_by_quarter"}>Quarter</MenuItem>
                      <MenuItem value={"group_by_month"}>Month</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        {this.props.funnelCompareFilters ? (
          <Grid
            item
            xs={12}
            md={12}
            id="grid-container"
            style={{ marginTop: "1rem" }}
          >
            <Paper style={{ marginBottom: "-7px" }}>
              <ToggleButtonGroup
                color="primary"
                indicatorColor="primary"
                value={this.state.tab}
                exclusive
                size="large"
                onChange={this.handleChangeTab}
              >
                <ToggleButton
                  style={{
                    background: this.state.tab === "Summary" ? "#4472C4" : "",
                    color: this.state.tab === "Summary" ? "white" : "",
                    fontSize: "1rem",
                    fontWeight: 600,
                    height: "3.5rem",
                    width: "8rem",
                  }}
                  value="Summary"
                  id="navlink_Summary_Tab"
                >
                  Summary
                </ToggleButton>
                <ToggleButton
                  style={{
                    background: this.state.tab === "Team_Rep" ? "#4472C4" : "",
                    color: this.state.tab === "Team_Rep" ? "white" : "",
                    fontSize: "1rem",
                    fontWeight: 600,
                    height: "3.5rem",
                    width: "8rem",
                  }}
                  value="Team_Rep"
                  id="navlink_Team_Rep_Tab"
                >
                  Team / Rep
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>
          </Grid>
        ) : (
          <Card style={{ margin: "auto", width: "100%" }} raised={true}>
            <Grid item container justify="center">
              <Skeleton
                variant="react"
                animation="wave"
                height={20}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          </Card>
        )}

        {this.state.tab === "Summary" ? (
          <Grid className={classes.grid} item xs={12}>
            <Grid>
              {this.props.pipelineMixAnalysisData !== "" ? (
                <Grid container>
                  {this.props.pipelineMixAnalysisData.map(
                    ({ type, data, label }, i) => {
                      const updatedData =
                        this.state.isMonth === true &&
                        data.filter((f) => f.month).length > 0
                          ? data.map((item) => ({
                              ...item,
                              month: item.month,
                            }))
                          : data;
                      return (
                        type !== "Owner_Name" && (
                          <Grid
                            container
                            key={i}
                            className={classes.grid}
                            item
                            xs={12}
                          >
                            <DataVisualizer
                              chartName={`Pipeline Created Mix by ${type}`}
                              title={`Pipeline Created mix by ${replaceAll(
                                label,
                                "acv",
                                `${this.props.Value_Label}`
                              )}`}
                              type={type}
                              chartType="acv"
                              winACVMixAnalysisData={
                                ["Apps_Cluster", "Product_Line"].includes(type)
                                  ? updatedData.filter(
                                      (i) => i[type] !== "Total"
                                    )
                                  : updatedData
                              }
                              handleChangeLocation={this.handleChangeLocation}
                              quarter={this.state.selectCol}
                              Value_Label={this.props.Value_Label}
                              tableComponent={
                                type === "Acct_Segment" ? (
                                  <DynamicTableSegment
                                    type={type}
                                    quarter={this.state.selectCol}
                                    winAcvData={updatedData}
                                    handleChangeLocation={
                                      this.handleChangeLocation
                                    }
                                    Value_Label={this.props.Value_Label}
                                    isMonth={this.state.isMonth}
                                  />
                                ) : type === "Owner_Name" ? (
                                  <DynamictableV2
                                    data={updatedData}
                                    quarter={this.state.selectCol}
                                    stage={type}
                                    Value_Label={this.props.Value_Label}
                                  />
                                ) : undefined
                              }
                              unit="acv"
                              isMonth={this.state.isMonth}
                              clusterTotal={
                                ["Apps_Cluster", "Product_Line"].includes(type)
                                  ? updatedData.filter(
                                      (i) => i[type] === "Total"
                                    )
                                  : []
                              }
                              sortByTotal={
                                type !== "Acct_Segment" ? true : false
                              }
                              label={label}
                              toggleValue={this.state.toggleValue}
                              handleChangeToggle={this.handleChangeToggle}
                              sortedStages={
                                this.props.pipelineMixAnalysisData.filter(
                                  (f) => f.type === "Stage"
                                )[0].sortedStages
                              }
                            />
                          </Grid>
                        )
                      );
                    }
                  )}
                </Grid>
              ) : (
                <Card>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width="95%"
                    height={230}
                    style={{ margin: "2rem" }}
                  />
                </Card>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.grid} item xs={12}>
            <Grid>
              {this.props.pipelineMixAnalysisData !== "" ? (
                <Grid container>
                  {this.props.pipelineMixAnalysisData.map(
                    ({ type, data, label }, i) => {
                      const updatedData =
                        this.state.isMonth === true &&
                        data.filter((f) => f.month).length > 0
                          ? data.map((item) => ({
                              ...item,
                              month: item.month,
                            }))
                          : data;
                      return (
                        type === "Owner_Name" && (
                          <Grid key={i} className={classes.grid} item xs={12}>
                            <Grid container component={Card}>
                              <Grid item style={{ padding: "1rem" }} xs={12}>
                                <Typography
                                  align="center"
                                  variant="h2"
                                  style={{ margin: "1rem" }}
                                >{`Pipeline Created mix by ${label}`}</Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  margin: "2rem",
                                  marginTop: "0rem",
                                  paddingRight: "5rem",
                                }}
                                justify="flex-start"
                                container
                              >
                                <DynamictableV2
                                  data={updatedData}
                                  quarter={this.state.selectCol}
                                  stage={type}
                                  handleChangeLocation={
                                    this.handleChangeLocation
                                  }
                                  isMonth={this.state.isMonth}
                                  Value_Label={this.props.Value_Label}
                                  dataID="Pipeline_Created_By_Team_Rep_table"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      );
                    }
                  )}
                </Grid>
              ) : (
                <Card>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width="95%"
                    height={230}
                    style={{ margin: "2rem" }}
                  />
                </Card>
              )}
            </Grid>
          </Grid>
        )}
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunities in Pipeline Created Mix"}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={"Opportunities in Pipeline Created Mix"}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                Value_Label={this.props.Value_Label}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString={false}
      // prefix="$"
      allowNegative={false}
    />
  );
}

function NumberFormatCustomv2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        (values.value > 0 || [null, "", undefined].includes(values.value)) &&
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
      }}
      thousandSeparator
      isNumericString={false}
      // prefix="$"
      allowNegative={false}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    pipelineCreatedMixFilters,
    pipelineCreatedMixAnalysisData,
    user,
    lkpFilters,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelCompareFilters: pipelineCreatedMixFilters,
    pipelineMixAnalysisData: pipelineCreatedMixAnalysisData,
    company: user.company,
    Value_Label: user.Value_Label,
    drilldownOnGraphData,
    currentFilters,
    lkpFilters,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },
  getPipelineMixDrilldown: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getPipelineCreatedMixAnalysisDrilldown(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              //closed_date: item.closed_date !== undefined ? item.closed_date.split('T')[0] : item.projected_close_date.split('T')[0],
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getPbiSummaryDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getPbiSummaryDrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.result.recordsets[1];
        tempOpportunities.sort((a, b) => (a.acv > b.acv ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.result.recordsets[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },

  getfunnelCompareFilters: (company) => {
    dispatch({ type: "get_pipeline_created_mix_filters_request" });
    appService.getPipelineCreatedMixAnalysisFilters().then(
      (json) => {
        var lkpFilters = Object.values(
          json.message.lkpFilters.reduce((r, c) => Object.assign(r, c), {})
        );
        json.message = json.message.computedFilters;
        json.lkpFilters = lkpFilters;
        dispatch({ type: "get_pipeline_created_mix_filters_success", json });
        dispatch({ type: "get_pipeline_created_mix_analysis_data_request" });
        //default stage filter
        const stageLength = json.message.filter(
          (f) => f.type === "milestone_stage"
        ).length;
        const stageSequence = getDefaultSelectedStage(
          json.message.filter((f) => f.type === "milestone_stage")
        );
        const defaultStages = json.message
          .filter((item) => item.sequence === stageSequence)
          .map((i) => i.milestone_stage)
          .filter(Boolean);
        let filters = [
          {
            name: "Open_Date_Initial_Fiscal_Quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "Open_Date_Initial_Fiscal_Quarter"
            ),
            string: true,
          },
          { name: "milestone_stage", string: true, value: defaultStages },
        ];
        if (stageLength === 1) {
          filters = [
            {
              name: "Qualified_Date_Initial_Fiscal_Quarter",
              value: getDefaultSelectedFiscalQuarters(
                json.message,
                "Qualified_Date_Initial_Fiscal_Quarter"
              ),
              string: true,
            },
            { name: "milestone_stage", string: true, value: defaultStages },
          ];
        }
        const defaultACV = getDefaultACV(
          json.message.filter((f) => f.type === "acv")
        );
        if (defaultACV !== "") {
          filters = [
            ...filters,
            { name: "acv", value: defaultACV, string: false },
          ];
        }
        if (json.message.filter((s) => s.oppType).length > 0) {
          let oppTypeColumn = json.message.filter((i) => i.oppType)[0].oppType;
          filters =
            getDefaultSalesType(
              json.message.filter((i) => i.oppType),
              oppTypeColumn
            )[0] === "All"
              ? [...filters]
              : [
                  ...filters,
                  {
                    name: oppTypeColumn,
                    value: getDefaultSalesType(
                      json.message.filter((i) => i.oppType),
                      oppTypeColumn
                    ),
                  },
                ];
        }
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }
        dispatch({ type: "current_selected_filters", filters });
        appService
          .getPipelineCreatedMixAnalysisData(
            json.message.length === 0 ? [] : filters
          )
          .then(
            (json) => {
              dispatch({
                type: "get_pipeline_created_mix_analysis_data_success",
                json,
              });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_pipeline_created_mix_analysis_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_pipeline_created_mix_analysis_data_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pipeline_created_mix_filters_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_pipeline_created_mix_filters_failure", error });
      }
    );
  },
  getPipelineMixAnalysisData: (company, filters = []) => {
    dispatch({ type: "get_pipeline_created_mix_analysis_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getPipelineCreatedMixAnalysisData(filters).then(
      (json) => {
        dispatch({
          type: "get_pipeline_created_mix_analysis_data_success",
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pipeline_created_mix_analysis_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_pipeline_created_mix_analysis_data_failure",
            error,
          });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedPipelineCreatedMixAnalysis = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PipelineCreatedMixAnalysis));
export default connectedPipelineCreatedMixAnalysis;
