import {
    Button,
    Card,
    Grid,
    Paper,
    Typography,
    withStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
  } from "@material-ui/core";
  import Skeleton from "@material-ui/lab/Skeleton";
  import React, { Component } from "react";
  import AttainmentSection from "./AttainmentSection";
  import NQPSection from "./NQPSection";
  import PipelineCoverageSection from "./PipelineCoverageSection";
  import { connect } from "react-redux";
  import { appService } from "../../App/app.service";
  import DescriptionTable from "../ScoreCard/DescriptionTable";
  import CustomOrgenericComponentFinder from "./CustomOrGenericComponentFinder";
  import Attainment from "./Attainment";
  import MQOSection from "./MQOSection";
  import ACVCreatedSection from "./ACVCreatedSection";
  import AllAesPerformanceTable from "../ScoreCard/components/AllAesPerformance/AllAesPerformanceTable";
  import RepBenchmarking from "../FunnelCompare/RepBenchmarkScorecard";
  import ErrorBoundary from "../../components/ErrorBoundary";
  import addHeaderdescription from "../../components/HeaderDescription";
  import { ToggleButton, ToggleButtonGroup } from "@mui/material";
  import BalancedScorecardSection from "./BalancedScorecard/BalancedScoreCard";
  
  const styles = (theme) => ({
    gridItem: {
      padding: "1rem",
    },
  });
  
  
  class BalancedScoreCard extends Component {
    filters = ["team", "rep"];
    state = {
      team:
        this.props.genericScorecardFilters !== ""
          ? [this.props.genericScorecardFilters.defaultFilters.Team]
          : ["All"],
      rep: ["All"],
      teams: [],
      reps: [],
      filters: [],
      selectedTeam:
        this.props.genericScorecardFilters !== ""
          ? [this.props.genericScorecardFilters.defaultFilters.Team]
          : ["All"],
      selectedRep: ["All"],
      attaianmentGenericOrCustom: "",
    //   tab: "Summary",
      // tab: 'Balanced'
    };
  
    componentDidMount() {
      this.props.getScoreCardFilters();
      this.props.getSettings();
    }
  
    componentDidUpdate(prevProps, prevState) {
      if (
        this.props.genericScorecardFilters !== "" &&
        prevProps.genericScorecardFilters === ""
      ) {
        const { teams, reps, attaianmentGenericOrCustom, defaultFilters } =
          this.props.genericScorecardFilters;
          let f = []
          if(defaultFilters.Team !== 'All'){
           f= [{ name: "team", value: [defaultFilters.Team] }]
          }
  
          if(defaultFilters.Rep !== 'All'){
            f= [{ name: "rep", value: [defaultFilters.Rep] }]
          }
  
          const params = localStorage.getItem("params");
          
          let t = 'Summary'
          // let t = 'Balanced'
          if(![null, undefined, ''].includes(params)){
            t = params
          }
  
          // console.log(t,typeof params, 'TABBBB')
  
        this.setState({
          ...this.state,
          teams,
          reps,
          attaianmentGenericOrCustom,
          team: [defaultFilters.Team],
          selectedTeam: [defaultFilters.Team],
          rep: [defaultFilters.Rep],
          selectedRep: [defaultFilters.Rep],
          filters: f,
          tab: t
        });
      }
    }
  
    handleChangeTab = (e) => {
      this.handleGo();
      console.log(e.target.value);
      this.setState({
        ...this.state,
        tab: e.target.value,
      });
    };
  
    handleChange = (e) => {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    };
  
    handleGo = () => {
      let filters = this.filters
        .map((f) => {
          return {
            name: f,
            value: this.state[f],
          };
        })
        .filter((f) => !Array.isArray(f.value) || !f.value.includes("All"));
      console.log("go", filters);
      this.setState({
        ...this.state,
        filters,
        selectedTeam: this.state.team,
        selectedRep: this.state.rep,
      });
    };
  
    render() {
      const classes = styles();
  
      return (
        <Grid container>
          {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
            <Typography variant="h2" align="center">
              Performance Scorecard
            </Typography>
            <Typography style={{ marginLeft: "2rem" }} variant="body1">
              Get a comprehensive view of lagging, leading and emerging
              performance indicators.
            </Typography>
          </Grid> */}
          {addHeaderdescription(
            "Performance Scorecard",
            "Get a comprehensive view of lagging, leading and emerging performance indicators."
          )}
  
          {this.props.genericScorecardFilters !== "" ? (
            <Grid container item xs={12}>
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={9}
                lg={this.props.filtersTobeDisplayed === "rep" ? 4 : 2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid
                    item
                    xs={12}
                    sm={this.props.filtersTobeDisplayed === "rep" ? 6 : 12}
                    style={{ paddingTop: 0 }}
                  >
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      id="filter_team"
                      disabled={!this.state.rep.includes("All")}
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        id="select-team-label"
                      >
                        Team:
                      </InputLabel>
                      <Select
                        data-test="team"
                        id="performance_scorecard_team"
                        value={this.state.team}
                        onChange={this.handleChange}
                        name="team"
                        multiple
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          PaperProps: {
                            id: "filter_team_values", // Add id to the generated div element
                          },
                        }}
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.genericScorecardFilters !== "" &&
                          this.props.genericScorecardFilters.teams
                            .sort()
                            .map((y) => (
                              <MenuItem
                                id="performance_scorecard_team_values"
                                key={y}
                                value={y}
                              >
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {this.props.filtersTobeDisplayed === "rep" && (
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        disabled={!this.state.team.includes("All")}
                        id="filter_rep"
                      >
                        <InputLabel
                          className={classes.filterLabel}
                          id="select-team-label"
                        >
                          Rep:
                        </InputLabel>
                        <Select
                          id="performance_scorecard_rep"
                          data-test="rep"
                          value={this.state.rep}
                          onChange={this.handleChange}
                          name="rep"
                          multiple
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            PaperProps: {
                              id: "filter_rep_values", // Add id to the generated div element
                            },
                          }}
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.genericScorecardFilters !== "" &&
                            this.props.genericScorecardFilters.reps
                              .sort()
                              .map((y) => (
                                <MenuItem
                                  id="performance_scorecard_rep_values"
                                  key={y}
                                  value={y}
                                >
                                  {y}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
  
              <Grid
                item
                sm={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                  id="button_go"
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              container
              component={Card}
              style={{ margin: "1rem" }}
              justify="center"
            >
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          )}
          <Grid item xs={12} style={{ marginLeft: "1rem" }}>
            <ErrorBoundary>
              {" "}
              <DescriptionTable
                settings={this.props.settings.lastPull}
                selectedTeam={
                  this.state.selectedTeam.includes("All") &&
                  this.state.selectedRep.includes("All")
                    ? "All"
                    : !this.state.selectedTeam.includes("All")
                    ? this.state.selectedTeam.length > 3
                      ? this.state.selectedTeam.slice(0, 3).join(", ") + "..."
                      : this.state.selectedTeam.join(", ")
                    : this.state.selectedRep.length > 3
                    ? this.state.selectedRep.slice(0, 3).join(", ") + "..."
                    : this.state.selectedRep.join(", ")
                }
                teamOrRep={
                  this.state.selectedTeam.includes("All") &&
                  this.state.selectedRep.includes("All")
                    ? "Team"
                    : !this.state.selectedTeam.includes("All")
                    ? this.state.selectedTeam.length > 1
                      ? "Teams"
                      : "Team"
                    : this.state.selectedRep.length > 1
                    ? "Reps"
                    : "Rep"
                }
                qtdQuarter={this.props.currentQuarter}
                nextQuarter={this.props.nextQuarter}
                isScoreCardDataLoaded={this.props.genericScorecardFilters !== ""}
                msDesc={true}
              />
            </ErrorBoundary>
          </Grid>
  
  
          {this.props.genericScorecardFilters !== "" &&
             (
              <Grid
                // style={{ marginTop: "1rem" }}
                item
                xs={12}
              >
                <Grid item xs={12} id="grid-container">
                  <ErrorBoundary>
                    {" "}
                    <BalancedScorecardSection
                      filters={this.state.filters}
                      selectedTeam={this.state.selectedTeam}
                      selectedRep={this.state.selectedRep}
                      title={"Balanced Scorecard"}
                    />
                  </ErrorBoundary>
                </Grid>
              </Grid>
            )}
          <Grid />
        </Grid>
      );
    }
  }
  function mapStateToProps(state) {
    const { genericScorecardFilters, user, AttainmentDataYTD, settings } =
      state.app;
    const {
      asof,
      currentQuarter,
      nextQuarter,
      nextQuarter2,
      nextQuarter3,
      currentDate,
      db,
      attaianmentGenericOrCustom,
      SQlCreatedTableTypes,
      filtersTobeDisplayed,
      splitup,
      lowConfidenceKey,
      currentandPriorTwoQuarters,
      pipelineCoverageDeals20KFlag,
      showAllAEsTable,
      defaultFilters,
    } = genericScorecardFilters;
    return {
      genericScorecardFilters,
      user,
      asof,
      settings,
      currentQuarter,
      nextQuarter,
      nextQuarter2,
      nextQuarter3,
      currentDate,
      db,
      attaianmentGenericOrCustom,
      SQlCreatedTableTypes,
      filtersTobeDisplayed,
      splitup,
      lowConfidenceKey,
      currentandPriorTwoQuarters,
      pipelineCoverageDeals20KFlag,
      showAllAEsTable,
      defaultFilters,
      AttainmentDataYTD,
      company: user.company,
    };
  }
  
  const mapDispatchToProps = (dispatch) => ({
    getScoreCardFilters: () => {
      dispatch({ type: "get_Generic_ScoreCard_filters_request" });
      appService.getGenericScoreCardFilters().then(
        (json) => {
          dispatch({ type: "get_Generic_ScoreCard_filters_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_ScoreCard_filters_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_ScoreCard_filters_failure", error });
        }
      );
    },
    getSettings: () => {
      dispatch({ type: "get_settings_request" });
      appService.getSettings().then(
        (json) => {
          dispatch({ type: "get_settings_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_settings_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_settings_failure", error });
        }
      );
    },
  });
  
  const GenericBalancedScoreCard = connect(
    mapStateToProps,
    mapDispatchToProps
  )(BalancedScoreCard);
  export default GenericBalancedScoreCard;