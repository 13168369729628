import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { sort } from "d3-array";
import React, { Component } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  customDateFormatter,
  customDateFormatterNew,
  getDefaultSelectedStage,
  getUniqueValues,
} from "../../util/customFunctions";
import DrilldownBody from "./DrilldownBody";
import NQPDrilldown from "./NQPDrilldown";
import DatePicker from "../../components/DatePicker_v2";

import QualifiedPipelineTable from "./QualifiedPipelineTable";
import { weekEndingMapping } from "./utils";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import { styled } from "@mui/material/styles";
import Drilldown from "../GenericScorecard/Drilldown";
import addHeaderdescription from "../../components/HeaderDescription";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  tableClickable: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
    cursor: "pointer",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});
class NewQualifiedPipelinev2 extends Component {
  state = {
    last: 60,
    activelast: 60,
    timeFrame: "window",
    from: "",
    to: "",
    fromDisplay: "",
    toDisplay: "",
    activeFrom: "",
    activeTo: "",
    minDateForTo: "",
    maxDateForTo: "",
    minDateForFrom: "",
    maxDateForFrom: "",
    errorTextFrom: "",
    errorTextTo: "",
    location: null,
    secondLevelLocation: null,
    toggleValue: "CurrentAcv",
    milestone_stage: "",
    stageFilter: [],
    drilldownStage: "",
  };
  componentDidMount = () => {
    this.props.getfunnelCompareFilters(this.props.company);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const fromDateComponents =
        this.props.funnelCompareFilters.dateFilters.prior2Month.split("-");
      const toDateComponents =
        this.props.funnelCompareFilters.dateFilters.currentDate.split("-");
      const fromDateObj = new Date(
        parseInt(fromDateComponents[0]),
        parseInt(fromDateComponents[1]) - 1,
        parseInt(fromDateComponents[2])
      );
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      const minDateSplit =
        this.props.funnelCompareFilters.minMax.minDate.split("-");
      const minDate = new Date(
        parseInt(minDateSplit[0]),
        parseInt(minDateSplit[1]) - 1,
        parseInt(minDateSplit[2])
      );
      const maxDateSplit =
        this.props.funnelCompareFilters.minMax.maxDate.split("-");
      const maxDate = new Date(
        parseInt(maxDateSplit[0]),
        parseInt(maxDateSplit[1]) - 1,
        parseInt(maxDateSplit[2])
      );
      const stageSequence = getDefaultSelectedStage(
        this.props.funnelCompareFilters.mileStoneStages
      );
      let stage_names = [];
      const stageLength =
        this.props.funnelCompareFilters.mileStoneStages.length;
      let col =
        stageLength > 1 ? "Open_Fiscal_Quarter" : "Qualified_Fiscal_Quarter";
      this.props.funnelCompareFilters.mileStoneStages.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence == stageSequence) stage_names = i.stage_name;
        }
      });

      this.setState({
        ...this.state,
        minDateForTo: minDate,
        maxDateForTo: maxDate,
        minDateForFrom: minDate,
        maxDateForFrom: maxDate,
        from: fromDate,
        to: toDate,
        fromDisplay: customDateFormatterNew(fromDate),
        toDisplay: customDateFormatterNew(toDate),
        activeFrom: fromDate,
        activeTo: toDate,
        milestone_stage: stageSequence,
        drilldownStage: stageSequence,
      });
    }
  }

  drilldown = (rep, weekEnding, avgDrilldownFlag, metaData) => {
    console.log(rep, weekEnding);

    var filters = [],
      filtersForDrilldown = [],
      heading = "";
    if (["Twelve", "Eight", "Four"].includes(weekEnding)) {
      filters = [
        {
          name: "Owner_Name",
          value: rep,
          string: true,
        },
        {
          name: this.props.NewQualifiedPipelineDatav2.timeFrameEnding,
          value:
            this.props.NewQualifiedPipelineDatav2.trailingTwelveWeeks.slice(
              0,
              weekEndingMapping[weekEnding]
            ),
          string: true,
        },
      ];
      filtersForDrilldown = [
        {
          title: "Time Frame",
          value:
            weekEnding === "Twelve"
              ? this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                "Half_Month_Ending"
                ? "Trailing 6 months"
                : "Trailing 12 weeks"
              : weekEnding === "Eight"
              ? this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                "Half_Month_Ending"
                ? "Trailing 4 months"
                : "Trailing 8 weeks"
              : this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                "Half_Month_Ending"
              ? "Trailing 2 months"
              : "Trailing 4 weeks",
        },
        {
          title: rep.length > 1 ? "Team" : "Rep",
          value:
            rep.length > 1
              ? this.props.NewQualifiedPipelineDatav2.summary
                  .filter((item) => rep.includes(item.Owner_Name))
                  .map((i) => i.team)
                  .filter(getUniqueValues)
              : rep,
        },
      ];
      heading = "New Qualified Pipeline Trends";
    } else {
      filters = [
        {
          name: "Owner_Name",
          value: rep,
          string: true,
        },
        {
          name: this.props.NewQualifiedPipelineDatav2.timeFrameEnding,
          value: weekEnding,
          string: true,
        },
      ];
      const timeEnded =
        this.props.NewQualifiedPipelineDatav2.timeFrameEnding === "Week_Ending"
          ? "Week"
          : "Half-Month";
      filtersForDrilldown = [
        {
          title: "Time Frame",
          value: `${timeEnded} ended ${customDateFormatter(weekEnding)}`,
        },
        {
          title: rep.length > 1 ? "Team" : "Rep",
          value:
            rep.length > 1
              ? this.props.NewQualifiedPipelineDatav2.summary
                  .filter((item) => rep.includes(item.Owner_Name))
                  .map((i) => i.team)
                  .filter(getUniqueValues)
              : rep,
        },
      ];
      heading = `New ${this.props.NewQualifiedPipelineDatav2.tableHeader} Pipeline`;
    }

    let stage_names = [];
    this.props.funnelCompareFilters.mileStoneStages.map((i) => {
      if (i.milestone_stage !== undefined) {
        if (i.sequence === this.state.drilldownStage)
          stage_names = [i.milestone_stage];
      }
    });
    filters = [
      ...filters,
      {
        name: "milestone_stage",
        value: stage_names,
        string: true,
      },
    ];
    this.props.getNewQualifiedPipelineDrilldownData(filters);

    this.setState({
      ...this.state,
      isMetaData: avgDrilldownFlag,
      metaData,
      drilldownLabelString:
        this.props.NewQualifiedPipelineDatav2.timeFrameEnding === "Week_Ending"
          ? "week"
          : "half-month",
      heading,
      location: "drilldown",
      filtersForDrilldown: filtersForDrilldown,
    });
  };

  handleChangeToggle = (e) => {
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
    //this.props.setTable('')
  };
  handleChangeLocation = () => {
    this.setState({ ...this.state, location: "Home", filtersForDrilldown: [] });
    // this.props.clearDrilldownData()
  };

  handleChange = (e) => {
    if (e.target.value !== 1) {
      const projectedClosedDateMapping = {
        30: this.props.funnelCompareFilters.dateFilters.prior1Month.split("-"),
        60: this.props.funnelCompareFilters.dateFilters.prior2Month.split("-"),
      };
      const fromDateObj = new Date(
        parseInt(projectedClosedDateMapping[e.target.value][0]),
        parseInt(projectedClosedDateMapping[e.target.value][1]) - 1,
        parseInt(projectedClosedDateMapping[e.target.value][2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDateComponents =
        this.props.funnelCompareFilters.dateFilters.currentDate.split("-");
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        to: toDate,
        from: fromDate,
        errorTextFrom: "",
        errorTextTo: "",
      });
    } else {
      this.setState({ ...this.statem, [e.target.name]: e.target.value });
    }
  };

  setFromDate = (date) => {
    const split = this.state.to.split("-");
    const toDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) > toDate) {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "From date cannot be later than the To date",
      });
    } else {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "",
        errorTextTo: "",
      });
    }
  };

  setToDate = (date) => {
    const split = this.state.from.split("-");
    const fromDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) < fromDate) {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "To date cannot be earlier than the From date",
      });
    } else {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextTo: "",
        errorTextFrom: "",
      });
    }
  };

  handleGo = () => {
    var filters = [];

    let stage_names = [];
    this.props.funnelCompareFilters.mileStoneStages.map((i) => {
      if (i.milestone_stage !== undefined) {
        if (i.sequence === this.state.milestone_stage)
          stage_names = [i.milestone_stage];
      }
    });
    filters = [
      ...filters,
      {
        name: "milestone_stage",
        value: stage_names,
        string: true,
      },
    ];

    if (this.state.from !== "" && this.state.to !== "") {
      const from = this.state.from;
      const fromDate = `${from.split("-")[2]}-${from.split("-")[0]}-${
        from.split("-")[1]
      }`;
      const to = this.state.to;
      const toDate = `${to.split("-")[2]}-${to.split("-")[0]}-${
        to.split("-")[1]
      }`;
      const timeFrames = `${fromDate}_to_${toDate}`;
      filters = [
        ...filters,
        {
          name: "timeFrames",
          value: timeFrames,
        },
      ];
    }

    this.setState({
      ...this.state,
      drilldownStage: this.state.milestone_stage,
    });
    this.props.getNewQualifiedPipelineDatav2(filters);
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      // location: location,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant='h2' align='center'>New Pipeline Creation and Cadence</Typography>
        </Grid>
        <Typography variant='body1'>
        Examine the cadence and consistency of new pipeline creation by milestone stage.
        </Typography> */}
        {addHeaderdescription(
          "New Pipeline Creation and Cadence",
          "Examine the cadence and consistency of new pipeline creation by milestone stage."
        )}

        {this.props.funnelCompareFilters !== "" ? (
          <Grid item container xs={12}>
            <Grid container item style={{ padding: 10 }} xs={2} md={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-stage-label"
                      style={{ color: "#4472c4", width: 144 }}
                    >
                      Milestone Stage:
                    </InputLabel>
                    <Select
                      labelId="select-stage-label"
                      id="select-stage"
                      value={this.state.milestone_stage}
                      onChange={this.handleChangeEarliestOpenStage}
                      label="Milestone Stage:"
                      name="milestone_stage"
                      data-test="milestine_stage"
                    >
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.mileStoneStages.map(
                          (y) =>
                            y.milestone_stage && (
                              <MenuItem key={y.sequence} value={y.sequence}>
                                {y.milestone_stage}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item style={{ padding: 10 }} xs={4} md={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={4} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-last-label"
                      style={{ color: "#4472c4" }}
                    >
                      Time Frame - Last:
                    </InputLabel>
                    <Select
                      labelId="select-last-label"
                      id="select-last"
                      value={this.state.last}
                      onChange={this.handleChange}
                      label="Last:"
                      name="last"
                      disabled={this.state.timeFrame === "custom"}
                    >
                      {[
                        { text: "Custom", value: 1 },
                        { text: "1 month", value: 30 },
                        { text: "2 months", value: 60 },
                      ].map((y) => (
                        <MenuItem key={y.text} value={y.value}>
                          {y.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 10 }}>
                  <DatePicker
                    label="From start of:"
                    setDate={(date) => this.setFromDate(date)}
                    disabled={this.state.last !== 1}
                    min={this.state.minDateForFrom}
                    max={this.state.maxDateForFrom}
                    current={this.state.from}
                    errorText={this.state.errorTextFrom}
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 10 }}>
                  <DatePicker
                    label="To end of:"
                    setDate={(date) => this.setToDate(date)}
                    disabled={this.state.last !== 1}
                    min={this.state.minDateForTo}
                    max={this.state.maxDateForTo}
                    current={this.state.to}
                    errorText={this.state.errorTextTo}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Card style={{ margin: "auto", width: "100%" }} raised={true}>
            <Grid item container justify="center">
              <Skeleton
                variant="react"
                animation="wave"
                height={20}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          </Card>
        )}
        <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {this.props.NewQualifiedPipelineDatav2 !== "" &&
          this.funnelCompareFilters !== "" ? (
            <Grid item container xs={12} justify="flex-start">
              <Card style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{
                        width: "100%",
                        margin: "auto",
                        padding: "1rem",
                        paddingTop: "0.3rem",
                      }}
                      align="center"
                    >{`New ${this.props.NewQualifiedPipelineDatav2.tableHeader} Opportunities by rep (grouped by team)`}</Typography>
                  </Grid>
                  <Grid item xs={1} align="right">
                    <ToggleButtonGroup
                      color="primary"
                      indicatorColor="primary"
                      value={this.state.toggleValue}
                      exclusive
                      // size='small'
                      onChange={this.handleChangeToggle}
                    >
                      <CustomWidthTooltip title={`ACV`} placement="bottom">
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "Acv" ? "#4472C4" : "",
                            color:
                              this.state.toggleValue === "Acv" ? "white" : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`acv-button`}
                          value="Acv"
                        >
                          &#x27F2;$
                        </ToggleButton>
                      </CustomWidthTooltip>
                      <CustomWidthTooltip
                        title="Current ACV"
                        placement="bottom"
                      >
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "CurrentAcv"
                                ? "#4472C4"
                                : "",
                            color:
                              this.state.toggleValue === "CurrentAcv"
                                ? "white"
                                : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`currentAcv-button`}
                          value="CurrentAcv"
                        >
                          $
                        </ToggleButton>
                      </CustomWidthTooltip>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <QualifiedPipelineTable
                  thresholds={this.props.NewQualifiedPipelineDatav2.threshold}
                  drilldown={this.drilldown}
                  Value_Label={this.props.Value_Label}
                  isColDate={true}
                  name="nqp-cadennce-data-table"
                  columns={this.props.detailsColumns.sort()}
                  isAverage={false}
                  acvCol={
                    this.state.toggleValue === "CurrentAcv"
                      ? "currentACV"
                      : "Acv_at_Qualfied"
                  }
                  avgCol={
                    this.state.toggleValue === "CurrentAcv"
                      ? "currentACVAverage"
                      : "Average_Acv"
                  }
                  data={this.props.NewQualifiedPipelineDatav2.detail}
                  subColumns={
                    this.state.toggleValue === "CurrentAcv"
                      ? ["# of Opps", "Current ACV", "Avg. ACV"]
                      : [
                          "# of Opps",
                          `ACV at ${this.props.NewQualifiedPipelineDatav2.tableHeader}`,
                          "Avg. ACV",
                        ]
                  }
                  col="week"
                  tableHeading={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                    "Week_Ending"
                      ? "Week Ending"
                      : "Half-Month Ending"
                  }
                  goalheader={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                    "Week_Ending"
                      ? "Weekly"
                      : "Half-Month"
                  }
                  timeFrameEnding={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding
                  }
                  teamNames={this.props.detailsTeams}
                  dataID="New_Pipeline_Creation_And_Cadence_table"
                />
              </Card>
            </Grid>
          ) : (
            <Grid item container component={Card} justify="center">
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
          {this.props.NewQualifiedPipelineDatav2 !== "" &&
          this.funnelCompareFilters !== "" ? (
            <Grid item container xs={12} justify="flex-start">
              <Card style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12} justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="h2"
                      style={{ width: "100%", margin: "auto", padding: "1rem" }}
                      align="center"
                    >
                      {
                        this.props.NewQualifiedPipelineDatav2
                          .traillingtableHeader
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={1} align="right">
                    <ToggleButtonGroup
                      color="primary"
                      indicatorColor="primary"
                      value={this.state.toggleValue}
                      exclusive
                      // size='small'
                      onChange={this.handleChangeToggle}
                    >
                      <CustomWidthTooltip title={`ACV}`} placement="bottom">
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "Acv" ? "#4472C4" : "",
                            color:
                              this.state.toggleValue === "Acv" ? "white" : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`acv-button`}
                          value="Acv"
                        >
                          &#x27F2;$
                        </ToggleButton>
                      </CustomWidthTooltip>
                      <CustomWidthTooltip
                        title="Current ACV"
                        placement="bottom"
                      >
                        <ToggleButton
                          style={{
                            background:
                              this.state.toggleValue === "CurrentAcv"
                                ? "#4472C4"
                                : "",
                            color:
                              this.state.toggleValue === "CurrentAcv"
                                ? "white"
                                : "",
                            fontSize: "1rem",
                            fontWeight: 600,
                            width: "3rem",
                            height: "2rem",
                          }}
                          data-test={`currentAcv-button`}
                          value="CurrentAcv"
                        >
                          $
                        </ToggleButton>
                      </CustomWidthTooltip>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>

                <QualifiedPipelineTable
                  name="trailing-week"
                  thresholds={this.props.NewQualifiedPipelineDatav2.threshold}
                  drilldown={this.drilldown}
                  Value_Label={this.props.Value_Label}
                  isColDate={false}
                  isAverage={true}
                  col="Week_Ending"
                  acvCol={
                    this.state.toggleValue === "CurrentAcv"
                      ? "currentACV"
                      : "Acv_at_Qualfied"
                  }
                  avgCol2={
                    this.state.toggleValue === "CurrentAcv"
                      ? "WeeksAverageCurrentACV"
                      : "WeeksAverageACV"
                  }
                  avgCol3={
                    this.state.toggleValue === "CurrentAcv"
                      ? "WeeksCurrentAverage"
                      : "WeeksAverage"
                  }
                  columns={this.props.columns}
                  traillingColumns={
                    this.props.NewQualifiedPipelineDatav2.endingAvgColNames
                  }
                  goalheader={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                    "Week_Ending"
                      ? "Weekly"
                      : "Half-Month"
                  }
                  data={this.props.NewQualifiedPipelineDatav2.summary}
                  subColumns={
                    this.state.toggleValue === "CurrentAcv"
                      ? ["# of Opps", "Current ACV", "Avg. ACV"]
                      : [
                          "# of Opps",
                          `ACV at ${this.props.NewQualifiedPipelineDatav2.tableHeader}`,
                          "Avg. ACV",
                        ]
                  }
                  timeFrameEnding={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding
                  }
                  tableHeading={
                    this.props.NewQualifiedPipelineDatav2.timeFrameEnding ===
                    "Week_Ending"
                      ? "Week Ending"
                      : "Half-Month Ending"
                  }
                  teamNames={this.props.teams}
                  dataID="New_Pipeline_Creation_And_Cadence_table-Average"
                />
              </Card>
            </Grid>
          ) : (
            <Grid item container component={Card} justify="center">
              <Skeleton
                animation="wave"
                variant="rect"
                width="80%"
                height={200}
                style={{ margin: "20px 0px" }}
              />
            </Grid>
          )}
        </Grid>

        {this.state.location === "drilldown" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.heading}
              back={this.handleChangeLocation}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={`New ${this.props.NewQualifiedPipelineDatav2.tableHeader} Pipeline`}
                body={this.props.NewQualifiedPipelineDrilldownData}
                nqpScoreCard={true}
                showCurrentACV={this.state.toggleValue}
                trailingAvarage={this.state.metaData}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                Value_Label={this.props.Value_Label}
                sld={true}
              />
              {/* <Drilldown body={this.props.NewQualifiedPipelineDrilldownData} isMetaData={this.state.isMetaData} metaData={this.state.metaData} drilldownLabelString={this.state.drilldownLabelString} 
               company={this.props.company} handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
               />   */}
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  var {
    user,
    NewQualifiedPipelineDatav2,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
    NewQualifiedPipelineFiltersv2,
    currentFilters,
  } = state.app;
  var transformedNewQualifiedPipeline,
    teams,
    columns,
    detailsTeams,
    detailsColumns,
    timeframe;
  if (NewQualifiedPipelineDatav2 !== "") {
    teams = NewQualifiedPipelineDatav2.summary
      .filter((item) => !["team", "total"].includes(item.Owner_Name))
      .map((item) => item.team)
      .sort()
      .filter(getUniqueValues);
    timeframe = NewQualifiedPipelineDatav2.timeFrameEnding;
    columns = NewQualifiedPipelineDatav2.summary
      .map((item) => item[timeframe])
      .filter(getUniqueValues);
    detailsTeams = NewQualifiedPipelineDatav2.detail
      .filter((item) => !["team", "total"].includes(item.Owner_Name))
      .map((item) => item.team)
      .sort()
      .filter(getUniqueValues);
    detailsColumns = NewQualifiedPipelineDatav2.detail
      .map((item) => item[timeframe])
      .filter(getUniqueValues);
    NewQualifiedPipelineDatav2.summary = NewQualifiedPipelineDatav2.summary.map(
      (item) => {
        if (item[timeframe] === "Trailing 8 Week") {
          return {
            ...item,
            weekEnding: "Eight",
          };
        } else if (item[timeframe] === "Trailing 12 Week") {
          return {
            ...item,
            weekEnding: "Twelve",
          };
        } else if (item[timeframe] === "Trailing 4 Week") {
          return {
            ...item,
            weekEnding: "Four",
          };
        }
      }
    );
  }

  return {
    company: user.company,
    columns,
    teams,
    detailsTeams,
    detailsColumns,
    NewQualifiedPipelineDatav2,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
    funnelCompareFilters: NewQualifiedPipelineFiltersv2,
    currentFilters,
    Value_Label: user.Value_Label,
  };
};
const mapDispatchToProps = (dispatch) => ({
  clearDrilldownData: () => {
    dispatch({ type: "clear_NewQualifiedPipeline_drilldown_data" });
  },
  getfunnelCompareFilters: (company) => {
    dispatch({ type: "get_nqp_nop_filters_request" });
    appService.getNewQualifiedPipelineFiltersv2().then(
      (json) => {
        dispatch({ type: "get_nqp_nop_filters_success", json });
        dispatch({ type: "get_nqp_nop_data_request" });
        const stageSequence = getDefaultSelectedStage(
          json.message.mileStoneStages
        );
        const defaultStage = json.message.mileStoneStages
          .filter((item) => item.sequence === stageSequence)
          .map((i) => i.milestone_stage)
          .filter(Boolean);
        let filters = [];
        if (json.message.mileStoneStages) {
          filters = [
            { name: "milestone_stage", string: true, value: defaultStage },
          ];
        }
        if (json.message.dateFilters) {
          const from = json.message.dateFilters.prior2Month;
          // const fromDate = `${from.split('-')[2]}-${from.split('-')[0]}-${from.split('-')[1]}`
          const to = json.message.dateFilters.currentDate;
          // const toDate = `${to.split('-')[2]}-${to.split('-')[0]}-${to.split('-')[1]}`

          const timeFrames = `${from}_to_${to}`;
          filters = [
            ...filters,
            {
              name: "timeFrames",
              value: timeFrames,
            },
          ];
        }
        dispatch({ type: "current_selected_filters", filters });
        appService.getNewQualifiedPipelineDatav2(filters).then(
          (json) => {
            dispatch({ type: "get_nqp_nop_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_nqp_nop_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_nqp_nop_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_nqp_nop_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_nqp_nop_filters_failure", error });
      }
    );
  },
  getNewQualifiedPipelineDrilldownData: (filters) => {
    dispatch({ type: "get_NewQualifiedPipeline_drilldown_data_request" });
    appService.getNewQualifiedPipelineDrilldownDatav2(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.acvAtStage > b.acvAtStage ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            columns: json.message.columns,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
          },
        };

        dispatch({
          type: "get_NewQualifiedPipeline_drilldown_data_success",
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_NewQualifiedPipeline_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_NewQualifiedPipeline_drilldown_data_failure",
            error,
          });
      }
    );
  },
  getNewQualifiedPipelineDatav2: (filters) => {
    dispatch({ type: "get_nqp_nop_data_request" });
    appService.getNewQualifiedPipelineDatav2(filters).then(
      (json) => {
        dispatch({ type: "get_nqp_nop_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_nqp_nop_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_nqp_nop_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedNewQualifiedPipelinev2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewQualifiedPipelinev2));
export default connectedNewQualifiedPipelinev2;
