import React from "react";
import { withSnackbar } from "notistack";

import {
  Avatar,
  Button,
  Box,
  IconButton,
  InputAdornment,
  CardMedia,
  Typography,
  colors,
  TextField,
  Fade,
  Grid,
  Checkbox,
  Collapse,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import LockIcon from "@material-ui/icons/Lock";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { appService } from "../App/app.service";
import * as Yup from "yup";
import { Formik } from "formik";
import Logo from "../components/Logo";
import ViewIcon from "@material-ui/icons/Visibility";
import OffIcon from "@material-ui/icons/VisibilityOff";
import { withRouter } from "react-router-dom";

const viz_url = process.env.REACT_APP_VIZ_URL;

const styles = (theme) => ({
  root: {
    backgroundColor: "#4472c4",
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
  },
  container: {
    height: "100%",
  },
  media: {
    borderRadius: 4,
    height: "60%",
    marginTop: "10%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pictureDiv: {
    textAlign: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    backgroundSize: "cover",
    backgroundImage:
      "linear-gradient(to bottom, rgba(0,0,0,0), rgba(255,255,255,1)), url(https://skygeniwebapp.blob.core.windows.net/thumbnails/sales.jpg)",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    padding: "50px",
  },
  formContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    margin: 30,
    [theme.breakpoints.down("sm")]: {
      margin: 10,
    },
  },
  errorMessageStyle: {
    textAlign: "left",
    paddingTop: "10px",
    color: "red",
  },
  formStyle: {
    padding: "10%",
  },
  rememberMeCheck: {
    padding: 0,
    marginRight: 10,
  },
  backButtonContainer: {
    textAlign: "left",
  },
  backButton: {
    textTransform: "none",
    padding: "0%",
    textAlign: "left",
    color: "#4472c4",
    justifyContent: "flex-start",
  },
});

class LoginView extends React.Component {
  state = {
    location: "login",
    forgotPassEmail: "",
    errorMessage: this.props.alert.returnCode ? this.props.alert.message : "",
    open: true,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.loggedIn) {
      const redirectTo = localStorage.getItem("redirectAfterLogin");
      // console.log(redirectTo, 'XYZZZZZ')
      if (redirectTo) {
        localStorage.removeItem("redirectAfterLogin");
        this.props.history.push(redirectTo);
      } else {
        this.props.history.push("/");
      }
    }

    if (prevProps.forgotPassToken === "" && this.props.forgotPassToken !== "") {
      if (this.state.location === "forgotPass") {
        this.setState({
          ...this.state,
          location: "changePass",
        });
      }
    }
    if (
      this.props.alert !== "" &&
      this.props.alert.message ===
        "Password changed. Please sign in with new password."
    ) {
      if (this.state.location === "changePass") {
        this.setState({
          ...this.state,
          location: "login",
          forgotPassEmail: "",
        });
      }
    }

    if (this.props.alert.id !== prevProps.alert.id) {
      console.log(this.props.alert);
      /*
      For error code 600 // inactivity timeout happens only to alrady logged in users
1) Logout user
2) Show message as toast
      For error code 601 // Unknown company happens when trying to
1) Show message as box with close button
For error code 602 // Missing user, known company
1) Show message as box with close button
For error code 603 // Inactive, known user
1) Show message as box with close button
      */
      if (
        this.props.alert.returnCode == 604 ||
        this.props.alert.returnCode == 603 ||
        this.props.alert.returnCode == 601 ||
        this.props.alert.returnCode == 602 ||
        this.props.alert.returnCode == 600
      ) {
        console.log(this.props.alert.returnCode);
        this.setState({
          ...this.state,
          open: true,
          errorMessage: this.props.alert.message,
        });
      } else if (this.props.alert.returnCode) {
        console.log(this.props.alert.returnCode);
        this.props.enqueueSnackbar(
          this.props.alert.message || "Something went wrong",
          {
            variant: this.props.alert.type || "error",
          }
        );
      }
    }
    if (this.props.persistentAlert !== "" && prevProps.persistentAlert === "") {
      this.persistentAlert = this.props.enqueueSnackbar(
        this.props.persistentAlert.message,
        {
          variant: this.props.persistentAlert.type,
          persist: true,
        }
      );
    }
  };

  handleChangeLocation = (location) => {
    this.setState({ ...this.state, location: location });
  };
  componentDidMount = () => {
    if (this.props.loggedIn) {
      const redirectTo = localStorage.getItem("redirectAfterLogin");
      console.log(redirectTo, "XYZZZZZ");
      if (redirectTo) {
        localStorage.removeItem("redirectAfterLogin");
        this.props.history.push(redirectTo);
      } else {
        this.props.history.push("/");
      }
      // this.props.history.push("/");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          className={classes.container}
          alignItems="center"
          justify="center"
        >
          {this.state.location === "login" && (
            <Fade in={true}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className={classes.formContainer}>
                  <Formik
                    initialValues={{
                      email: this.props.checked,
                      password: "",
                      checked: this.props.checked === "" ? false : true,
                      visible: false,
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email("Must be a valid email.")
                        .max(255)
                        .required("Email is required."),
                      password: Yup.string()
                        .max(255)
                        .required("Password is required."),
                    })}
                    onSubmit={(values) => {
                      this.props.login(
                        values.email,
                        values.password,
                        values.checked
                      );
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <form
                        noValidate
                        onSubmit={handleSubmit}
                        className={classes.formStyle}
                      >
                        {this.state.errorMessage !== "" && (
                          <Collapse in={this.state.open}>
                            <Alert
                              severity="error"
                              color="error"
                              variant="filled"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      open: false,
                                      errorMessage: "",
                                    });
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              <Typography variant="body1">
                                {this.state.errorMessage}
                              </Typography>
                            </Alert>
                          </Collapse>
                        )}
                        {/* <Typography variant="body2" className={classes.errorMessageStyle}>{this.state.errorMessage}</Typography> */}
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          //autoFocus
                          helperText={touched.email && errors.email}
                          label="Email Address"
                          margin="normal"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="email"
                          value={values.email}
                          variant="outlined"
                        />
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          label="Password"
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          variant="outlined"
                          type={values.visible ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {values.visible ? (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue("visible", false, false)
                                    }
                                  >
                                    {" "}
                                    <OffIcon />{" "}
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue("visible", true, false)
                                    }
                                  >
                                    <ViewIcon />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Box mt={2}>
                          <Button
                            color="primary"
                            disabled={
                              (this.props.persistentAlert !== "" &&
                                this.props.persistentAlert.message ===
                                  "Signing in...") ||
                              Boolean(touched.password && errors.password) ||
                              Boolean(touched.email && errors.email)
                            }
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            data-test="signin"
                          >
                            Sign in
                          </Button>
                        </Box>
                        {/* <Box mt={2}>
                          <a href={`${viz_url}/api/auth/activeDirectoryLogin`}>Active Directory Login</a>
                        </Box> */}

                        <Box mt={2}>
                          <Checkbox
                            checked={values.checked}
                            color="primary"
                            className={classes.rememberMeCheck}
                            onChange={handleChange}
                            name="checked"
                          />
                          Remember me
                        </Box>

                        <Box mt={2} className={classes.backButtonContainer}>
                          <Button
                            disabled={
                              this.props.persistentAlert !== "" &&
                              this.props.persistentAlert.message ===
                                "Signing in..."
                            }
                            size="small"
                            variant="small"
                            onClick={() =>
                              this.handleChangeLocation("forgotPass")
                            }
                            className={classes.backButton}
                          >
                            Forgot Your Password?
                          </Button>
                        </Box>
                        <Box mt={2} className={classes.backButtonContainer}>
                          <Button
                            size="small"
                            variant="small"
                            href={`${viz_url}/api/auth/salesforce`}
                            className={classes.backButton}
                            data-test="salesforce-signin"
                          >
                            Sign in with Salesforce
                          </Button>
                        </Box>
                        {/*<Box mt={2} className={classes.backButtonContainer}>
                          <Button
                            size="small"
                            variant="small"
                            href={`${viz_url}/api/auth/activeDirectory`}
                            className={classes.backButton}
                            data-test="active-signin"
                          >
                            Sign in with Active Directory
                          </Button>
                        </Box>*/}
                      </form>
                    )}
                  </Formik>
                </Box>
              </Grid>
            </Fade>
          )}
          {this.state.location === "forgotPass" && (
            <Fade in={true}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className={classes.formContainer}>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email("Must be a valid email.")
                        .max(255)
                        .required("Email is required."),
                    })}
                    onSubmit={(values) => {
                      this.setState({
                        ...this.state,
                        forgotPassEmail: values.email,
                      });
                      this.props.forgotPass(values.email);
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                    }) => (
                      <form
                        noValidate
                        onSubmit={handleSubmit}
                        className={classes.formStyle}
                      >
                        {this.state.errorMessage !== "" && (
                          <Collapse in={this.state.open}>
                            <Alert
                              severity="error"
                              color="error"
                              variant="filled"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      open: false,
                                      errorMessage: "",
                                    });
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              <Typography variant="body1">
                                {this.state.errorMessage}
                              </Typography>
                            </Alert>
                          </Collapse>
                        )}
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          //autoFocus
                          helperText={touched.email && errors.email}
                          label="Email Address"
                          margin="normal"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="email"
                          value={values.email}
                          variant="outlined"
                        />
                        <Box mt={2}>
                          <Button
                            color="primary"
                            disabled={
                              (this.props.persistentAlert !== "" &&
                                this.props.persistentAlert.message ===
                                  "Sending code...") ||
                              Boolean(touched.email && errors.email)
                            }
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            Send Code
                          </Button>
                        </Box>
                        <Box mt={2} className={classes.backButtonContainer}>
                          <Button
                            size="small"
                            variant="small"
                            onClick={() => this.handleChangeLocation("login")}
                            className={classes.backButton}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Grid>
            </Fade>
          )}
          {this.state.location === "changePass" && (
            <Fade in={true}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className={classes.formContainer}>
                  <Formik
                    initialValues={{
                      code: "",
                      password: "",
                      confirm: "",
                      visible1: false,
                      visible2: false,
                    }}
                    validationSchema={Yup.object().shape({
                      code: Yup.number()
                        .integer()
                        .typeError("Code is a 6 digit integer.")
                        .required("Code is required."),
                      password: Yup.string()
                        .max(255)
                        .required("Password is required.")
                        .min(
                          8,
                          "Password is too short - should be a minimum of 8 characters."
                        )
                        .matches(
                          /.*[0-9].*/,
                          "Password must contain at least 1 number."
                        )
                        .matches(
                          /[*@!#%&^~$-_]+/,
                          "Password must contain at least 1 special character."
                        )
                        .matches(
                          /[A-Z]+/,
                          "Password must contain at least 1 upper case character."
                        )
                        .matches(
                          /[a-z]+/,
                          "Password must contain at least 1 lower case character."
                        ),
                      confirm: Yup.string().oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match."
                      ),
                    })}
                    onSubmit={(values) => {
                      this.props.changePass(
                        values.code,
                        values.password,
                        this.props.forgotPassToken,
                        this.state.forgotPassEmail
                      );
                      this.handleChangeLocation("login");
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <form
                        noValidate
                        onSubmit={handleSubmit}
                        className={classes.formStyle}
                      >
                        <TextField
                          error={Boolean(touched.code && errors.code)}
                          fullWidth
                          //autoFocus
                          helperText={touched.code && errors.code}
                          label="Code"
                          margin="normal"
                          name="code"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          variant="outlined"
                        />
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          label="Password"
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          variant="outlined"
                          type={values.visible1 ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {values.visible1 ? (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue("visible1", false, false)
                                    }
                                  >
                                    {" "}
                                    <OffIcon />{" "}
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue("visible1", true, false)
                                    }
                                  >
                                    <ViewIcon />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          error={Boolean(touched.confirm && errors.confirm)}
                          fullWidth
                          helperText={touched.confirm && errors.confirm}
                          label="Confirm New password"
                          margin="normal"
                          name="confirm"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.confirm}
                          variant="outlined"
                          type={values.visible2 ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {values.visible2 ? (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue("visible2", false, false)
                                    }
                                  >
                                    {" "}
                                    <OffIcon />{" "}
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue("visible2", true, false)
                                    }
                                  >
                                    <ViewIcon />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Box mt={2}>
                          <Button
                            color="primary"
                            disabled={
                              (this.props.persistentAlert !== "" &&
                                this.props.persistentAlert.message ===
                                  "Sending code...") ||
                              (this.props.persistentAlert !== "" &&
                                this.props.persistentAlert.message ===
                                  "Changing password...") ||
                              Boolean(touched.password && errors.password) ||
                              Boolean(touched.confirm && errors.confirm) ||
                              Boolean(touched.code && errors.code)
                            }
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            Change Password
                          </Button>
                        </Box>
                        <Box mt={2} className={classes.backButtonContainer}>
                          <Button
                            disabled={
                              (this.props.persistentAlert !== "" &&
                                this.props.persistentAlert.message ===
                                  "Sending code...") ||
                              (this.props.persistentAlert !== "" &&
                                this.props.persistentAlert.message ===
                                  "Changing password...")
                            }
                            size="small"
                            variant="small"
                            onClick={() =>
                              this.props.forgotPass(this.state.forgotPassEmail)
                            }
                            className={classes.backButton}
                          >
                            Send Code Again
                          </Button>
                        </Box>
                        <Box mt={2} className={classes.backButtonContainer}>
                          <Button
                            size="small"
                            variant="small"
                            onClick={() => this.handleChangeLocation("login")}
                            className={classes.backButton}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Grid>
            </Fade>
          )}

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.pictureDiv}
          >
            <Logo width="300px" height="100px" />
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, persistentAlert, forgotPassToken, checked, loggedIn } =
    state.app;

  return {
    alert,
    persistentAlert,
    forgotPassToken,
    checked,
    loggedIn,
  };
}

const mapDispatchToProps = (dispatch) => ({
  login: (username, password, checked) => {
    dispatch({ type: "login_request", username });
    appService.login(username, password).then(
      (json) => {
        if (checked) {
          localStorage.setItem(
            "skygeni-user-checked",
            JSON.stringify(username)
          );
          dispatch({ type: "add_checked", username });
        } else {
          localStorage.removeItem("skygeni-user-checked");
          dispatch({ type: "remove_checked" });
        }
        dispatch({ type: "login_success", json });
      },
      (error) => {
        console.log(error, "updating_app_error");
        if (error.isLoggedIn === false) {
          dispatch({ type: "udpating_app" });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else if (typeof error === "object")
          dispatch({ type: "login_failure", error });
        else dispatch({ type: "login_failure", error });
      }
    );
  },
  forgotPass: (username) => {
    dispatch({ type: "forgot_pass_request", username });
    appService.forgotPass(username).then(
      (json) => {
        dispatch({ type: "forgot_pass_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "forgot_pass_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "forgot_pass_failure", error });
      }
    );
  },
  changePass: (code, password, token, email) => {
    dispatch({ type: "change_pass_request" });
    appService.changePass(code, password, token, email).then(
      (json) => {
        dispatch({ type: "change_pass_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "change_pass_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "change_pass_failure", error });
      }
    );
  },
});

const connectedLoginView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(withSnackbar(LoginView))));
export { connectedLoginView as LoginView };
