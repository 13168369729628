import {
  Typography,
  withStyles,
  Grid,
  Select,
  MenuItem,
  Paper,
  FormControl,
  InputLabel,
  Button,
  Card,
  TextField,
  helperText,
  InputAdornment,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getMixKey } from "../../util/generateHeadings";
import * as d3 from "d3";

import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import { formatDataForMekkoChartV2 } from "../../util/dataFormatter";
import SummaryViewTable from "../PBISummaryView/SummaryViewTable";
import D3MekkoChart from "../PBISummaryView/D3PBIMekkoChart";
import PBISummaryLegend from "../PBISummaryView/PBISummaryLegend";
import NoDataComponent from "../../components/NoDataComponent";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import Drilldown from "../../components/Drilldown/Drilldown";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
  getDefaultSalesType,
} from "../../util/customFunctions";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";
import NumberFormat from "react-number-format";

const styles = (theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },

  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
  colHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "7px 10px",
    color: "inherit",
    width: 120,
  },
});
class PipelineMekkoChart extends Component {
  filters = [
    "projected_close_fiscal_quarter",
    "Acct_Size_Segment",
    "Cust_Type",
    "Acct_Industry",
    "rep",
    "team",
    "Solution_Cat",
    "ACV_Range",
    "deal_category__c",
    "cross_sell1__c",
    "Type",
    "Opportunity_Record_Type",
  ];
  state = {
    Opportunity_Record_Type: ["All"],
    projected_close_fiscal_quarter: ["All"],
    disabled: ["year", "month", "quarter"],
    earliestOpenStage: "",
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Segment: ["All"],
    filtersForDrilldown: ["All"],
    rep: ["All"],
    repTeam: "rep",
    team: ["All"],
    Acct_Vertical: ["All"],
    Solution_Cat: ["All"],
    Acct_Segment: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Cust_Type: ["All"],
    Type: ["All"],
    Acct_Industry: ["All"],
    Acct_Size_Segment: ["All"],
    ACV_Range: ["All"],
    xAxis: "",
    yAxis: "",
    xLabel: "",
    yLabel: "",
    xAxisError: "",
    yAxisError: "",
    secondLevelLocation: null,
    acv: 0,
    acvlt: 0,
  };
  componentDidMount() {
    this.props.getMekkoChartFilters("open", this.props.company);
    window.addEventListener("resize", this.onResize);
  }
  componentWillUnmount() {
    D3MekkoChart.destroy(this._rootNode1);
    window.removeEventListener("resize", this.onResize);
  }
  onResize = () => {
    const data = this.props.MekkoData[0];
    const uniqueXAxisValues = this.props.MekkoData[2].uniqueXAxisValues;
    const uniqueYAxisValues = this.props.MekkoData[2].uniqueYAxisValues;
    const xyAxis = this.props.MekkoData[3];
    const formattedData = formatDataForMekkoChartV2(
      data,
      uniqueXAxisValues,
      uniqueYAxisValues,
      xyAxis
    );

    D3MekkoChart.destroy(this._rootNode1);
    this._chart1 = D3MekkoChart.create(this._rootNode1, {
      data: formattedData,
      labels: { appCluster: uniqueXAxisValues, vertical: uniqueYAxisValues },
      handleChangeLocation: this.handleChangeLocation,
      type: "generic",
      xLabel: xyAxis.xLabel,
      yLabel: xyAxis.yLabel,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const stageSequence = getDefaultSelectedStage(
        this.props.funnelCompareFilters.filter((f) => f.type === "stage")
      );
      let stage_names = [];
      this.props.funnelCompareFilters.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence >= stageSequence)
            stage_names = [...stage_names, i.stage_name];
        }
      });
      const xyAxis = this.props.funnelCompareFilters.filter(
        (s) => s.type === "Ch_Opp_Column_Name"
      );
      const xAxis = xyAxis.filter((x) => x.default_axis === "x")[0]
        .Ch_Opp_Column_Name;
      const yAxis = xyAxis.filter((x) => x.default_axis === "y")[0]
        .Ch_Opp_Column_Name;

      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter(
            (s) => s.type === "cross_sell1__c"
          ),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter((s) => s.type === "Type"),
          "Type"
        );
      }

      this.setState({
        ...prevState,
        projected_close_fiscal_quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "projected_close_fiscal_quarter"
        ),
        earliestOpenStage: stageSequence,
        filtersForDrilldown: [
          {
            title: getMixKey("projected_close_fiscal_quarter"),
            value: getDefaultSelectedFiscalQuarters(
              this.props.funnelCompareFilters,
              "projected_close_fiscal_quarter"
            ),
          },
        ],
        xAxis,
        yAxis,
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
      });
    }
    if (this.props.MekkoData !== "" && prevProps.MekkoData === "") {
      // const preFormattedMekkoData = preFormatMekkoData(this.props.PBISummaryData)
      const data = this.props.MekkoData[0];
      const uniqueXAxisValues = this.props.MekkoData[2].uniqueXAxisValues;
      const uniqueYAxisValues = this.props.MekkoData[2].uniqueYAxisValues;
      const xyAxis = this.props.MekkoData[3];
      const formattedData = formatDataForMekkoChartV2(
        data,
        uniqueXAxisValues,
        uniqueYAxisValues,
        xyAxis
      );

      D3MekkoChart.destroy(this._rootNode1);
      this._chart1 = D3MekkoChart.create(this._rootNode1, {
        data: formattedData,
        labels: { appCluster: uniqueXAxisValues, vertical: uniqueYAxisValues },
        handleChangeLocation: this.handleChangeLocation,
        type: "generic",
        xLabel: xyAxis.xLabel,
        yLabel: xyAxis.yLabel,
      });
      this.setState({
        ...prevState,
        xLabel: xyAxis.xLabel,
        yLabel: xyAxis.yLabel,
      });
    }
  }

  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }

  quarterForDrilldown = () => {
    let quarter = [];
    quarter = this.state.closed_fiscal_quarter;
    console.log(quarter);
    return quarter;
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    this.filters.map((s) => {
      if (!this.state[s].includes("All")) {
        let obj = {
          title: getMixKey(s, this.props.Value_Label),
          value: this.state[s],
        };
        filtersArray.push(obj);
      }
    });

    return filtersArray;
  };

  handleChangeLocation = (location, y, x) => {
    const xyAxis = this.props.MekkoData[3];
    const xAxisValue = xyAxis.xValue;
    const yAxisValue = xyAxis.yValue;
    if (location !== "overview") {
      var filters = [];
      var yValue = y;
      var xValue = x;
      if (y.includes("Others")) {
        yValue = [
          ...yValue.filter((s) => s !== "Others"),
          ...this.props.MekkoData[1],
        ];
      } else if (x.includes("Others")) {
        xValue = [
          ...xValue.filter((s) => s !== "Others"),
          ...this.props.MekkoData[1],
        ];
      }

      filters = [
        ...filters,
        { name: xAxisValue, value: xValue },
        { name: yAxisValue, value: yValue },
      ];

      let localFilters = this.props.currentFilters.filter(
        (i) => i.name !== xAxisValue && i.name !== yAxisValue
      );

      filters = [...filters, ...localFilters];
      const xyAxisLables = [xyAxis.xLabel, xyAxis.yLabel];
      let filtersDrilldown = this.state.filtersForDrilldown.filter(
        (f) => !xyAxisLables.includes(f.title)
      );
      this.props.getMekkoChartDrilldown(filters, "open");
      this.setState({
        location: location,
        secondLevelLocation: location,
        filtersForDrilldown: [
          { title: xyAxis.xLabel, value: x },
          { title: xyAxis.yLabel, value: y },
          ...filtersDrilldown,
        ],
      });
    } else {
      this.props.clearDrilldownData();
    }
  };
  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
    //this.props.setTable('')
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };

  xyAxisChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      xAxisError:
        e.target.name === "xAxis"
          ? e.target.value === this.state.yAxis
            ? "X-Axis and Y-Axis cannot be same"
            : ""
          : "",
      yAxisError:
        e.target.name === "yAxis"
          ? e.target.value === this.state.xAxis
            ? "X-Axis and Y-Axis cannot be same"
            : ""
          : "",
    });
  };

  handleGo = () => {
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_names = [];
    this.props.funnelCompareFilters.map((i) => {
      if (!["", null, undefined].includes(i.stage_name)) {
        if (i.sequence >= this.state.earliestOpenStage)
          stage_names = [...stage_names, i.stage_name];
      }
    });
    filters = [
      ...filters,
      {
        name: "stage",
        value: stage_names,
        string: true,
      },
    ];

    if (this.state.acv !== null && this.state.acv !== 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }

    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }

    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
    this.props.getMekkoChartData(
      filters,
      "open",
      this.state.xAxis,
      this.state.yAxis
    );
  };

  // handleGo = () => {
  //     let filters = this.filters.map(f => {
  //         return {
  //             name: f,
  //             value: f === 'rep' ? this.state[f].includes('All') ? 'All' : this.getSelectedRepIDs() : this.state[f],
  //             string:f === 'team' ? true : this.props.funnelCompareFilters.filter(i => i.type === f && i[f] === this.state[f][0])[0] ? this.props.funnelCompareFilters.filter(i => i.type === f && i[f] === this.state[f][0])[0].string === "Y" : true
  //         }
  //     }).filter(f => (!Array.isArray(f.value) && f.value !== 'All') || !f.value.includes('All')).filter(f => !this.state.disabled.includes(f.name))
  //     this.setState({ ...this.state, filtersForDrilldownCYQ: this.passFiltersForDrilldown() })
  //     //this.props.getMekkoChartData(this.state.activeTimeFilter === "closed_fiscal_year" ? "closed_fiscal_year" : "closed_fiscal_quarter", filters)
  //     this.props.getMekkoChartData(filters,'won')
  // }

  orderRepsByLastName = () => {
    let temp = this.props.funnelCompareFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Region_Rollup",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Booking_Type_NL_CS_US",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Acct_Vertical",
          projectedQuarter: "projected_close_fiscal_quarter",
          ACV_Range: "ACV_Range",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Solution_Cat",
          projectedQuarter: "projected_close_fiscal_quarter",
          ACV_Range: "ACV_Range",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
        };

  render() {
    const { classes } = this.props;
    const tableHeaders =
      this.props.MekkoData !== "" &&
      this.props.MekkoData !== undefined &&
      this.props.MekkoData.length > 0
        ? this.props.MekkoData[2].uniqueXAxisValues
        : [];
    console.log(this.props, tableHeaders);
    return (
      <Grid container justify="space-around">
        {/* <Grid className={classes.grid} item xs={12} style={{ marginBottom: 10 }}>
                    <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Pipeline Mekko Chart</Typography>
                    <Typography variant='body1'>
                    Dissect the open pipeline to discover the major areas of focus.
                    </Typography>
                </Grid> */}
        {addHeaderdescription(
          "Pipeline Mekko Chart",
          "Dissect the open pipeline to discover the major areas of focus."
        )}
        {this.props.funnelCompareFilters !== "" ? (
          <Grid item container xs={12}>
            <Grid container item style={{ padding: 10 }} xs={4} md={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.projectedQuarter
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Proj. Close Fiscal Quarter:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.projectedQuarter]}
                      onChange={this.handleChange}
                      name={this.variableCols.projectedQuarter}
                      data-test="projected-close-fiscal-quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>

                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.map((y) =>
                          y.type === this.variableCols.projectedQuarter ? (
                            <MenuItem
                              key={y[this.variableCols.projectedQuarter]}
                              value={y[this.variableCols.projectedQuarter]}
                            >
                              {y[this.variableCols.projectedQuarter]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-stage-label"
                      style={{ color: "#4472c4", width: 144 }}
                    >
                      Earliest Open Stage:
                    </InputLabel>
                    <Select
                      labelId="select-stage-label"
                      id="select-stage"
                      value={this.state.earliestOpenStage}
                      onChange={this.handleChangeEarliestOpenStage}
                      label="Earliest Open Stage:"
                      name="earliestOpenStage"
                      data-test="earliestOpenStage"
                    >
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.map(
                          (y) =>
                            y.stage_name && (
                              <MenuItem key={y.sequence} value={y.sequence}>
                                {y.stage_name}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item style={{ padding: 10 }} xs={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    data-test="rep"
                    id="combo-box-demo"
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                        label="Rep:"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      data-test="team"
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) => y.type === "team" && y.Display_Name !== ""
                          )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item style={{ padding: 10 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.Acct_Vertical
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Solution Category:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Acct_Vertical]}
                      onChange={this.handleChange}
                      name={this.variableCols.Acct_Vertical}
                      data-test={this.variableCols.Acct_Vertical}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Acct_Vertical &&
                              y[this.variableCols.Acct_Vertical] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Acct_Vertical] <
                            b[this.variableCols.Acct_Vertical]
                              ? -1
                              : a[this.variableCols.Acct_Vertical] >
                                b[this.variableCols.Acct_Vertical]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Acct_Vertical]}
                              value={y[this.variableCols.Acct_Vertical]}
                            >
                              {y[this.variableCols.Acct_Vertical]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={
                  this.state.xAxisError !== "" || this.state.yAxisError !== ""
                }
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={6}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Opportunity Type:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Type"
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerType &&
                              y[this.variableCols.CustomerType] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[this.variableCols.Industry].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003" ? "Region:" : "Industry:"}
                    </InputLabel>
                    <Select
                      data-test="Industry"
                      value={this.state[this.variableCols.Industry]}
                      onChange={this.handleChange}
                      name={this.variableCols.Industry}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Industry &&
                              y[this.variableCols.Industry] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Industry] <
                            b[this.variableCols.Industry]
                              ? -1
                              : a[this.variableCols.Industry] >
                                b[this.variableCols.Industry]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Industry]}
                              value={y[this.variableCols.Industry]}
                            >
                              {y[this.variableCols.Industry]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      {this.props.company === "C0003"
                        ? "Segment:"
                        : "Customer Segment:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Segment"
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerSegment &&
                              y[this.variableCols.CustomerSegment] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerSegment]}
                              value={y[this.variableCols.CustomerSegment]}
                            >
                              {y[this.variableCols.CustomerSegment]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/*Opportunity_Record_Type Filters  */}
            {this.props.company === "C0013" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Opportunity_Record_Type.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opportunity Record Type:
                      </InputLabel>
                      <Select
                        data-test="Opportunity_Record_Type"
                        value={this.state.Opportunity_Record_Type}
                        onChange={this.handleChange}
                        name="Opportunity_Record_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === "Opportunity_Record_Type" &&
                                y.Opportunity_Record_Type !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Opportunity_Record_Type}
                                value={y.Opportunity_Record_Type}
                              >
                                {y.Opportunity_Record_Type}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* Adding ACV Range Filter */}
            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.ACV_Range
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.Value_Label} Range:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.ACV_Range]}
                      onChange={this.handleChange}
                      name={this.variableCols.ACV_Range}
                      data-test={this.variableCols.ACV_Range}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {console.log(
                        this.props.funnelCompareFilters
                          .filter((y) => y.type === this.variableCols.ACV_Range)
                          .map((y) => y[this.variableCols.ACV_Range])
                      )}
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.ACV_Range &&
                              y[this.variableCols.ACV_Range] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.ACV_Range]}
                              value={y[this.variableCols.ACV_Range]}
                            >
                              {y[this.variableCols.ACV_Range]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {this.props.company === "C0001" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.deal_category__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Deal Category
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.deal_category__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.deal_category__c}
                        data-test={this.variableCols.deal_category__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.deal_category__c &&
                                y[this.variableCols.deal_category__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.deal_category__c]}
                                value={y[this.variableCols.deal_category__c]}
                              >
                                {y[this.variableCols.deal_category__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company === "C0008" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={4}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.cross_sell1__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Sales Type
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.cross_sell1__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.cross_sell1__c}
                        data-test={this.variableCols.cross_sell1__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.cross_sell1__c &&
                                y[this.variableCols.cross_sell1__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.cross_sell1__c]}
                                value={y[this.variableCols.cross_sell1__c]}
                              >
                                {y[this.variableCols.cross_sell1__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[this.variableCols.Type].includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Deal Type
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.Type]}
                        onChange={this.handleChange}
                        name={this.variableCols.Type}
                        data-test={this.variableCols.Type}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Type &&
                                y[this.variableCols.Type] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Type]}
                                value={y[this.variableCols.Type]}
                              >
                                {y[this.variableCols.Type]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      id="select-industry-label"
                    >
                      {"X-Axis"}
                    </InputLabel>
                    <Select
                      data-test="x-axis"
                      value={this.state.xAxis}
                      onChange={this.xyAxisChange}
                      name={"xAxis"}
                    >
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter((y) => y.type === "Ch_Opp_Column_Name")
                          .map((y) => (
                            <MenuItem
                              key={y["Ch_Opp_Column_Name"]}
                              value={y["Ch_Opp_Column_Name"]}
                            >
                              {y.label}
                            </MenuItem>
                          ))}
                    </Select>
                    <helperText style={{ color: "red" }}>
                      {this.state.xAxisError}
                    </helperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      id="select-industry-label"
                    >
                      {"Y-Axis"}
                    </InputLabel>
                    <Select
                      data-test="y-axis"
                      value={this.state.yAxis}
                      onChange={this.xyAxisChange}
                      name={"yAxis"}
                    >
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter((y) => y.type === "Ch_Opp_Column_Name")
                          .map((y) => (
                            <MenuItem
                              key={y["Ch_Opp_Column_Name"]}
                              value={y["Ch_Opp_Column_Name"]}
                            >
                              {y.label}
                            </MenuItem>
                          ))}
                    </Select>
                    <helperText style={{ color: "red" }}>
                      {this.state.yAxisError}
                    </helperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/*ACV >= and ACV <= */}

            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 12 }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((f) => f.type === "acv")
                        .map((d) => d3.format(",")(String(d.acv)))
                    }
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    data-test="acv"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== null && this.state.acv !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((f) => f.type === "acv")
                        .map((d) => d3.format(",")(String(d.acv)))
                    }
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    data-test="acvlt"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== null &&
                              this.state.acvlt !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {this.props.MekkoData !== "" ? (
          <>
            <Grid
              item
              container
              xs={12}
              justify="space-around"
              className={classes.grid}
            >
              <Grid
                container
                component={Card}
                raised={true}
                style={{ marginTop: "0.5rem" }}
              >
                <Grid item container xs={12} style={{ padding: "1rem" }}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6}>
                    <Typography
                      align="center"
                      variant="h2"
                      style={{ margin: "1rem" }}
                    >{`Pipeline by ${this.state.xLabel} and ${this.state.yLabel}`}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    container
                    justify="flex-end"
                    style={{ padding: "1rem" }}
                  ></Grid>
                </Grid>
                <Grid item xs={12}>
                  {this.props.MekkoData !== "" ? (
                    tableHeaders.length >= 1 ? (
                      <div
                        style={{
                          justifyContent: "center",
                          marginTop: 20,
                          margin: "auto",
                          width: "95%",
                        }}
                        id="svgContainer"
                        className="svg-container"
                        ref={this._setRef1.bind(this)}
                      />
                    ) : (
                      <NoDataComponent />
                    )
                  ) : (
                    <Grid container justify="center">
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="80%"
                        height={200}
                        style={{ margin: "20px 0px" }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.grid}>
              <Grid container component={Card}>
                <Grid item container xs={12}>
                  <Grid item xs={12} style={{ padding: "1rem" }}>
                    <Typography
                      align="center"
                      variant="h2"
                      style={{ margin: "1rem" }}
                    >{`Pipeline by ${this.state.xLabel} and ${this.state.yLabel}`}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    justify="center"
                    style={{ marginTop: -20 }}
                  >
                    {this.props.MekkoData !== "" ? (
                      <Grid container>
                        {tableHeaders === null || tableHeaders.length <= 0 ? (
                          <NoDataComponent />
                        ) : (
                          <SummaryViewTable
                            data={this.props.MekkoData[4]}
                            uniqueXYAxisValues={this.props.MekkoData[2]}
                            isAcv={true}
                            handleChangeLocation={this.handleChangeLocation}
                            data_test={"pipeline_by_vertical"}
                            xyAxis={this.props.MekkoData[3]}
                          />
                        )}
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width="80%"
                          height={200}
                          style={{ margin: "20px 0px" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {tableHeaders.length >= 1 ? <PBISummaryLegend /> : null}
                  </Grid>
                </Grid>
                <Grid item container xs={12} style={{ marginTop: 20 }}>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: "1rem", marginBottom: -20 }}
                  >
                    <Typography
                      align="center"
                      variant="h2"
                      style={{ margin: "1rem" }}
                    >{`Pipeline by ${this.state.xLabel} and ${this.state.yLabel} (% of total view)`}</Typography>
                  </Grid>
                  <Grid item xs={12} justify="center">
                    {this.props.MekkoData !== "" ? (
                      <Grid container>
                        {tableHeaders === null || tableHeaders.length <= 0 ? (
                          <NoDataComponent />
                        ) : (
                          <SummaryViewTable
                            ref={(el) => (this.componentRef = el)}
                            data={this.props.MekkoData[5]}
                            uniqueXYAxisValues={this.props.MekkoData[2]}
                            isAcv={false}
                            handleChangeLocation={this.handleChangeLocation}
                            xyAxis={this.props.MekkoData[3]}
                            data_test={"pipeline_by_vertical_total_view"}
                          />
                        )}
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width="80%"
                          height={200}
                          style={{ margin: "20px 0px" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "2rem" }}>
                    {tableHeaders.length >= 1 ? <PBISummaryLegend /> : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            style={{ marginTop: "1rem" }}
            container
            component={Card}
            raised={true}
            justify="center"
          >
            <Skeleton
              animation="wave"
              variant="rect"
              width="80%"
              height={200}
              style={{ margin: "20px 0px" }}
            />
          </Grid>
        )}
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={`Pipeline by ${this.state.xLabel} and ${this.state.yLabel}`}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              {console.log(this.props.drilldownOnGraphData)}
              <Drilldown
                header={`Pipeline by ${this.state.xLabel} and ${this.state.yLabel}`}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                title="mekko"
                Value_Label={this.props.Value_Label}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    PBISummaryFilters,
    MekkoData,
    user,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;
  return {
    funnelCompareFilters: PBISummaryFilters,
    MekkoData: MekkoData,
    company: user.company,
    Value_Label: user.Value_Label,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getMekkoChartFilters: (key, company) => {
    dispatch({ type: "get_pbi_summary_filters_request" });
    appService.getPipelineMekkoChartFilters().then(
      (json) => {
        dispatch({ type: "get_pbi_summary_filters_success", json });
        dispatch({ type: "get_pbi_mekkochart_data_request" });
        const stageSequence = getDefaultSelectedStage(
          json.message.filter((f) => f.type === "stage")
        );
        const defaultStages = json.message
          .filter(
            (item) =>
              item.sequence >= stageSequence &&
              ![null, undefined, ""].includes(item["stage_name"])
          )
          .map((i) => i.stage_name);
        let filters = [
          {
            name: "projected_close_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "projected_close_fiscal_quarter"
            ),
            string: true,
          },
          { name: "stage", string: true, value: defaultStages },
        ];
        const xyAxis = json.message.filter(
          (i) => i.type === "Ch_Opp_Column_Name"
        );
        const xAxis = xyAxis.filter((i) => i.default_axis === "x")[0]
          .Ch_Opp_Column_Name;
        const yAxis = xyAxis.filter((i) => i.default_axis === "y")[0]
          .Ch_Opp_Column_Name;
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }
        dispatch({ type: "current_selected_filters", filters });
        appService
          .getPipelineMekkoChartData(
            json.message.length === 0 ? [] : filters,
            key,
            xAxis,
            yAxis
          )
          .then(
            (json) => {
              dispatch({ type: "get_pbi_mekkochart_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_pbi_mekkochart_data_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_pbi_mekkochart_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pbi_summary_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_pbi_summary_mix_filters_failure", error });
      }
    );
  },
  getMekkoChartDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    console.log(meta);
    appService.getPipelineMekkoChartDrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getMekkoChartData: (filters = [], key, xAxis, yAxis) => {
    dispatch({ type: "get_pbi_mekkochart_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getPipelineMekkoChartData(filters, key, xAxis, yAxis).then(
      (json) => {
        dispatch({ type: "get_pbi_mekkochart_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_pbi_mekkochart_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_pbi_mekkochart_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedPipelineMekkoChart = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PipelineMekkoChart));
export default connectedPipelineMekkoChart;
